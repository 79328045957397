.profile {

  &__header {
    box-shadow: $common-shadow;
    background: #fff;
    margin-bottom: 20px;
  }

  &__head {
    padding: 30px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
  }

  &__avatar {
    width: 141px;
    margin: 0 auto 20px;
  }

  &__name {
    font-size: 16px;
    font-family: $bold;
    text-transform: uppercase;
    text-align: center;
  }

  &__block {
    box-shadow: $common-shadow;
    background: #fff;
    padding: 30px 20px;

    @include mobile {
      padding: 30px 18px;
    }
  }

  &__headline {
    text-align: center;
    font-size: 16px;
    margin-bottom: 12px;

    @include mobile {
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: $accent-primary-color;
      margin: 15px auto 0;
    }
  }

  &__title {
    font-family: $bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    line-height: 1.2;
    letter-spacing: 1px;
  }

  &__body {
    @extend .inner-page__body;
  }

  &__sidebar {
    @extend .inner-page__sidebar;

    @include tablet {
      width: 254px;
    }
  }

  &__content {
    @extend .inner-page__content;

    @include tablet {
      width: 436px;
    }
  }
}
