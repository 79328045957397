//common scss variables used through the whole application
$preferred_font: 'Proxima Nova Regular', 'Helvetica', sans-serif;
$performance_font: 'Helvetica', 'Arial', sans-serif;
$bold: 'ProximaNova-Bold', 'Helvetica', sans-serif;
$extrabold: 'ProximaNova-Extrabld', 'Helvetica', sans-serif;

$font-adjustment-1: 320px !default;
$font-adjustment-2: 480px !default;
$font-adjustment-3: 640px !default;
$font-adjustment-4: 800px !default;
$font-adjustment-5: 960px !default;
$font-adjustment-6: 1120px !default;
$font-adjustment-7: 1280px !default;
// +15px for lower one for FIT-756
$font-adjustment-8: 1455px !default;
$font-adjustment-9: 1615px !default;
$font-adjustment-10: 1775px !default;
$font-adjustment-11: 1935px !default;

$common-shadow: 0 1px 4px 0 rgba(0, 0, 0, .08);
$common-shadow_lifter: 0 0 12px rgba(0, 0, 0, .16);
$messages-shadow: 0 0 9px 0 rgba(0, 0, 0, .36);

$common-black-color: #000;
$common-dark-gray-color: #373e48;
$common-dim-gray-color: #4f5866;
$common-gray-color: #98999d;
$common-gray-color_transparent: #f4f5f5;
$common-silver-color: #c6c8cc;
$common-medium-gray-color: #ddd;
$common-medium-gray-color_accent: #dddddc;
$common-input-disabled-color: #f9f9f9;
$calendar-border-gray-color: #f6f6f6;
$calendar-background-gray-color: #f5f5f6;
$common-white-color: #fff;
$accent-primary-color: #ff5d22;
$accent-secondary-color: #00a1c3;
$accent-secondary-color-hover: darken(#00a1c3, 15%);
$accent-health-color: #3aa05f;
$accent-employee-color: #a081e3;
$warning-color: #b03939;
$warning-color_not-desktop: #ff0000; /* stylelint-disable-line */
$warning-color_not-desktop-rgba: rgba(255, 0, 0, .3);
$warning-requires-color: #c40808;
$common-extra-text-color: #bebebe;
$zone-card-color: #3c404a;

$small-transition-time: .1s;
$short-transition-time: .3s;
$medium-transition-time: .5s;
$duration: .35s;

$page-header-min-height: 60px;
$navbar-height: 50px;

// for 1366px width and font-size 13.8px
$input-height: 50px;
$input-value-padding: 0 .8rem;
$input-password-toggler-width: 22px;
$input-password-toggler-height-hidden: 20px;
$input-password-toggler-height-shown: 12px;

$common-margin-top-great_mobile: 3.7rem;
$common-margin-large_tablet: 3.62rem;
$common-margin-top-large_mobile: 2.9rem;
$common-margin-top-large: 2.46rem;
$common-margin-top-big: 2.17rem;
$common-margin-universal-normal: 1.67rem;
$common-margin-top-medium: .73rem;

$touched-input-padding: 1.88rem .8rem .35rem;

$common-border-radius: 3px;
$common-big-border-radius: 5px;

$facebook-color: #3c5a99;

$image-control-container-size: 71px;
$image-control-container-border-size: 2px;
$empty-image-icon-width: 30px;
$empty-image-icon-height: 24px;

// easing
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1);
$easeOutCubic: cubic-bezier(.215, .61, .355, 1);
$easeInCubic: cubic-bezier(.55, .055, .675, .19);

$easeInOutSine: cubic-bezier(.445, .05, .55, .95);

$onboarding-img-width: 40.12%;
$onboarding-img-min-width: 36.49%;
//$onboarding-img-height_mobile: 24.48%;
$onboarding-container-width: 100% - $onboarding-img-width;
$onboarding-container-min-width: 100% - $onboarding-img-min-width;
//$onboarding-container-height_mobile: 100% - $onboarding-img-height_mobile;

$signin-width: 475px;
$signin-width_min-desktop: 475px;
$signin-width_tablet: 475px;
$signin-width_mobile: 87.5%;
$signin-logo-width: 49.37%;
$signin-logo-width_mobile: 76%;
