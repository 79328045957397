.signin {
  @extend %flex-container-rows;
  min-height: 100%;
  width: 100%;
  padding-left: 40%;
  @include not-desktop {
    display: flex;
    flex-flow: column nowrap;
    padding-left: 0;
  }
}

.signin__title {
  font: 1.5rem/1 'ProximaNova-Bold';
  letter-spacing: .5px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}
.signin__img {
  flex: 1 0 $onboarding-img-width;
  background-image: url('/assets/imgs/employee-login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 40%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  @include min-desktop {
    flex: 1 0 $onboarding-img-min-width;
  }

  @include not-desktop {
    order: 2;
    flex: 0;
    position: relative;
    width: 100%;
    height: auto;
  }

  @include tablet {
    background-image: url('/assets/imgs/employee-login_tablet.jpg');
    padding-bottom: 58.335%;
  }

  @include mobile {
    background-image: url('/assets/imgs/employee-login_mobile.jpg');
    padding-bottom: 67.5%;
  }
}

.signin__img_employee {
  background-image: url('/assets/imgs/employee-login.jpg');
  @include tablet {
    background-image: url('/assets/imgs/employee-login_tablet.jpg');
  }
  @include mobile {
    background-image: url('/assets/imgs/employee-login_mobile.jpg');
  }
}
.signin__content {
  @extend %container_centred;
  border-top: 10px solid $accent-primary-color;
  flex: 1 0 $onboarding-container-width;
  //width: $onboarding-container-width;

  @include min-desktop {
    flex: 1 0 $onboarding-container-min-width;
  }

  @include not-desktop {
    display: initial;
    flex: initial;
    order: 1;
    //flex: 1 0 100%;
  }
}

.signin__container {
  @include onboarding-container($signin-width, $signin-width_tablet, $signin-width_mobile);
  min-height: calc(100vh - 6.666666666666667rem - 80px - 3.19rem - 10px);
  //margin: calc(-80px - 3.19rem) auto 0;

  // hack for social buttons so it doesn't broke
  @include min-desktop {
    min-width: $signin-width_min-desktop;
    max-width: $signin-width_min-desktop;
  }
}

.signin__logo {
  display: block;
  margin: 3.19rem auto 0;
  width: $signin-logo-width;

  @include not-desktop {
    width: $signin-logo-width_mobile;
  }
}

.signin__form {
  width: 100%;
  margin-bottom: 15px;
}

.signin__social-login {
  @extend %flex-container-rows-centred-between;
  margin-top: 4.19rem;
  width: 100%;

  > span {
    transition: none !important;
  }

  > span,
  .social-login__button--google {
    width: 49%;
  }

  @include not-desktop {
    flex-flow: column nowrap;

    > span,
    .social-login__button--google {
      width: 100%;
    }

    .social-login__button--google {
      margin-top: .72rem;
    }
  }

  @include tablet {
    margin-top: $common-margin-top-great_mobile;
  }

  @include mobile {
    margin-top: 3rem;
  }
}

.signin__divider {
  @extend %divider;
}

.signin__divider-text {
  @extend %divider-text;
}

// todo: should it be a mixin as onboarding-container or not?
.signin__submit-button {
  @extend .button;
  width: 63.16%;

  @include mobile {
    width: 100%;
    margin-top: 1.45rem;
  }
}

.signin__submit-button_disabled {
  @extend .button_disabled;
}

.signin__skip-step {
  @extend %skip-step;

  @include not-desktop {
    height: auto;
  }

  @include tablet {
    .skip-step__text,
    .skip-step__link,
    .skip-step__skip-link {
      padding: 1.45rem 0 3.62rem;
    }
  }

  @include mobile {
    .skip-step__text,
    .skip-step__link,
    .skip-step__skip-link {
      padding: $common-margin-top-big 0;
    }
  }
}
