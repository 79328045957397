$chart-height-small: 45.6%;
$chart-height-small_mobile: 101%;
$chart-height-big: 146%;
$chart-height-big_tablet: 300px;
//$chart-height-big_mobile: 88.5%;
$chart-height-big_mobile: 280px;
$chart-height-medium: 59.2%;
$chart-height-medium_tablet: 390px;
//$chart-height-medium_mobile: 146.6%;
$chart-height-medium_mobile: 400px;
$chart-height-large: 189.5%;
//$chart-height-large_tablet: 69.5%;
$chart-height-large_tablet: 300px;
//$chart-height-large_mobile: 147.65%;
$chart-height-large_mobile: 340px;
$chart-title-text-margin: 29px;
$chart-layer-top-margin: 20px;
$chart-layer-top-margin_tablet: 15px;
$chart-layer-top-margin_mobile: 5px;

.overview__charts {
  @extend %flex-container-rows-between;
  position: relative;
  width: 100%;

  @include not-desktop {
    flex-wrap: wrap;
  }
}

.overview__charts_second-layer {
  @include tablet {
    justify-content: flex-end;
    margin-top: -$chart-height-big_tablet - $chart-layer-top-margin_tablet;
  }
}

.charts__class-reservations,
.charts__class-reservations-by-zone {
  margin-top: $chart-layer-top-margin;
  position: relative;
  width: 100% - $new-object-item-width - (100% - $new-object-item-width * 4) / 3; // 3/4 of 100% - 1/3 of intervals

  @include not-desktop {
    width: 100%;
  }

  @include tablet {
    margin-top: $chart-layer-top-margin_tablet;
  }
}

.charts__class-reservations_loading,
.charts__class-reservations-by-zone_loading {
  margin-top: 0;
}

.charts__class-reservations-by-zone {
  @include not-desktop {
    order: 2;
  }
}

.charts__report-cards,
.charts__bookings-by-trainer {
  @extend %flex-container-columns-centred;
  position: relative;
  margin-top: $chart-layer-top-margin;
  width: $new-object-item-width;

  @include tablet {
    margin-top: $chart-layer-top-margin_tablet;
  }

  @include mobile {
    width: 100%;
  }
}

.charts__report-cards {
  @include tablet {
    width: 31.25%;
  }
}

.charts__report-cards_loading,
.charts__bookings-by-trainer_loading {
  margin-top: 0;
}

.charts__bookings-by-trainer {
  @include not-desktop {
    order: 1;
  }

  @include tablet {
    width: 66%;
  }
}

.class-reservations__loader,
.class-reservations-by-zone__loader,
.report-cards__loader,
.bookings-by-trainer__loader,
.custom-line__chart_absolute,
.class-reservations-by-zone__chart,
.report-cards__chart-content,
.bookings-by-trainer__chart-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.class-reservations__loader {
  padding-top: $chart-height-small;

  @include not-desktop {
    padding-top: 0;
    height: $chart-height-big_tablet;
  }
}

.class-reservations-by-zone__loader {
  padding-top: $chart-height-medium;

  @include not-desktop {
    padding-top: 0;
  }

  @include tablet {
    height: $chart-height-medium_tablet;
  }

  @include mobile {
    height: $chart-height-medium_mobile;
  }
}

.report-cards__loader,
.report-cards__chart {
  padding-top: $chart-height-big;

  @include not-desktop {
    padding-top: 0;
  }

  @include tablet {
    height: $chart-height-big_tablet;
  }

  @include mobile {
    height: $chart-height-big_mobile;
  }
}

.bookings-by-trainer__loader {
  padding-top: $chart-height-large;

  @include not-desktop {
    padding-top: 0;
  }

  @include tablet {
    height: $chart-height-large_tablet;
  }

  @include mobile {
    height: $chart-height-large_mobile;
  }
}

.class-reservations__content,
.report-cards__content,
.class-reservations-by-zone__content,
.bookings-by-trainer__content {
  @extend %flex-container-columns-centred;
}

.class-reservations__content,
.class-reservations-by-zone__content {
  width: 100%;
  height: 100%;
}

.class-reservations__content {
  @include not-desktop {
    height: $chart-height-big_tablet;
  }
}

.class-reservations-by-zone__content {
  @include tablet {
    height: $chart-height-medium_tablet;
  }

  @include mobile {
    height: $chart-height-medium_mobile;
  }
}

.class-reservations-by-zone__legend {
  //display: inline-flex;
  //flex-flow: column;
  //margin: 15px auto 0;
  margin-top: 15px;

  @include mobile {
    @extend %flex-container-rows-wrapped-centred;
  }

  .legend__item {
    @extend %flex-container-rows;
    margin-top: 5px;

    @include mobile {
      margin-right: 5px;
    }
  }

  .legend__item-text {
    @extend %typo-charts-legend;
  }

  .legend__item-marker {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
}

.report-cards__content,
.bookings-by-trainer__content {
  width: 100%;
}

.custom-line__chart,
.class-reservations-by-zone__chart {
  //@extend %flex-container-columns;
  padding: 23px 25px 10px;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: $common-border-radius;
  overflow: auto;
}

.class-reservations-by-zone__chart {
  @include mobile {
    @extend %flex-container-columns;
    padding-bottom: 23px;
  }
}

// hack for tooltip displaying increasing containers height
.custom-line__chart {
  padding-bottom: 0;
}

.bookings-by-trainer__chart {
  padding-top: $chart-height-large;

  @include tablet {
    padding-top: $chart-height-large_tablet;
  }

  @include mobile {
    padding-top: $chart-height-large_mobile;
  }
}

.report-cards__chart,
.bookings-by-trainer__chart {
  position: relative;
  width: 100%;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: $common-border-radius;
  overflow: auto;
}

.report-cards__chart-content,
.bookings-by-trainer__chart-content {
  padding: 23px 30px;

  @include desktop {
    margin: 0 auto;
  }
}

.bookings-by-trainer__chart-content {
  .recharts-wrapper {
    @include desktop {
      margin: 0 auto;
    }
  }
}

.chart__title {
  @extend %typo-charts-title;
  margin-bottom: $chart-title-text-margin;

  @include mobile {
    text-align: center;
  }
}

.chart__title_centered {
  text-align: center;
}

.chart__content {
  //align-self: center;
  align-self: center;
  margin-left: -37px;
}

.chart__tooltip {
  @extend %typo-charts-tooltip;
  display: inline-block;
  opacity: 1;
  transition: opacity $short-transition-time;
}

.chart__tooltip_hidden {
  opacity: 0;
  transition: opacity $short-transition-time;
}

.chart__text {
  @extend %typo-charts-text;
  margin-top: $chart-title-text-margin;

  @include mobile {
    width: 85%;
    margin: $chart-title-text-margin auto 0;
  }
}

// first xAxis label should start right
// but it's useless for a bigger date diapason
//.custom-line__chart {
//  .recharts-xAxis {
//    .recharts-cartesian-axis-tick {
//      &:first-of-type {
//        .recharts-cartesian-axis-tick-value {
//          text-anchor: start;
//        }
//      }
//    }
//  }
//}

.recharts-text {
  fill: $common-medium-gray-color;
}

.circle-chart {
  display: block;
  margin: 0 auto;
}

.circle-chart__background,
.circle-chart__circle {
  fill: none;
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  transform: rotate(-90deg);
  transform-origin: center;
}

.circle-chart__percent {
  @extend %typo-charts-title;
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

.recharts-legend-item {
  margin-top: 5px;

  &:first-of-type {
    margin-top: 0;
  }
}

.recharts-legend-item-text {
  @extend %typo-charts-legend;
}

.recharts-cartesian-grid-horizintal {
  line {
    stroke: $common-medium-gray-color;
  }
}

// recharts-cartesian-grid-vertical lines are being rendered buggy
// sometimes line that should be first is being rendered as last etc
//.charts__class-reservations,
//.overview__total-revenue {
//  .recharts-cartesian-grid-vertical {
//    line {
//      stroke: transparent;
//
//      &:first-of-type,
//      &:last-of-type {
//        stroke: $common-medium-gray-color;
//      }
//    }
//  }
//}
//
//.charts__class-reservations-by-zone {
//  .recharts-cartesian-grid-vertical {
//    line {
//      stroke: transparent;
//
//      &:nth-last-child(2),
//      &:last-of-type {
//        stroke: $common-medium-gray-color;
//      }
//    }
//  }
//}

.recharts-cartesian-grid-vertical {
  line {
    stroke: transparent;
  }
}
