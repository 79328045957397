$icon-size--xs: .25rem;
$icon-size--sm: $icon-size--xs * 2;
$icon-size--md: $icon-size--sm * 2; // 1rem
$icon-size--lg: $icon-size--md * 2; // 2rem
$icon-size--xl: $icon-size--lg * 2; // 4rem

@mixin icon-size($size) {
  width: $size;
  height: $size;
}

//.icon {
//  @include mobile {
//    flex: 1 0 auto;
//  }
//}

.icon_xs {
  @include icon-size($icon-size--xs);
}

.icon_sm {
  @include icon-size($icon-size--sm);
}

.icon_md {
  @include icon-size($icon-size--md);
}

.icon_lg {
  @include icon-size($icon-size--lg);
}

.icon_xl {
  @include icon-size($icon-size--xl);
}

.icon_search {
  width: 30px;
  height: 30px;
}

.icon_bell {
  width: 22px;
  height: 23px;
}

.icon_profile {
  width: 22px;
  height: 26px;
}

.icon_reports {
  width: 29px;
  height: 37px;
}

.icon_home {
  width: 35px;
  height: 29px;
}

.icon_customers {
  width: 34px;
  height: 26px;
}

.icon_dog {
  width: 34px;
  height: 26px;
}

.icon_directory {
  width: 35px;
  height: 32px;
}

.icon_clock {
  width: 31px;
  height: 31px;
}

.icon_packages {
  width: 37px;
  height: 42px;
}

.icon_purchased-package {
  height: 24px;
  width: 24px;
}

.icon_calendar {
  width: 34px;
  height: 34px;
}

.icon_marketing {
  width: 40px;
  height: 30px;
}

.icon_forms {
  width: 26px;
  height: 31px;
}

.icon_dashboard {
  width: 35px;
  height: 29px;
}

.icon_camera {
  width: 58px;
  height: 46px;
}

.icon_chevron_down {
  width: 30px;
  height: 30px;
  fill: $common-gray-color;

  &:hover {
    fill: $common-dark-gray-color;
  }

  @include mobile {
    fill: $common-dark-gray-color !important;
  }
}

.icon_edit {
  width: 18px;
  height: 18px;
}

.icon_delete {
  width: 18px;
  height: 18px;
}

.icon_play,
.icon_pause {
  width: 22px;
  height: 22px;
  fill: none;
  stroke: currentColor;
}

.icon_menu-profile {
  width: 16px;
  height: 20px;
  stroke: $common-dark-gray-color;
}

.icon_zzz {
  width: 24px;
  height: 16px;
}

.icon_trash {
  width: 18px;
  height: 20px;
}

.icon_vip {
  width: 20px;
  height: 18px;
}

.icon_card-edit,
.icon_card-add {
  width: 21.5px;
  height: 22px;
  fill: $common-gray-color;

  &:hover {
    fill: $accent-secondary-color;
  }

  @include not-desktop {
    fill: $accent-secondary-color;
  }
}

.icon_dashboard-edit {
  width: 21.5px;
  height: 22px;
  fill: $common-white-color;

  &:hover {
    fill: $accent-secondary-color;
  }

  @include not-desktop {
    fill: $accent-secondary-color;
  }
}

.icon_check-mark {
  width: 23px;
  height: 20px;
  //fill: $common-dark-gray-color;
}

.icon_report {
  width: 20px;
  height: 25px;
}

.icon_check-mark-small {
  width: 11px;
  height: 10.2px;
  //fill: $common-dark-gray-color;
}

.icon_cancel {
  width: 24px;
  height: 24px;
  //fill: $common-dark-gray-color;
}

.icon_cancel-fee {
  width: 24px;
  height: 24px;
}

.icon_no-show {
  width: 26px;
  height: 26px;
  //fill: $common-dark-gray-color;
}

.icon_dots {
  width: 8px;
  height: 30px;
  fill: transparent;

  &:hover {
    fill: $common-dark-gray-color !important;
    cursor: pointer;
  }
}

.icon_chevron-table {
  width: 26px;
  height: 26px;
  fill: $accent-primary-color;
}

.icon_add-customer {
  width: 22px;
  height: 22px;
  fill: $accent-secondary-color;
}

.icon_add-new-item {
  width: 22px;
  height: 22px;
  fill: $accent-secondary-color;
}

.icon_add-search {
  width: 23px;
  height: 23px;
  fill: $common-gray-color;
}

.icon_back {
  width: 8px;
  height: 15px;
  fill: $accent-secondary-color;
}

.icon_remove {
  width: 29px;
  height: 29px;
}

.icon_add_photo {
  width: 35px;
  height: 35px;
}

.icon_remove_21 {
  width: 21px;
  height: 21px;
  fill: $common-white-color;
}

.icon_view {
  width: 22px;
  height: 12px;
}

.icon_download {
  width: 14px;
  height: 20px;
}

.icon_doc-img,
.icon_doc-pdf {
  width: 40px;
  height: 42px;
  fill: $accent-primary-color;
}

.icon_timer {
  width: 18px;
  height: 20px;
}

.icon_list-view {
  width: 42px;
  height: 27px;
}

.icon_grid-view {
  width: 32px;
  height: 32px;
}

.icon_deceased {
  width: 18px;
  height: 20px;
}

.icon_close {
  width: 10px;
  height: 10px;
}

.icon_password {
  width: 18px;
  height: 21px;
}

.icon_camera-small {
  width: 30px;
  height: 24px;
}

.icon_location {
  width: 18px;
  height: 26px;
}

.icon_card {
  height: 15px;
  width: 24px;
}

.icon_location {
  width: 18px;
  height: 26px;
}

.icon_calendar-date-picker {
  height: 27px;
  width: 26px;
  fill: $common-medium-gray-color;
}

.icon_star {
  height: 20px;
  width: 20px;
}

.icon-chevron-up {
  height: 9px;
  width: 15px;
}

.icon-star-filled {
  width: 26px;
  height: 25px;
}

.icon_image-upload {
  width: 23px;
  height: 18px;
  fill: $common-white-color;
}

.icon_recall {
  width: 22px;
  height: 19px;
}

.icon_behavior {
  width: 22px;
  height: 11px;
}

.icon_new-skill {
  width: 22px;
  height: 15px;
}

.icon_paw {
  width: 19px;
  height: 16px;
}

.icon_scent {
  width: 19px;
  height: 16px;
}

.icon_potty {
  width: 18px;
  height: 16px;
}

.icon_health {
  width: 20px;
  height: 18px;
}

.icon_tricks {
  width: 12px;
  height: 20px;
}

.icon_agility {
  width: 20px;
  height: 18px;
}

.icon_fitness {
  width: 17px;
  height: 16px;
}

.icon_leash_skills {
  width: 22px;
  height: 22px;
}

.icon_swim {
  width: 22px;
  height: 22px;
}

.icon_first_class_comments {
  width: 22px;
  height: 22px;
}

.icon_lure_coursing {
  width: 22px;
  height: 22px;
}

.icon_loader {
  width: 30px;
  height: 30px;
  opacity: 0;
  transition: opacity $short-transition-time;

  @include mobile {
    width: 25px;
    height: 25px;
  }
}

.icon_loader-visible {
  opacity: 1;
  transition: opacity $short-transition-time;
}

.icon_loader-white {
  stroke: $common-white-color;
}

.icon_loader-black {
  stroke: $common-black-color;
}

.icon_loader-accent {
  stroke: $accent-primary-color;
}

.icon_search-small {
  width: 19px;
  height: 19px;
}

.icon_remove-small {
  width: 20px;
  height: 20px;
  fill: $common-gray-color;
  transition: fill $short-transition-time;

  &:hover {
    cursor: pointer;
    fill: $common-dark-gray-color;
    transition: fill $short-transition-time;
  }
}

.icon_error-small {
  width: 20px;
  height: 20px;
  fill: $warning-requires-color;

  @include mobile {
    margin-right: 0;
  }
}

.icon_chevron-left,
.icon_chevron-right {
  width: 9px;
  height: 15px;
  fill: $common-dark-gray-color;
}

.icon-add-card {
  width: 22px;
  height: 22px;
}

.icon-payment-card-option {
  width: 25px !important;
  height: 16px !important;
}

.icon_chevron-long-right {
  width: 20px;
  height: 22px;
  fill: $common-dark-gray-color;
}

.icon_lock {
  width: 35px;
  height: 35px;
}

.icon_customer-logout {
  width: 15px;
  height: 15px;
  display: block;
  margin: 0 auto 7px;
  fill: #fff;
}

.icon_customer-dog {
  width: 34px;
  height: 38px;
  margin-top: -5px;
}

.icon_customer-training {
  margin-top: -3px;
  width: 36px;
  height: 36px;
}

.icon_customer-classes {
  width: 36px;
  height: 34px;
}

.icon_customer-schedule {
  width: 36px;
  height: 34px;
}

.icon_customer-packages {
  width: 36px;
  height: 34px;
}

.icon_disabled {
  fill: $common-medium-gray-color;
}

.icon_checkmark {
  fill: #3ba848;
  width: 15px;
  height: 11px;
  display: inline-block;
  vertical-align: middle;
}

.icon_immunisation-cancel {
  fill: #f00;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

.icon_exclamation {
  fill: #f00;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

.icon_message-remove,
.icon_message-minimize,
.icon_message-maximize {
  fill: $common-white-color;
  width: 21px;
}

.icon_message-remove {
  height: 21px;
}

.icon_message-minimize {
  height: 5px;
}

.icon_message-maximize {
  height: 21px;
}

.icon_message-add-image {
  fill: $common-dark-gray-color;
  width: 26px;
  height: 21px;
  transition: fill $short-transition-time;
}

.icon_message-send {
  fill: $common-dark-gray-color;
  width: 30px;
  height: 30px;
  transition: fill $short-transition-time;
}

.icon_messages-loader-black {
  stroke: $common-black-color;
  width: 60px;
  height: 60px;
}

.icon_messages-loader-more-dark-gray-small {
  stroke: $common-dark-gray-color;
  width: 20px;
  height: 20px;
}

.icon_messages-loader {
  text-align: center;

  .loader__text {
    @extend %typo-messages-empty-text;
  }
}

.icon_messages-loader-more {
  color: $common-dark-gray-color;
  margin-left: 5px;
}

.icon_new-customers,
.icon_new-dogs,
.icon_new-sold-packages,
.icon_new-sold-trainings {
  fill: $accent-secondary-color;
}

.icon_new-customers {
  width: 30px;
  height: 40px;

  @include mobile {
    width: 20px;
  }

  @include small-mobile {
    width: 30px;
  }
}

.icon_new-dogs {
  width: 41px;
  height: 41px;
}

.icon_new-sold-packages {
  width: 39px;
  height: 39px;

  @include mobile {
    width: 26px;
  }

  @include small-mobile {
    width: 39px;
  }
}

.icon_new-sold-trainings {
  width: 34px;
  height: 41px;

  @include mobile {
    width: 22px;
  }

  @include small-mobile {
    width: 34px;
  }
}

.icon_file {
  width: 39px;
  height: 52px;
  margin: 0 auto;
}

.icon_external-link {
  width: 18px;
  height: 18px;
}

.icon_closed-envelope {
  width: 24px;
  height: 17px;
  transition: fill $short-transition-time;
}

.icon_pdf {
  width: 22px;
  height: 27px;
  transition: fill $short-transition-time;
}
