.avatar-list {
  padding: 30px 19px;
  font-size: 0;
  text-align: center;

  &__item {
    display: inline-block;
    width: 140px;
    text-align: center;
    margin: 0 11px 20px;
  }

  &__avatar {
    margin-bottom: 10px;
    .avatar__container {
      cursor: default;
    }
  }

  &__name {
    font-size: 16px;
    text-transform: uppercase;
    font-family: $bold;
    margin-bottom: 2px;
  }

  &__action {
    @extend .link;
    font-family: $bold;
    display: inline-block;
    font-size: 16px;
  }
}
