.image-control {
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.image-control__image-container {
  width: $image-control-container-size;
  height: $image-control-container-size;
  border: solid $image-control-container-border-size $common-dark-gray-color;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  position: relative;
}

.image-control__image-preview,
.image-control__image-empty {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid white;
  overflow: hidden;
  border-radius: 50%;
}

.image-control__image-empty {
  background-color: $common-dim-gray-color;
  svg {
    width: $empty-image-icon-width;
    height: $empty-image-icon-height;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    display: block;
  }
}

.image-control__image-preview {
  img {
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    display: block;
  }
}

.image-control__profile-image {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
}

.image-control {
  .image-control__input_hidden {
    display: none;
  }
}

.image-control__update-image-button {
  margin: 15px auto 0;
  font-family: 'Proxima Nova Regular';
  font-size: 18.5px;
  font-weight: 600;
  letter-spacing: .5px;
  text-align: center;
  color: $common-dark-gray-color;
  transition: color $short-transition-time, border-color $short-transition-time;
  cursor: pointer;
  user-select: none;
  width: 121px;
  display: block;
  border-bottom: 1px solid $common-dark-gray-color;
  &:hover {
    color: $common-dim-gray-color;
    border-color: $common-dim-gray-color;
  }
}

.image-control__error {
  @extend .input-control__error;
  position: absolute;
  bottom: .73rem;
}

.image-control__zone_failed {
  @extend .input-control__input_failed;
  border-width: 1px;
  border-style: solid;

  .dog-report-form__image-preview_empty {
    background-color: inherit !important;
  }
}
