.customers {
  @extend %background-pattern;
  @extend %dashboard__list;
}

.customers__filter {
  width: 220px;
  height: 50px;
  margin: 0 auto;
}

.customers__add-customer {
  @extend %flex-container-rows-centred;
  position: absolute;
  right: 2.9rem;
  top: calc(1.23rem + 15px);

  @include mobile {
    position: initial;
    margin-top: 1.23rem;
  }
}

.customers-table {
  margin-top: 1.23rem;

  .rt-tbody {
    //overflow-y: scroll;
    min-height: calc(100vh - 25.6rem);
    max-height: calc(100vh - 25.6rem);

    @media screen and (max-width: 1020px) {
      min-height: calc(100vh - 25.6rem - 16px);
      max-height: calc(100vh - 25.6rem - 16px);
    }
  }

  //// workaround for FIT-478
  //// consider 9 columns + 100px + 16px for scroll bar
  //.ReactTable.-with-scroll-bar {
  //  .rt-tbody,
  //  .rt-thead {
  //    min-width: 866px !important;
  //  }
  //}
}

.customers-table__header {
  @extend %table__header;
}

.customers-table__header-content {
  @extend %table__header-content;
}

.customers-table__th {
  @extend %table__th;
}

// workaround for FIT-478
.customers-table__th_last-of-type {
  //@media screen and (max-width: 952px) {
  width: 115px !important;
  //}
}

.customers-table__th_select {
  @extend %table__th_select;
}

.customers-table__td {
  @extend %table__td;
  word-break: break-all;
}

.customers-table__td_email {
  word-break: break-all;
}

.customers-table__customer-link {
  color: $common-dark-gray-color;
  text-decoration: underline;
  word-break: break-all;
  display: block;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  &:hover {
    cursor: pointer;
  }
}
