$min-height-desktop: 360px;
$min-height-tablet: 332px;
$min-height-mobile: 346px;

.dropdown-modal {
  position: relative;
  background-color: $common-gray-color_transparent;
  border-top: solid .5px $common-medium-gray-color_accent;
  border-bottom: solid .5px $common-medium-gray-color_accent;
  min-height: $min-height-desktop;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @include tablet {
    min-height: $min-height-tablet;
  }

  @include mobile {
    min-height: $min-height-tablet;
  }
}

.dropdown-modal__close {
  position: absolute;
  display: flex;
  background-color: transparent;
  top: 14px;
  right: 14px;
  border: none;
  z-index: 999;
}

.dropdown-modal__title {
  @extend %typo-content-card-header;
  position: absolute;
  top: 0;
  padding-top: 28px;
  width: 100%;
  text-align: center;
  color: $common-dark-gray-color;
  @include mobile {
    padding-top: 48px;
  }
}

.dropdown-modal__empty {
  text-align: center;
  height: $min-height-desktop;
  padding: 0 16px;

  @include tablet {
    height: $min-height-tablet;
  }

  @include mobile {
    height: $min-height-mobile;
  }
}

.dropdown-modal__empty-title {
  @extend %typo-dropdown-modal-empty-title;
  color: $common-dark-gray-color;
  padding: 135px 0 32px;
  @include mobile {
    padding-top: 123px;
  }
}

.dropdown-modal__empty-text {
  @extend %typo-dropdown-modal-empty-text;
  color: $common-gray-color;
}

.dropdown-modal__loader {
  @extend %flex-container-columns-centred;
  height: 100%;
  text-align: center;

  .icon_loader {
    width: 50px;
    height: 50px;
  }
}
