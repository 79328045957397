.attending-dogs-grid {
  @extend %flex-container-rows-wrapped-start-between;
  display: none;

  @include mobile {
    display: flex;
    justify-content: center;
  }
  @include tablet {
    display: flex;
  }
}

.attending-dogs-grid__empty-content {
  @extend %table__empty-content;
  width: 100%;
}

.attending-dogs-card-container {
  @extend .employee-card-container;

  @include mobile {
    width: 260px;
  }

  @include tablet {
    width: 258px;
  }
}

.attending-dogs-card {
  @extend .employee-card;
}

.attending-dogs-card__header {
  @extend .employee-card__header;
  min-height: 13.5rem;
}

.attending-dogs-card__img-container {
  @extend .employee-card__img-container;
}

.attending-dogs-card__img {
  @extend .employee-card__img;
}

.attending-dogs-card__header-title {
  @extend .employee-card__header-title;
}

.attending-dogs-card__header-text {
  @extend .employee-card__header-text;
}

.attending-dogs-card__content {
  @extend .employee-card__content;
  border-radius: 0;
  border-bottom: 1px solid #e8e8e8;
}

.attending-dogs-card__item {
  @extend .employee-card__item;
}

.attending-dogs-card__item-header {
  @extend .employee-card__item-header;
}

.attending-dogs-card__item-content {
  @extend .employee-card__item-content;
}

.attending-dogs-card__expand-button-wrapper {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.attending-dogs-card__expand-button {
  @extend %typo-link-button;
  @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
  color: $common-dark-gray-color;
  display: inline-block;
  vertical-align: sub;
  &:hover {
    @include underline(false, 1px, 100%, $accent-secondary-color, .3);
    color: $accent-secondary-color;
  }
}

button.attending-dogs-card__expand-button {
  background: none;
  border: none;
  padding: 0;
}

