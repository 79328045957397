.customer-class-card {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: $common-shadow;
  width: 300px;
  margin: 0 10px 20px;
  position: relative;
  overflow: hidden;
  @include desktop {
    margin: 0 0 20px;
    &:nth-child(3n+2) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &_empty {
    visibility: hidden;
    opacity: 0;
    margin-bottom: 0;
    height: 0;
  }

  &__header {
    height: 200px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    border-bottom: 5px solid $accent-primary-color;
    position: relative;
  }

  &__spots {
    position: absolute;
    font-size: 12px;
    top: 10px;
    left: 10px;
    background: $accent-primary-color;
    padding: 5px 10px 3px;
    text-transform: uppercase;
    border-radius: 10px;
    font-family: $bold;
    color: #fff;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 246px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px 20px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
  }

  &__action {
    padding: 30px 20px;
  }

  &__car {
    width: 18px;
    height: 18px;
    margin-right: 13px;
  }

  &__access {
    font-family: $extrabold;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: .6px;
    text-transform: uppercase;

    &_full-access {
      color: $accent-health-color;
    }
  }

  &__title {
    font-family: $bold;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 3px;
  }

  &__loaction,
  &__price {
    color: $common-gray-color;
    font-family: $bold;
    font-size: 15px;
    display: inline-block;
  }

  &__loaction {
    &:after {
      margin: 0 5px;
    }
  }

  &__time {
    padding: 15px 20px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 1.2;
  }

  &__time-list {
    text-align: left;
  }

  &__button {
    width: 100% !important;
    margin: 0 0 23px !important;
  }
}
