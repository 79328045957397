.package-create-modal {
  .radio-control {
    display: block;
    @extend .form__aligner;
    @extend .form__aligner_60-40;
  }
  .radio-control__controls-text {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.package-create-modal__head {
  margin-top: -37px;

  @include mobile {
    margin-top: 20px;
  }
}

.package-create-modal__name {
  margin-top: 60px !important;

  @include mobile {
    margin-top: 20px !important;
  }
}
