@mixin typo-font-family($use) {
  @if $use =='preferred' {
    //font-family: 'Proxima Nova Regular';
    font-family: $preferred_font;
  }

  @else {
    font-family: $performance_font;
  }
}

%typo-social-login-buttons {
  font-size: 1.14rem;
  letter-spacing: .8px;
}

%typo-input-placeholder {
  font-size: 1.47rem;
  letter-spacing: .5px;
  font-family: 'ProximaNova-Extrabld';
}

%typo-input-value {
  font-size: 1.34rem;
  font-family: 'Proxima Nova Regular';
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 1.4;
}

%typo-input-top-placeholder {
  font-size: .73rem;
  font-family: 'ProximaNova-Bold';
  text-transform: uppercase;
  letter-spacing: .8px;
}

%typo-input-hint {
  font-size: .87rem;
  letter-spacing: .3px;

  @include not-desktop {
    font-size: 1.14rem;
    letter-spacing: .4px;
  }
}

%typo-input-error {
  font-size: 1rem !important;
  letter-spacing: .3px !important;
  font-weight: normal !important;
}

%typo-button-submit {
  font-size: 1.14rem;
  font-family: 'ProximaNova-Bold';
  text-transform: uppercase;
  letter-spacing: .8px;
}

%typo-step-name {
  font-size: 1.34rem;
  font-family: 'ProximaNova-Extrabld';
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

%typo-step-description-header {
  @extend %typo-input-placeholder;
  line-height: 1.8rem;
  font-family: 'ProximaNova-Bold';
  text-transform: uppercase;
}

%typo-step-description-disclaimer {
  font-size: 1.14rem;
  line-height: 1.8rem;
  letter-spacing: .4px;
}

%typo-radio-control-text {
  line-height: 0;
  font-weight: 500;
  font-size: 15.7px;
  letter-spacing: .4px;
}

%typo-radio-control-description-label {
  font-size: 1.14rem;
  font-family: 'ProximaNova-Bold';
  line-height: 1.25;
  letter-spacing: .4px;
}

%typo-radio-control-description-warning {
  @extend %typo-input-error;
  line-height: 1.25;
}

%typo-dnd-control {
  font-family: 'Proxima Nova Regular';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: .4px;
}

%typo-skip-step {
  @extend %typo-social-login-buttons;
}

%typo-vet-info-helper {
  font-size: 1.14rem;
  letter-spacing: .4px;
}

%typo-forgot-password-retry {
  @extend %typo-vet-info-helper;
}

%typo-search-bar-input {
  font-size: 1.16rem;
  font-family: 'Proxima Nova SemiBold';
  letter-spacing: 1.2px;
}

%typo-welcome-back-button-header {
  font-size: 1.16rem;
  letter-spacing: 1px;
  font-family: 'ProximaNova-Extrabld';
  text-transform: uppercase;
}

%typo-welcome-back-button-description {
  font-size: 1.16rem;
  letter-spacing: .4px;

  @include mobile {
    line-height: 1.38;
  }
}

%typo-zone-preview-text {
  font-size: 1.14rem;
  font-family: 'Proxima Nova SemiBold';
  //font-weight: bold;
  letter-spacing: .5px;
}

%typo-checkbox-text {
  @extend %typo-vet-info-helper;
  font-weight: 500;
}

%typo-from-error {
  letter-spacing: .3px;

  @include mobile {
    font-size: 1.23rem;
    letter-spacing: .4px;
  }
}

%typo-divider-text {
  font-size: .87rem;

  @include not-desktop {
    font-size: 1.14rem;
    letter-spacing: .4px;
  }
}

%typo-contact-card-title {
  font-family: 'ProximaNova-Extrabld';
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

%typo-contact-card-status {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.23rem;
  letter-spacing: .8px;
  font-weight: 600;
  color: $common-gray-color;
}

%typo-contact-card-text {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.23rem;
  line-height: 1.5rem;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}

%typo-avatar-text {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.14rem;
  font-weight: 600;
  letter-spacing: .7px;
}

%typo-navbar-link {
  font-family: 'Proxima Nova Regular';
  font-size: .87rem;
  font-weight: 600;
  letter-spacing: .9px;
  text-transform: uppercase;
}

%typo-sidebar-link {
  font-family: 'Proxima Nova Regular';
  font-weight: 600;
  font-size: 1.23rem;
  letter-spacing: .4px;
}

%typo-dropdown-menu {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.23rem;
  letter-spacing: .8px;
}

%typo-dropdown-menu-extra-text {
  font-size: .87rem;
  line-height: 1;
  letter-spacing: .6px;
}

%typo-content-card-header {
  font-family: 'ProximaNova-Extrabld';
  font-size: 1.23rem;
  letter-spacing: .9px;
  text-transform: uppercase;
}

%typo-content-card-item-header {
  font-family: 'ProximaNova-Bold';
  font-size: 1.16rem;
  letter-spacing: .8px;
}

%typo-employee-card-header-title {
  @extend %typo-content-card-item-header;
  line-height: 23px;
}

%typo-employee-card-header-text {
  @extend %typo-content-card-item-content;
  line-height: 23px;
}

%typo-content-card-item-content {
  font-size: 1.16rem;
  letter-spacing: .8px;
}

%typo-table-header {
  font-family: 'ProximaNova-Extrabld';
  font-size: 17px;
  letter-spacing: .9px;
  text-transform: uppercase;
}

%typo-table-cell {
  @extend %typo-content-card-item-content;
}

%typo-table-column {
  @extend %typo-table-cell;
  font-weight: 600;
}

%typo-table-search-input {
  @extend %typo-content-card-item-content;
}

%typo-table-primary {
  font-family: 'Proxima Nova SemiBold';
  font-size: 16px;
  letter-spacing: .8px;
  color: $common-dark-gray-color;
}

%typo-table-secondary {
  font-family: 'Proxima Nova Regular';
  font-size: 14px;
  letter-spacing: .7px;
  color: $common-gray-color;
}

%typo-add-new-customer {
  @extend %typo-social-login-buttons;
  font-family: 'Proxima Nova SemiBold';
}

%typo-add-new-item {
  @extend %typo-social-login-buttons;
  font-family: 'ProximaNova-Bold';
}

%typo-dog-evaluation-question {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.44rem;
  line-height: 30px;
  letter-spacing: 1px;
}

%typo-dog-evaluation-answer {
  font-family: $preferred_font;
  font-size: 1.44rem;
  line-height: 25px;
  letter-spacing: 1px;
}

%typo-modal-dialog {
  font-family: 'ProximaNova-Extrabld';
  color: $common-white-color;
  font-size: 1.23rem;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%typo-badge {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.01rem;
  line-height: 11px;
  letter-spacing: .7px;
}

%typo-dog-report-title {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.48rem;
  line-height: 1.25;
  letter-spacing: 1px;
}

%typo-dog-report-date {
  font-family: $preferred_font;
  font-size: 1.1rem;
  line-height: 1.67;
  letter-spacing: .8px;
}

%typo-dog-report-card-header {
  font-family: $preferred_font;
  font-size: 1.13rem;
  letter-spacing: .8px;
}

%typo-dog-report-form-image-upload-button {
  font-family: 'Proxima Nova SemiBold';
  font-size: 13.8px;
  letter-spacing: .3px;
}

%typo-location {
  font-family: ProximaNova-Bold;
}

%typo-filter-tag {
  font-family: $preferred_font;
  font-size: 1.34rem;
  font-weight: 600;
  letter-spacing: .5px;
  margin: 5px;
}

%typo-link-button {
  @extend %typo-add-new-item;
}

%typo-modal-dialog-form-title {
  font-size: 1.34rem;
  font-family: 'ProximaNova-Extrabld';
  letter-spacing: .5px;
  text-transform: uppercase;
}

%typo-modal-text {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.16rem;
  font-weight: 400;
  letter-spacing: .8px;
  color: $common-dark-gray-color;
}

%typo-input-placeholder-empty {
  font-size: 1.34rem;
  position: absolute;
  vertical-align: bottom;
  left: .8rem;
  pointer-events: none;
  opacity: .5 !important;
  z-index: 1;
  font-family: 'Proxima Nova SemiBold';
  text-transform: none;

  &:not(.input-control__placeholder_failed) {
    color: $common-gray-color  !important;
  }
}

%type-vertical-align {
  top: 50%;
  transform: translateY(-50%);
}

%type-vertical-horizontal-align {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

%typo-input-placeholder-empty-vertical-align {
  @extend %type-vertical-align;
  top: $input-height/2;
}

%typo-datepicker-font-basic {
  font-family: 'Proxima Nova Regular';
  font-size: 14px;
  letter-spacing: .7px;
}

%typo-modal-dialog-tab-name {
  font-family: 'Proxima Nova Regular';
  font-weight: 600;
  font-size: 14.8px;
  letter-spacing: .7px;
}

%typo-dog-report-form-icon-text {
  font-family: 'Proxima Nova Regular';
  font-size: 16px;
  letter-spacing: .4px;
}

%typo-dog-report-form-title {
  font-family: 'ProximaNova-Bold';
  font-size: 20.5px;
  line-height: 1.25;
  letter-spacing: 1px;
}

%typo-dog-report-form-date {
  font-family: 'Proxima Nova Regular';
  font-size: 15.3px;
  line-height: 1.67;
  letter-spacing: .8px;
}

%typo-option-secondary {
  font-family: 'Proxima Nova Regular';
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: .6px;
}

%typo-option-unavailable-text {
  font-family: 'Proxima Nova Regular';
  font-weight: 600;
  font-size: 13.8px;
  letter-spacing: .3px;
}

%typo-option-unavailable-access-level {
  font-family: 'Proxima Nova Regular';
  font-weight: 600;
  font-size: 15.7px;
  letter-spacing: .4px;
}

%typo-calendar-heading {
  font-size: 1.16rem;
  font-weight: 600;
  letter-spacing: .8px;
}

%typo-calendar-time-label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .7px;
  text-align: right;
  color: $common-dark-gray-color;
}

%typo-calendar-header-weekday {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .7px;
  color: $common-dark-gray-color;
  text-transform: uppercase;
}

%typo-calendar-header-date {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: .7px;
  color: $common-dark-gray-color;
}

%typo-calendar-show-more {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .6px;
  text-align: center;
  color: #00a1c3;
}

%typo-avatar-upload-btn {
  font-family: 'ProximaNova-Bold';
  font-size: 14px;
}

%typo-calendar-event {
  font-size: 14px;
  letter-spacing: .7px;
  color: $common-dark-gray-color;
}

%typo-event-spots {
  font-size: 16px;
  letter-spacing: .8px;
}

%typo-avatar-upload-btn {
  font-family: 'ProximaNova-Bold';
  font-size: 14px;
}

%typo-card-name {
  font-size: 20px;
  font-weight: bold;
  color: #373e48;
}

%typo-event-warning {
  font-family: 'Proxima Nova SemiBold';
  font-size: 16px;
  letter-spacing: .8px;
  font-weight: 600;
}

%typo-error-handler {
  font-size: 1.23rem;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: .9px;
}

%typo-zone-card-number {
  font-family: 'ProximaNova-Bold';
  font-size: 20px;
  letter-spacing: 1px;
}

%typo-zone-card-name {
  font-family: 'Proxima Nova Regular';
  font-size: 16px;
  letter-spacing: .8px;
}

%typo-dropdown-modal-empty-title {
  font-family: 'ProximaNova-Bold';
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: .8px;
}

%typo-dropdown-modal-empty-text {
  font-family: 'Proxima Nova Regular';
  font-size: 15px;
  line-height: 23px;
  letter-spacing: .4px;
}

%typo-notes-items-position {
  font-family: 'Proxima Nova Regular';
  font-size: 16px;
  letter-spacing: .8px;
}

%typo-notes-items-time {
  font-family: 'Proxima Nova Regular';
  font-size: 14px;
  letter-spacing: .7px;
}

%typo-notes-item-name {
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
  letter-spacing: .8px;
}

%typo-text-editor-placeholder-blank {
  font-family: 'Proxima Nova SemiBold';
  font-size: 1.34rem;
  vertical-align: bottom;
  z-index: 1;
  text-transform: none;
}

%typo-text-editor-toolbar {
  font-family: 'Proxima Nova Regular';
  font-size: 23px;
}

%typo-ql-editor {
  font-family: 'Proxima Nova Regular';
  font-size: 15.7px;
  letter-spacing: .4px;
  line-height: 1.59;
}

%typo-ql-picker-label {
  font-family: 'Proxima Nova SemiBold';
  font-size: 15.7px;
  letter-spacing: .4px;
  font-weight: 600;
}

%typo-messages-header {
  font-family: 'ProximaNova-Extrabld';
  font-size: 17px;
  letter-spacing: .9px;
  color: $common-white-color;
}

%typo-messages-empty-text {
  font-size: 16px;
  letter-spacing: .8px;
  text-align: center;
  color: $common-dark-gray-color;
}

%typo-message-header {
  font-family: 'ProximaNova-Bold';
  font-size: 12px;
  letter-spacing: .6px;
  color: $common-dark-gray-color;
}

%typo-message-body {
  font-family: 'Proxima Nova Regular';
  font-size: 16px;
  letter-spacing: .8px;
  color: $common-dark-gray-color;
}

%typo-message-status {
  font-family: 'Proxima Nova SemiBold';
  font-size: 12px;
  letter-spacing: .6px;
}

%typo-message-load-more {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .6px;
  color: $common-dark-gray-color;
}

%typo-overview-new-objects-item-text {
  font-size: 14px;
  letter-spacing: .7px;
  text-align: center;
  color: $common-dark-gray-color;
}

%typo-overview-new-objects-item-amount {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: $common-dark-gray-color;
}

%typo-charts-title {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: .9px;
  text-transform: uppercase;
  color: $common-dark-gray-color;
}

%typo-charts-tooltip {
  font-size: 13.6px;
  font-weight: bold;
  color: $common-dark-gray-color;
}

%typo-charts-text {
  font-size: 16px;
  letter-spacing: .8px;
  text-align: center;
  color: $common-gray-color;
}

%typo-charts-legend {
  font-size: 12px;
  letter-spacing: .7px;
  color: $common-dark-gray-color;
}

%typo-template-selector-template-header {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .5px;
  color: $common-dark-gray-color;
}

%typo-image-selector {
  font-family: 'Proxima Nova SemiBold';
  font-size: 16px;
  letter-spacing: .8px;
  line-height: normal;
  color: #c1c1c1;
}

%typo-template-button {
  font-family: Verdana, sans-serif;
  font-size: 16px;
  letter-spacing: .8px;
  text-transform: uppercase;
}

%typo-template-title {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: .5px;
}

%typo-template-body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  letter-spacing: .5px;
}

%typo-custom-report-features {
  font-size: 15.7px;
  letter-spacing: .8px;
  color: $common-dark-gray-color;
}

%typo-report-card {
  font-family: 'ProximaNova-Extrabld';
  font-size: 24.3px;
  line-height: 1.67;
  letter-spacing: .7px;
  text-align: left;
  color: rgba(255, 255, 255, .7);
}