.customers-vaccinations {
  @extend .customers-classes;
}

.vaccinations-table {
  @extend .classes-table;
}

.vaccinations-table__header {
  @extend %table__header;
}

.vaccinations-table__header-content {
  @extend %table__header-content;
}

.vaccinations-table__th {
  @extend %table__th;
}

.vaccinations-table__td {
  @extend %table__td;
}

.vaccinations-table__empty-content {
  @extend %table__empty-content;
}

.vaccinations-table__doc-name {
  @extend %flex-container-rows-centred;
  @extend %truncate-this;
}

.vaccinations-table__doc-name-text {
  margin-left: 20px;
  @extend %truncate-this;
}

.customers-vaccinations__add-new {
  @extend %flex-container-rows-centred;
}

.customers-vaccinations__add-new-container {
  @extend %flex-container-rows-centred-end;
  margin-top: -25px;
  margin-bottom: -17px;
}
