.card-selector {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 14px;
    user-select: none;
  }

  &__icon {
    cursor: pointer;
  }

  &__content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 13px;
    margin-top: -1px;
  }

  &__text {
    font-size: 16px;
    letter-spacing: .8px;
  }

  &__button {
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    color: $accent-secondary-color;
    letter-spacing: .6px;
    transition: color $duration;
    margin-top: -1px;
    &:hover {
      @extend %faster;
      color: darken($accent-secondary-color, 20%);
    }
  }
}
