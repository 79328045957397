%divider {
  //border-top: 1px solid $common-medium-gray-color;
  border: none;
  background-color: $common-medium-gray-color;
  text-align: center;
  margin: 2.61rem 0;
  width: 100%;
  height: 1px;
}

%divider-text {
  @extend %typo-divider-text;
  display: inline-block;
  position: relative;
  padding: 0 1.09rem;
  top: -.6rem;
  background-color: white;
  color: $common-gray-color;

  @include not-desktop {
    top: -.5rem;
  }
}

