.step-description__text {
  @extend %typo-step-description-header;
  color: $common-dark-gray-color;
  text-align: center;
}

.step-description__disclaimer {
  @extend %typo-step-description-disclaimer;
  color: $common-gray-color;
  text-align: center;

  @include mobile {
    padding: 0 1.45rem;
  }
}

.step-description__arrow,
.step-description__image {
  @include desktop {
    display: none;
  }
}

@keyframes bounce {
  0%, 20%, 60%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  80% {
    transform: translateY(-10px);
  }
}

.step-description__arrow {
  @extend %flex-container-rows-centred;
  animation: bounce 2s infinite ease-in-out;

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 9px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSI5Ij4NCiAgICA8cGF0aCBmaWxsPSIjMzczRTQ4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy45OTcuOTU5bC0uMzQzLS4zMjItLjY3OC0uNjM2LS42NzguNjM2Yy0uNDg1LjQ1My0xLjI3IDEuMTkzLTIuMjA3IDIuMDhsLTIuNTkgMi40NDktMi41ODktMi40NDlBNTY2LjM5IDU2Ni4zOSAwIDAgMCAyLjcwNC42MzdMMi4wMjUuMDAxbC0uNjc2LjYzNmMtLjEwMy4wOTYtLjIxOC4yMDMtLjM0Mi4zMjJsLTEuMDA1Ljk1IDMuNzQ1IDMuNTQzYzEuMzU3IDEuMjg0IDIuNDYgMi4zMjYgMy4wNzUgMi45MDVsLjY3OS42NDEuNjc5LS42NDFhMTA5OC41IDEwOTguNSAwIDAgMCAzLjA3Ni0yLjkwNWwzLjc0NS0zLjU0My0xLjAwNC0uOTV6Ii8+DQo8L3N2Zz4NCg==");
  }

  @include tablet {
    margin: 1.59rem 0;
  }

  @include mobile {
    margin: 1.74rem 0;
  }
}

.step-description__image {
  height: 100%;
  background-image: url('../../../assets/imgs/slide-1_tablet.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 82.336%;
  width: 100vw;
  margin: 0 calc(50% - 50vw);

  @include mobile {
    padding-bottom: 62.5%;
    background-image: url('../../../assets/imgs/slide-1_mobile.jpg');
  }
}

