.tooltip {
  position: relative;
  z-index: 2;

  &:hover &__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    @extend %faster;
  }

  &__content {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity $duration, visibility $duration, transform $duration;
    transform: translateY(-10px);
    color: #fff;
    background: $common-dark-gray-color;
    padding: 15px 20px;
    font-size: 12px;
    width: 270px;
    z-index: 2;
    line-height: 1.33;
    text-align: center;
    letter-spacing: .6px;
    text-transform: none;
    bottom: 100%;
    margin-bottom: 28px;
    left: 50%;
    margin-left: -135px;
    cursor: initial;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 18px 9px 0;
      border-color: $common-dark-gray-color transparent transparent transparent;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -9px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      top: 100%;
      height: 28px;
      left: 0;
    }

    strong {
      color: $accent-primary-color;
    }
  }

  &__link {
    color: #fff;
    font-family: $bold;

    &:hover {
      text-decoration: underline;
    }
  }

  &_mobile-right {
    @include mobile {
      .tooltip__content {
        transform: translateY(-50%) !important;
        bottom: auto;
        left: 100%;
        margin: 0;
        width: 190px;
        top: 50%;

        &:after {
          display: none;
        }
        &:before {
          left: auto;
          top: 50%;
          right: 100%;
          border-width: 9px 18px 9px 0;
          border-color: transparent $common-dark-gray-color transparent transparent;
          margin-top: -9px;
        }
      }
    }
  }
}
