.custom-reports {
  position: relative;
  margin-top: 35px;
  margin-bottom: 70px;

  @include mobile {
    margin-bottom: 140px;
  }

  .ReactTable {
    margin-top: 35px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__inner-table {
    padding: 24px;
    background: $common-gray-color_transparent;
    .ReactTable {
      border: none;
    }
    .rt-tr-group {
      border-bottom: none !important;
    }
    .rt-tfoot {
      border-top: 1px solid #dddddc;
    }
    &__th {
      @extend %table__th;
      font-size: 16px;
      font-family: $bold;
      font-weight: normal;
      letter-spacing: .8px;
      border-right: none !important;
      border-bottom: 1px solid #dddddc;
    }

    &__td {
      background: $common-gray-color_transparent;
      border: none !important;
      padding: 14px;
      font-size: 16px;
      overflow: visible !important;
    }
  }

  &__expand-btn {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      width: 10px;
      height: 15px;
      transition: transform $duration, opacity $duration;
      transform-origin: 50%;
      opacity: .3;
    }
    &:hover &__icon {
      opacity: 1;
    }
    &_expanded &__icon {
      transform: rotate(90deg);
      transform-origin: 50%;
    }
  }
  .rt-tfoot {
    box-shadow: none;
    background-color: $common-gray-color_transparent;
    color: $common-dark-gray-color;

    .custom-reports__td {
      border: none;
    }
  }
}

.custom-reports__th {
  @extend %table__th;
}

.custom-reports__td {
  @extend %table__td;
}

.custom-reports__empty-content {
  @extend %table__empty-content;
}

.detailed-sales > .rt-table > .rt-thead {
  display: none;
  visibility: hidden;
}

.custom-reports__expand-btn_sub {
  background-color: $common-gray-color_transparent;
}

.detailed-sales__sub-inner-table {
  .rt-tr-group:last-of-type {
    display: none;
    visibility: hidden;
  }
}

.custom-reports__error-text {
  color: red;
}

.ReactTable {
  .custom-reports__th_wrapped,
  .custom-reports__inner-table__th_wrapped,
  .trainers-invoice__th_wrapped {
    white-space: normal;
  }
}

.custom-reports__features {
  @extend %flex-container-rows-centred-end;
  @extend %typo-custom-report-features;
  margin-bottom: 16px;

  @include mobile {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}

.custom-reports__wrapper {
  display: flex;
  gap: 10px;
}
.custom-reports__save-as-campaign-target,
.custom-reports__export-report-to {
  @extend %flex-container-rows-centred;
}

.custom-reports__save-as-campaign-target {
  margin-left: 20px;

  &:hover {
    cursor: pointer;

    .icon_closed-envelope {
      fill: $accent-secondary-color;
      transition: fill $short-transition-time;
    }
  }

  @include mobile {
    margin-left: 0;
    margin-top: 10px;

    .icon {
      order: 1;
    }

    .save-as-campaign-target__text {
      order: 2;
    }
  }
}

.custom-reports__export-report-to {
  &:hover {
    cursor: pointer;

    .icon_pdf {
      fill: $accent-secondary-color;
      transition: fill $short-transition-time;
    }
  }

  @include mobile {
    .icon {
      order: 1;
    }

    .export-report-to__text {
      order: 2;
    }
  }
}

.save-as-campaign-target__text,
.export-report-to__text {
  margin-right: 8px;

  @include mobile {
    margin-right: 0;
    margin-left: 8px;
  }
}
