$facebook-bg-color: #3b5998;
$google-text-color: #787878;
$social-login-border-color: #3e82f1;

.social-login__button {
  @extend %flex-container-rows-centred;
  @extend %typo-social-login-buttons;
  background-color: white;
  border: 1px solid $social-login-border-color;
  text-decoration: none;
  color: white;
  height: 4rem;

  &:hover {
    cursor: pointer;
  }
}

.social-login__button--google {
  color: $google-text-color;
}

.social-login__button--facebook {
  width: 100%;
  background-color: $facebook-bg-color;
  display: inline-block;
  text-align: center;
  .social-login__icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.social-login__button--signup {
  margin-top: .5rem;
  text-transform: uppercase;
  background-color: gray;
}

.social-login__icon {
  margin: 0 1rem 0 -1rem;
  fill: $common-white-color;
}

