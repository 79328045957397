.textarea {
  border: 1px solid $common-medium-gray-color_accent;
  border-radius: 3px;
  padding: 18px;
  max-height: 350px;
  overflow-y: scroll;
  font-size: 16px;
  letter-spacing: .4px;
  line-height: 1.2;

  @include mobile {
    padding: 18px 16px;
  }

  &__content {
    width: 100%;
    overflow: hidden;
  }
}
