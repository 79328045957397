.grid-card-container {
  @extend .employee-card-container;
}

.grid-card-container_empty {
  @extend .employee-card-container_empty;
}

.grid-card {
  @extend .employee-card;
}

.grid-card__header {
  @extend .classes-schedule-card__header;
}

.grid-card__price {
  @extend .classes-schedule-card__header-title;
}

.grid-card__name {
  @extend .classes-schedule-card__name-info;
  @extend %typo-card-name;
  @extend %truncate-this;
}

.grid-card__items {
  @extend .classes-schedule-card__content;
}

.grid-card__item {
  @extend .classes-schedule-card__item;
}

.grid-card__item-header {
  @extend .content-card__item-header;
}

.grid-card__item-content {
  @extend .content-card__item-content;
}

.grid-loader {
  @extend %flex-container-columns-centred;
  position: absolute;
  bottom: 230px;
  right: 21px;
  width: 260px;

  @include tablet {
    width: 300px;
  }

  @include mobile {
    width: 260px;
  }
}
