.remove-item {
  @extend %flex-container-rows-centred;
  cursor: pointer;

  &:hover &__text {
    color: $accent-secondary-color-hover;
    @extend %faster;
  }

  .icon_add-new-item {
    fill: $warning-color !important;
    transform: rotate(45deg);
    transition: fill $duration;
  }

  &:hover .icon_add-new-item {
    fill: $accent-secondary-color-hover !important;
    @extend %faster;
  }

  .remove-item__text {
    @extend %typo-add-new-item;
    white-space: nowrap;
    color: $warning-color;
    margin-right: .43rem;
    transition: color $duration;
  }

  &_disabled {
    pointer-events: none;
    .icon_add-new-item {
      fill: $common-gray-color !important;
    }
    .remove-item__text {
      color: $common-gray-color !important;
    }
  }
}
