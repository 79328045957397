.reports {
  @extend .dashboard__main;
}

.reports__pages {
  @extend .dashboard-page;
}

.reports__navbar > div {
  @extend %flex-container-rows-centred;
}
