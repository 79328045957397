.template-title {
  @extend %typo-template-title;
  cursor: text;
  color: $common-dark-gray-color;
  margin-bottom: 1.5rem;
  & input[type="text"],
  & label,
  & .preview {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    padding: 0 5px;
  }
  & input[type="text"] {
    border: 1px dashed $common-white-color;
    border-radius: $common-border-radius;
    transition: border-color $medium-transition-time;
    color: inherit;
  }
  & input[type="text"]:hover {
    border: 1px dashed $common-medium-gray-color;
  }
  & input[type="text"]:focus {
    border: 1px dashed $accent-primary-color;
  }
}
