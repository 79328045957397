.employee-card-container {
  @extend %hover-card;
  //width: calc(100% / 3 - 20px);
  width: 260px;
  margin: 0 10px 28px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  border-radius: 3px;

  &:before {
    border-radius: 3px;
  }

  &:hover {
    // for FIT-532 moved this z-index from %hover-card
    // else it breaks content-cards on DetailsPage with column layout in safari
    z-index: 1;
  }

  @include not-desktop {
    margin-bottom: 16px;

    &:nth-child(odd) {
      margin-right: auto;
      margin-left: 0;
    }

    &:nth-child(even) {
      margin-left: auto;
      margin-right: 0;
    }
  }

  @include tablet {
    //width: calc(50% - 12px);
    width: 300px;
  }

  @include mobile {
    width: 260px;
  }

  @media screen and (max-width: 596px) {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.employee-card-container_empty {
  height: 0;
  border: none;
  margin-bottom: 0;

  @include mobile {
    display: none;
  }
}

.employee-card {
  height: 100%;
  border-radius: 3px;

  .icon_chevron_down {
    fill: transparent;
  }

  &:hover {
    .icon_chevron_down {
      fill: $common-gray-color;

      &:hover {
        fill: $common-dark-gray-color;
      }
    }
  }
}

.employee-card__header {
  border-radius: 3px 3px 0 0;
  background-color: $common-gray-color_transparent;
  padding: 20px;
  text-align: center;
  min-height: 16.5rem;
}

.employee-card__header-title {
  @extend %typo-employee-card-header-title;
  @extend %truncate-this;
}

.employee-card__header-text {
  @extend %typo-employee-card-header-text;
  line-height: 23px;
}

.employee-card__header-text_email {
  @extend %comments-word-breaker;
  min-height: 47px;
}

.employee-card__img-container {
  margin: 0 auto 22px;
  padding: 2px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: $common-white-color;
  border: 2px solid $common-dark-gray-color;
}

.employee-card__img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.employee-card__content {
  border-radius: 0 0 3px 3px;
  background-color: $common-white-color;
  padding: 20px;
}

.employee-card__item {
  @extend .content-card__item;
  margin-top: 1.3rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.employee-card__item-header {
  @extend .content-card__item-header;
}

.employee-card__item-content {
  @extend .content-card__item-content;
}
