.class-event-slider-container {
  position: relative;
  margin: 0 auto;
  max-width: 896px;
  height: auto;
  @include tablet {
    max-width: 502px;
  }
  @include mobile {
    max-width: 272px;
  }
}

.class-event-slider {
  @extend %flex-container-columns-centred;
  margin: 65px auto 36px;
  text-align: center;
  width: 756px;
  height: 100%;
  overflow: hidden;
  @include tablet {
    width: 362px;
  }
  @include mobile {
    width: 272px;
    overflow: visible;
    margin-top: 90px;
    margin-bottom: 16px;
  }
}

.swiper-button-prev.class-event-slider__prev,
.swiper-button-next.class-event-slider__next {
  &:before {
    border: solid $common-gray-color;
    border-width: 4px 4px 0 0;
    opacity: 1 !important;
    transition: all $duration !important;
  }
  &:hover:not(.swiper-button-disabled) {
    &:before {
      border-color: $common-dark-gray-color;
      @extend %faster;
    }
  }
  &.swiper-button-disabled {
    &:before {
      opacity: .01 !important;
    }
  }
  @include mobile {
    display: none;
  }
}
