.go-back–button {
  @extend %flex-container-rows-centred;

  &:hover {
    cursor: pointer;
  }

  @include mobile {
    margin-top: 23px;
  }
}

.go-back-button__text {
  @extend %typo-add-new-item;
  color: $accent-secondary-color;
  margin-left: 15px;
}

.go-back–button_custom {
  display: inline-block;

  &:hover {
    .go-back–button__icon {
      background: $accent-secondary-color-hover;
      @extend %faster;
    }
    .go-back-button__text {
      color: $accent-secondary-color-hover;
      @extend %faster;
    }
  }

  .icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -5px;
  }

  .go-back–button__icon {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $accent-secondary-color;
    display: inline-block;
    vertical-align: middle;
    transition: background $duration;
  }

  .go-back-button__text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    transition: color $duration;
  }

  svg {
    fill: #fff;
  }
}
