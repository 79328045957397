.accordion {
  display: block;

  &__toggle {
    padding: 30px 25px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
  }

  &__inner {
    padding: 30px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
    background: $calendar-background-gray-color;
  }

  &__section {
    display: block;
  }
}
