.notes-incidents {
  @extend %background-pattern;
}

.notes-incidents__content {
  @extend %dashboard__content;
  margin: 36px auto;
  position: relative;
  max-width: 692px;

  @include mobile {
    min-width: 294px;
    margin: 20px 10px;
  }
}

.notes-incidents__header {
  @extend %table__header;
}

.notes-incidents__form {
  margin: 30px 50px 0;
  @include tablet {
    margin: 30px 30px 0;
  }
  @include mobile {
    margin: 20px 10px 0;
  }
}

textarea[name='notes'] {
  height: 140px;
  min-height: 140px;
}

.notes-incidents__submit-button {
  @extend .signin__submit-button;
  width: 150px;
  margin-top: 24px;
  margin-bottom: 48px;

  @include mobile {
    margin-top: 20px;
    margin-bottom: 44px;
  }
}

.notes-incidents__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.notes-incidents__item {
  border-top: 1px solid $common-medium-gray-color_accent;
  width: 100%;
  height: auto;
  padding: 30px;

  @include mobile {
    padding: 30px 10px;
  }
}

.notes-incidents__item-header {
  @extend %flex-container-rows-centred-start;
  position: relative;
}

.notes-incidents__item-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 9px;
}

.notes-incidents__item-name {
  @extend %typo-notes-item-name;
  color: $accent-primary-color;
  margin-bottom: 6px;
}

.notes-incidents__item-date {
  @extend %typo-content-card-item-content;
  color: inherit;
}

.notes-incidents__item-position {
  @extend %typo-notes-items-position;
  color: $common-dark-gray-color;
}

.notes-incidents__item-time {
  @extend %typo-notes-items-time;
  color: $common-dark-gray-color;

  &:before {
    content: '';
    margin: 0 8px;
    border-left: 1px solid $common-dark-gray-color;
  }
}

.notes-incidents__item-text {
  @extend %typo-content-card-item-content;
  @extend %comments-word-breaker;
  margin-top: 17px;
  color: inherit;
}

.badge {
  @extend %typo-badge;
  color: $common-white-color;
  padding: 7px 14px;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
}

.badge_incident {
  background-color: $warning-requires-color;
}

.badge_health {
  background-color: $accent-health-color;
}

.badge_behavioral {
  background-color: $accent-secondary-color;
}

.badge_employee {
  background-color: $accent-employee-color;
}

.notes__loader {
  position: relative;
  bottom: -70px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}
