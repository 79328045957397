.card-detection__item {
  display: inline-block;
  height: 28px;
  width: 45px;
  margin-left: 5px;
  filter: grayscale(1);
  transition: filter $medium-transition-time;

  &:first-of-type {
    margin-left: 0;
  }
  &_package {
    height: 45px;
    width: 45px;
    margin-top: -6px;
  }
}

.card-detection__item_detected {
  filter: grayscale(0);
  transition: filter $medium-transition-time;
}
