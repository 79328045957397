.event-dog-search * > .Select-option:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

.option-event, .option-event__dog-info, .option-form-recipient, .option-form-recipient__info {
  @extend %flex-container-rows-centred-between;
  overflow: hidden;
}

.option-form-recipient__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-event__dog-info_right {
  text-align: right;
}

.option-form-recipient__info_right {
  width: 140px;
  padding: 0 10px;
  text-align: center;
}

.option-event__avatar, .option-form-recipient__avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  margin-right: 12px;
}

.option-event__secondary, .option-form-recipient__secondary {
  @extend %typo-option-secondary;
  color: $common-gray-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-event__warning {
  color: #f00;
}

.option-event__unavailable {
  color: $common-gray-color !important;
}

.option-event__unavailable-text {
  @extend %typo-option-unavailable-text;
}

.option-event__unavailable-access-level {
  @extend %typo-option-unavailable-access-level;
}

.option-card {
  display: flex;
  align-items: center;
}
.option-card__label {
  font-size: 18px;
  margin-left: 10px;
}
