.text-accordion {
  position: relative;

  &__toggle {
    @extend .link;
    font-size: 14px;
    position: relative;
    display: inline-block;
    user-select: none;
  }
  &__body {
    overflow: hidden;
    transition: height $short-transition-time;
    transition-delay: height .2s;
    position: relative;
    margin-bottom: 2px;
  }
  &__preview {
    top: 0;
    position: absolute;
    transition: opacity $small-transition-time;
    transition-delay: opacity .2s;
  }
  &__hidden {
    opacity: 0;
    visibility: hidden;
  }
  &__content {
    top: 0;
    position: absolute;
    opacity: 0;
    transition: opacity $short-transition-time;
  }

  &_opened &__preview {
    opacity: 0;
    transition-duration: $small-transition-time;
    transition-delay: 0s;
  }
  &_opened &__content {
    opacity: 1;
    transition-duration: $small-transition-time;
    transition-delay: 0s;
  }
}
