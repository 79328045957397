.date-interval-control__no-end-date-checkbox-label {
  @extend %flex-container;
  @extend %flex-children-centred-start;
  margin-top: 10px;
  cursor: pointer;
}

.date-interval-control__multibox-checkbox {
  $checkbox-size: 15.2px;
  min-width: $checkbox-size;
  width: $checkbox-size;
  height: $checkbox-size;
  background-color: $common-white-color;
  border: solid 1px $common-medium-gray-color_accent;
  transition: background-color $short-transition-time, border-color $short-transition-time;
  position: relative;
  margin: 0 12px 0 0;
  svg {
    fill: $common-white-color;
    margin: 0 auto;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    display: block;
  }
}

.date-interval-control__multibox-checkbox_checked {
  background-color: $accent-primary-color;
  border-color: $accent-primary-color;
}

.date-interval-control__no-end-date-checkbox {
  display: none;
}

.date-interval-control__checkbox-name {
  font-family: 'Proxima Nova Regular';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .4px;
  color: $common-dark-gray-color;
}
