.button {
  @extend %typo-button-submit;
  box-sizing: border-box;
  display: block;
  margin: 1.81rem auto 0;
  width: 31.58%;
  text-align: center;
  line-height: 2.7;
  //line-height: 3.2;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0 4px $accent-primary-color inset;
  color: $common-white-color;
  background-color: $accent-primary-color;
  transition: background-color $short-transition-time,
    color $short-transition-time, box-shadow $short-transition-time;

  &__full_width {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: $common-white-color;
    color: $accent-primary-color;
    transition: background-color $short-transition-time,
      color $short-transition-time;
  }

  @include tablet {
    width: 66.96%;
    margin-top: 2.39rem;
  }

  @include mobile {
    width: 100%;
    margin-top: $common-margin-top-big;
  }
}


.button_disabled {
  background-color: $common-gray-color !important;
  border-color: $common-gray-color !important;
  box-shadow: none !important;
  transition: background-color $short-transition-time,
    box-shadow $short-transition-time;

  &:hover {
    cursor: not-allowed;
    background-color: $common-gray-color;
    color: $common-white-color !important;
  }
}

.button_dark {
  background-color: $common-dark-gray-color;
  box-shadow: none;
  &:hover {
    background-color: $accent-primary-color;
    color: $common-white-color;
  }
}

.button_locked {
  background-color: $common-medium-gray-color_accent;
  box-shadow: none;
  position: relative;
  height: 44px;
  cursor: default !important;

  &:hover {
    background-color: $common-medium-gray-color_accent;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.button_blocked {
  background-color: $common-medium-gray-color_accent;
  color: $common-gray-color;
  box-shadow: none;
  position: relative;
  cursor: default !important;

  &:hover {
    background-color: $common-medium-gray-color_accent;
    color: $common-gray-color;
  }
}

.button_clear {
  @extend %typo-button-submit;
  background-color: $accent-secondary-color;
  box-sizing: border-box;
  display: block;
  margin: 1.81rem auto 0;
  width: 31.58%;
  text-align: center;
  line-height: 2.7;
  //line-height: 3.2;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0 4px $accent-primary-color inset;
  color: $common-white-color;
  box-shadow: none;
  position: relative;
  cursor: default !important;

  &:hover {
    background-color: $common-medium-gray-color_accent;
    color: $common-gray-color;
  }
}

.button_save {
  @extend %typo-button-submit;
  background-color: $accent-primary-color;
  box-sizing: border-box;
  display: block;
  margin: 1.81rem auto 0;
  width: 31.58%;
  text-align: center;
  line-height: 2.7;
  //line-height: 3.2;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0 4px $accent-primary-color inset;
  color: $common-white-color;
  box-shadow: none;
  position: relative;
  cursor: default !important;

  &:hover {
    background-color: $common-medium-gray-color_accent;
    color: $common-gray-color;
  }
}
