$introduction-width: 69.44%;
$introduction-width_tablet: 86.98%;
$introduction-logo-width: 29.4%;

.introduction {
  @extend .signin;
}

.introduction__img {
  @extend .signin__img;
  background-image: url('/assets/imgs/slide-1.jpg');
  @include not-desktop {
    display: none;
  }
}

.introduction__content {
  @extend .signin__content;
}

.introduction__container {
  @include onboarding-container($introduction-width, $introduction-width_tablet, $signin-width_mobile);
  min-height: calc(100vh - 28.091rem);
}
 
.introduction__container_centred {
  @extend %container_centred;
}

// todo: should it be done like container above?
.introduction__logo {
  @extend .signin__logo;
  width: $introduction-logo-width;

  @include mobile {
    width: $signin-logo-width_mobile;
  }
}

.introduction__step-name {
  margin-top: $common-margin-top-large;

  @include tablet {
    margin-top: $common-margin-top-great_mobile;
  }

  @include mobile {
    margin-top: 3rem;
  }
}

.introduction__step-description {
  width: 60%;
  margin-top: 2.03rem;

  @include tablet {
    width: 42%;
    margin: 2.03rem auto 0;

    .step-description__image {
      padding-bottom: 102.315%;
    }
  }

  @include mobile {
    width: 100%;
    margin-top: $common-margin-top-large_mobile;
  }
}

.introduction__form {
  @extend .signin__form;
  @extend %flex-container-columns-around;
  flex: 1;
  margin-top: 3.41rem;

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: $common-margin-top-large_mobile;
  }
}

.introduction__submit-button {
  @extend .signin__submit-button;
  width: 71.65%;

  @include mobile {
    width: 100%;
    margin-top: $common-margin-top-large_mobile;
  }
}

.introduction__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

// todo: how should it be modified?
.introduction__skip-step {
  @extend %skip-step;
  height: auto;
  border-top: none;

  .skip-step__skip-link {
    padding: 1.45rem 0 3.41rem;

    @include mobile {
      padding: $common-margin-top-big 0 2.97rem;
    }
  }
}
