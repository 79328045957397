.marketing-editor-wrapper {
  @extend .dashboard-page;
  min-height: calc(100vh - #{$page-header-min-height});
  min-width: 1024px;
  width: 100%;
  text-align: center;
}

.marketing-editor-page {
  display: inline-block;
  width: 100%;
  max-width: 883px;
  margin: 0 auto;
}

.marketing-editor-page__header {
  @extend %table__header;
}

.marketing-editor-page__content {
  @extend .event-detail__content;
  width: 100%;
  padding: 30px 40px;
  margin-bottom: 30px;
}

.marketing-editor-page__form {
  text-align: left;
}

.marketing-editor-page__content-header {
  @extend .event-detail__content;
  border-radius: 0;
  width: 100%;
  height: 95px;
  border-bottom: 1px solid #ddd;
}

.marketing-editor-page__controls {
  @extend %flex-container-rows-centred-end;
  height: 72px;
}

.marketing-editor-page__cancel-button {
  margin-right: 12px;
}

.marketing-editor-page__control-button {
  @extend .event-detail__control-button;
  margin: 0 0 0 12px;
}
