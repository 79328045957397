.customers-classes {
  @extend %background-pattern;
  min-height: calc(100vh - 60px - 193px - 50px);
  padding: 2.97rem 2.9rem;

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.customer-agreement-page__container {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
}
.customer-agreement-page__background {
  background: white;
  border: 1px solid $common-gray-color_transparent;
}


.classes-table__table {
  overflow: auto;
  max-height: 608px;
  height: calc(100% - 40px);

  @include mobile {
    display: none;
  }
}

.classes-table__grid {
  @extend %flex-container-columns-centred;
  background-color: $common-white-color;

  &:not(:empty) {
    padding-top: 20px;
  }

  @include not-mobile {
    display: none;
  }

  .attending-dogs-card-container {
    margin-left: initial;
    margin-right: initial;
  }

  .dropdown_attending-dogs-card {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.classes-table__grid.-collapsed {
  max-height: 0;
  transition: max-height $medium-transition-time ease-in-out;

  .attending-dogs-card-container {
    display: none;
  }
}

.classes-table {
  margin-top: 2.32rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.classes-table__content {
  position: relative;
}

.classes-table__content.-collapsed {
  max-width: 0;
  max-height: 0;
  overflow: hidden;
}

.classes-table_upcoming {
  .rt-table {
    overflow: initial;
  }
  .rt-td {
      &:last-child {
        overflow: initial;
      }
    }
  .dropdown_table {
      position: relative;
    }
  .rt-tr-group {
    &:last-of-type {
      .dropdown__menu {
        margin-top: -33px !important;
      }
    }
  }
}

.classes-table_previous {
  .classes-table__header {
    background-color: $common-gray-color;
  }
  .icon_chevron-table {
    fill: $common-gray-color;
  }

  @extend .classes-table_upcoming;
}

.classes-table__header {
  @extend %table__header;

  &:hover {
    cursor: pointer;
  }
}

.classes-table__header-content {
  @extend %table__header-content;
}

.classes-table__th {
  @extend %table__th;
}

.classes-table__td {
  @extend %table__td;
}

.td__pickup-dropoff {
  text-align: center;
}

.pickup__location,
.dropoff__location {
  @extend %typo-location;
}

.pickup__time,
.dropoff__location,
.dropoff__time {
  margin-top: .3rem;
}

.classes-table__empty-content {
  @extend %table__empty-content;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.classes-table__loading-content {
  @extend %table__empty-content;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.classes-table__no-data {
  height: 200px;
}