.avatar {
  @extend %flex-container-rows-centred;
  flex-direction: column;
}

.avatar__container {
  position: relative;
  @extend %flex-container-rows-centred;
  box-sizing: content-box;
  width: 125px;
  height: 125px;
  margin: 4px;
  background-color: $common-dim-gray-color;
  border-radius: 50%;
  border: 4px solid $common-white-color;
  box-shadow: 0 0 0 4px $common-dark-gray-color;
  cursor: pointer;
}

.avatar__img {
  @extend %typo-avatar-text;
  width: 125px;
  height: 125px;
  line-height: 125px;
  border-radius: 50%;
  text-align: center;
  color: $common-white-color;
}

.avatar__overlay {
  position: absolute;
  top: 0;
  left: 0;
  @extend %flex-container-columns-centred;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: $common-dim-gray-color;
  opacity: 0;
  transition: opacity $short-transition-time ease-in-out;

  @include mobile {
    display: none;
    visibility: hidden;
  }
}

.avatar__overlay:hover {
  opacity: .9;
}

.avatar__text {
  margin-top: 10px;
  color: $common-white-color;
  @extend %typo-avatar-text;
}

.avatar__icon {
  fill: $common-white-color;

  &_dog {
    width: 68px;
    height: 67px;
    fill: #fff;
    margin-right: 10px;
  }

  &_small {
    width: 36px;
    height: 36px;
  }

  &_24 {
    width: 24px;
    height: 24px;
    @include mobile {
      width: 36px;
      height: 36px;
    }
  }

  &_60 {
    width: 60px;
    height: 60px;
  }

  &_30 {
    width: 30px;
    height: 30px;

    @include mobile {
      width: 60px;
      height: 60px;
    }
  }

  &_18 {
    width: 18px;
    height: 18px;
  }
}

.avatar__dropzone {
  border: none;
}

.avatar__upload-progress {
  font-size: 2rem;
}

.avatar__text-placeholder {
  margin-top: 0;
}

.avatar__upload-btn {
  @extend %typo-avatar-upload-btn;
  color: $common-dark-gray-color;
  max-width: 70%;
  display: inline-block;
  text-align: center;
  margin: 15px auto 0;
  cursor: pointer;
  text-decoration: underline;
  line-height: 1.4em;
  @include mobile {
    font-size: 16px;
    max-width: 100%;
  }
}

.avatar__default-placeholder-container {
  margin: 0 auto 22px;
  width: 70px;
}

.avatar__default-placeholder {
  @extend %flex-container-rows-centred;
  width: 70px;
  height: 70px;
  flex-direction: column;
  border-radius: 50%;
  border: 2px solid $common-dim-gray-color;
  &_dog {
    border: none;
    background-color: $common-gray-color;
  }
}

.avatar__default-placeholder_40 {
  width: 40px;
  height: 40px;
  @include mobile {
    width: 56px;
    height: 56px;
  }
}

.avatar__default-placeholder_62 {
  width: 62px;
  height: 62px;
}

.avatar__default-placeholder_36 {
  width: 36px;
  height: 36px;
}

.avatar__default-placeholder_card {
  width: 260px;
  height: 160px;

  @include tablet {
    width: 300px;
  }

  @include mobile {
    width: 260px;
  }
}

.avatar__default-placeholder_customer-card {
  width: 295px;
  height: 195px;
  background-color: #ccc;
}

.avatar__default-placeholder_customer-schedule-card {
  width: 130px;
  height: 87px;
  background-color: #ccc;

  @include mobile {
    width: 100%;
    height: 190px;
  }
}

.avatar__default-placeholder_report-card {
  width: 100%;
  height: 300px;
  border-radius: 0;
  background-color: rgba(#474d57, 1);

  @include mobile {
    width: 100%;
    height: 147px;
  }
}

.avatar__default-placeholder-inner {
  @extend %flex-container-rows-centred;
  background-color: $common-dim-gray-color;
  position: relative;
  box-sizing: content-box !important;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 2px solid $common-white-color;
}

.avatar__default-placeholder-inner_28 {
  width: 28px;
  height: 28px;
}
