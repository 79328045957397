.classes-schedule-card-container {
  @extend .employee-card-container;
}

.classes-schedule-card-container_empty {
  @extend .employee-card-container_empty;
}

.classes-schedule-card {
  @extend .employee-card;
}

.classes-schedule-card__header {
  border-radius: 3px 3px 0 0;
  text-align: center;
  height: 190px;
  position: relative;

  .avatar__default-placeholder {
    margin: 0 auto;
  }
}

.classes-schedule-card__header-title {
  @extend %typo-employee-card-header-title;
  color: $common-white-color;
  position: absolute;
  bottom: 15px;
  right: 0;
  max-width: 100%;
  padding: 11px 15px 9px;
  background-color: rgba($common-dark-gray-color, .85);

  div {
    text-align: left;
    line-height: 1.2;
  }
}

.classes-schedule-card__header-text {
  @extend %typo-employee-card-header-text;
  line-height: 23px;
}

.classes-schedule-card__header-text_email {
  min-height: 47px;
}

.classes-schedule-card__content {
  border-radius: 0 0 3px 3px;
  background-color: $common-white-color;
  padding: 20px;
}

.classes-schedule-card__item {
  @extend .content-card__item;
  &:first-of-type {
    margin-top: 0;
  }
}

.classes-schedule-card__item-header {
  @extend .content-card__item-header;
}

.classes-schedule-card__item-content {
  @extend .content-card__item-content;
}

.classes-schedule-card__name-info {
  @extend .classes-schedule-card__content;
  border-bottom: 1px solid $common-medium-gray-color_accent;
  border-radius: 0;
  position: relative;
  .classes-schedule-card__class-name {
    @extend .classes-schedule-card__item-header;
    font-size: 1.55rem;
    @extend %truncate-this;
  }
  .classes-schedule-card__weekdays {
    @extend .classes-schedule-card__item-content;
  }
  .classes-schedule-card__class-name,
  .classes-schedule-card__weekdays {
    max-width: 90%;
  }
  .classes-schedule-card_featured-class-icon {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
