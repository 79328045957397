.mobile-sidebar {
  display: none;
  height: 100vh;
  background: #373e48;
  width: calc(100% - 52px);
  color: #fff;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 100%;

  @include mobile {
    display: block;
  }

  &__inner {
    display: block;
  }

  &__profile {
    background: #404956;
    padding: 8px 18px;
    border-bottom: solid 1px rgba(255, 255, 255, .4);
    display: flex;
    align-items: center;

    &__image {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin-right: 15px;
    }

    &__name {
      font-size: 16px;
      font-family: $bold;
      color: #fff;
    }
  }

  &__nav {
    padding: 18px 0;
    border-bottom: solid 1px rgba(255, 255, 255, .4);
  }

  &__link {
    position: relative;
    padding: 12px 18px;
    display: block;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    transition: background $duration;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background: #57a2c4;
      width: 4px;
      opacity: 0;
      transition: opacity $duration;
    }

    &_active {
      background: #424956;

      &:before {
        opacity: 1;
      }
    }
  }

  &__footer {
    padding: 18px;
  }

  &__logout {
    color: rgba(255, 255, 255, .6);
    font-size: 16px;
    text-decoration: underline;
  }
}
