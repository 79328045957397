.messages {
  @extend .modal-one;

  @include desktop {
    @extend %flex-container-columns;
    align-items: initial;
    position: fixed;
    left: initial;
    right: $common-margin-top-large_mobile;
    height: initial;
    width: 400px;
    pointer-events: none;
    z-index: 0;
    opacity: 0;
    overflow: initial;
    will-change: top, opacity, transform;
    transition: top $duration $easeInOutSine, transform $duration $easeInOutSine, opacity $short-transition-time;
  }

  @include tablet {
    > span {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  @include not-desktop {
    height: 100vh;
  }

  @include mobile {
    > span {
      height: auto;
      justify-content: center;
      min-width: 300px;
      max-width: 300px;
    }
  }
}

.messages_active {
  z-index: 3;
  pointer-events: all;
  visibility: visible;
  opacity: 1;

  @include not-desktop {
    z-index: 1000;
    background-color: rgba(0, 0, 0, .4);
  }
}

.messages_open {
  transform: translateY(0);
}

.messages_minimized {
  height: 50px;
}

.messages__content {
  width: 400px;
  max-height: 90%;
  background-color: $common-white-color;
  border-radius: $common-border-radius;
  box-shadow: $messages-shadow;

  @include tablet {
    width: 500px;
    max-width: 500px;
  }

  @include mobile {
    width: auto;
    max-height: 75%;
    min-width: 100%;
  }
}

.messages__header {
  @extend %flex-container-rows-centred-between;
  height: 50px;
  padding: 15px 20px;
  background-color: $common-dark-gray-color;
}

.messages__header-title {
  @extend %typo-messages-header;
}

.messages__header-controls {
  @extend %flex-container-rows;
}

.messages__minimize,
.messages__remove,
.messages__maximize {
  &:hover {
    cursor: pointer;
  }
}

.messages__minimize,
.messages__add-image {
  @extend %flex-container-rows-centred;
}

.messages__minimize,
.messages__maximize {
  @include not-desktop {
    display: none;
  }
}

.messages__add-image {
  width: 26px;
  height: 21px;

  &:hover {
    .icon_message-add-image {
      fill: $accent-secondary-color;
    }
  }

  @include mobile {
    position: absolute;
    //bottom: -147px;
    //left: -160px;
    bottom: 13px;
    right: 150px;
    width: auto;
  }
}

.messages__add-image_disabled {
  .icon_message-add-image {
    fill: $common-medium-gray-color_accent;
  }

  &:hover {
    cursor: not-allowed;

    .icon_message-add-image {
      fill: $common-medium-gray-color_accent;
    }
  }
}

.messages__remove {
  margin-left: 21px;
}

.messages__body {
  padding: 0 21px 21px;
  height: 261px;
  opacity: 1;
  overflow: auto;
  will-change: height;
  transition: height $duration $easeInOutSine, opacity $short-transition-time;

  @include not-desktop {
    height: 400px;
  }

  @include mobile {
    height: 215px;
  }
}

.messages__body_loaded {
  padding: 21px;
}

.messages__body_centered {
  @extend %flex-container-columns-centred;
}

.messages__item-image {
  // default align-self is 'stretch' so the imgs full width of the flex container
  align-self: flex-start;
  margin-top: 5px;
  min-height: 150px;
  background-color: $common-gray-color;
}

.messages__empty-text {
  @extend %typo-messages-empty-text;
  margin: 0 3.5rem;
}

.messages__form {
  @extend %flex-container-rows-centred-between;
  width: 100%;

  .image-control {
    width: auto;
  }

  input:first-of-type {
    flex: 1 0 92%;

    @include mobile {
      flex: 1 0 100%;
    }
  }

  .input-control__textarea {
    margin-top: .35rem;
    width: 100%;
    border: none;

    .input-control__input {
      padding: .35rem 0 0 !important;
      margin-top: 0;
      min-height: 50px;
    }

    .input-control__placeholder_touched {
      opacity: 0;
    }
  }
}

.messages__footer {
  @extend %flex-container-rows-centred-between;
  position: relative;
  height: 51px;
  border-top: 1px solid $common-medium-gray-color;
  padding: 0 20px;
  opacity: 1;
  will-change: height;
  transition: height $duration $easeInOutSine, opacity $short-transition-time;

  input {
    @extend .input-control__input;
    border: 0;
    padding: 0;

    &:focus,
    &:active {
      padding: 0;
    }
  }

  @include mobile {
    position: relative;
    height: 192px;
    flex-flow: column nowrap;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 46px;
      height: 1px;
      background-color: $common-medium-gray-color;
    }
  }
}

.messages__body_minimized,
.messages__footer_minimized {
  height: 0;
  padding: 0;
  opacity: 0;
  border: 0;
  will-change: height;
  transition: height $duration $easeInOutSine, opacity $short-transition-time;
}

.messages__send {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin-left: 15px;
  //animation: float 6s ease-in-out infinite;

  &:hover {
    .icon_message-send {
      fill: $accent-secondary-color;
      transition: fill $short-transition-time;
    }
  }

  @include mobile {
    margin-left: 55px;
    margin-bottom: 8px;
  }
}

.messages__send_disabled {
  .icon_message-send {
    fill: $common-medium-gray-color_accent;
  }

  &:hover {
    cursor: not-allowed;

    .icon_message-send {
      fill: $common-medium-gray-color_accent;
    }
  }
}

.messages__item {
  @extend %flex-container-rows;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0;
  }
}

.messages__item-content {
  margin-left: 11.5px;
  max-width: 89%;
}

.messages__item-photo {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: $common-gray-color;
}

.messages__item-author {
  @extend %typo-message-header;
}

.messages__item-body {
  @extend %flex-container-columns;
  @extend %typo-message-body;
  @extend %comments-word-breaker;
  margin-top: 5px;
}

.messages__item-status {
  @extend %typo-message-status;
  margin-top: 5px;
}

.messages__item-status_success {
  color: $common-gray-color;
}

.messages__item-status_fail {
  color: $warning-color_not-desktop;
}

.message-enter {
  transform: translateY(-120%);
  opacity: 0;
  max-height: 0;
  transition: transform $medium-transition-time, opacity $medium-transition-time, max-height $medium-transition-time;

  &.message-enter-active {
    transform: translateY(0);
    opacity: 1;
    max-height: 300px;
  }
}

.message-leave {
  opacity: 1;
  transition: opacity $small-transition-time, max-height $small-transition-time;

  &.message-leave-active {
    opacity: 0;
    max-height: 0;
  }
}

.messages__images {
  @include not-desktop {
    //position: relative;
  }
}

.messages__image-preview-container {
  @extend %flex-container-rows-wrapped-start;
  position: absolute;
  bottom: 47px;
  left: 0;
  right: 0;
  max-height: 125px;
  overflow: auto;
  padding: 0 20px;
  background-color: $common-white-color;
  border-top: 1px solid $common-medium-gray-color;

  @include tablet {
    max-height: initial;
    overflow: initial;
  }

  @include mobile {
    border-top: none;
    max-height: 91px;
    min-height: 91px;
    overflow: auto;
  }
}

.messages__image-preview {
  position: relative;
  min-width: 70px;
  max-width: 70px;
  margin: 10px 10px 0 0;
  //max-height: 50px;

  img {
    width: 100%;
  }
}

.messages__image-preview-close {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: $common-dark-gray-color;
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $common-white-color;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color $short-transition-time;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $common-white-color;
    transform: rotate(45deg);
  }

  &:before {
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 10px;
  }

  &:after {
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -5px;
    width: 10px;
    height: 2px;
  }
}

.messages__load-more {
  padding: 20px 0;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(0);
  will-change: transform, opacity;
  transition: opacity $short-transition-time, transform $short-transition-time;

  + .messages__item {
    margin-top: 0;
  }

  &:hover {
    cursor: pointer;

    .messages__load-more-text {
      border-color: $common-dark-gray-color;
      transition: border-color $short-transition-time;
    }
  }
}

.messages__load-more_hidden {
  opacity: 0;
  transform: translateY(-100%);
}

.messages__load-more_loading {
  &:hover {
    cursor: not-allowed !important;

    .messages__load-more-text {
      border-bottom: none !important;
    }
  }
}

.messages__load-more-text {
  @extend %typo-message-load-more;
  display: inline;
  border-bottom: 1px solid transparent;
  transition: border-color $short-transition-time;
}

.messages__load-more-text_loading {
  @extend %flex-container-rows-centred;
  display: inline-flex !important;
}

.messages-enter {
  transform: translateY(-100%);

  &.messages-enter-active {
    transform: translateY(0);
  }
}

.messages-leave {
  transform: translateY(0);

  &.order-leave-active {
    transform: translateX(120%);
  }
}

.messages-enter {
  transform: translateX(20%);

  @include desktop {
    transform: translateX(0);
  }
}

.messages-enter.messages-enter-active {
  transform: translateX(0%);
  transition: transform $short-transition-time;
}

.messages-leave {
  transform: translateX(0%);
}

.messages-leave.messages-leave-active {
  transform: translateX(20%);
  transition: transform $short-transition-time;

  @include desktop {
    transform: translateX(0%);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .6);
    transform: translateY(0);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, .2);
    transform: translateY(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .6);
    transform: translateY(0);
  }
}
