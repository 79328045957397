$new-object-item-width: 23.25%;
$new-object-item-width_mobile: 49.4%;
$new-object-item-padding-top_mobile: 53.4%;
$new-object-loader-padding-top_mobile: 107.25%;

.overview {
  @extend %flex-container-columns-centred-start;
  //@extend .dashboard-page;
  //min-height: calc(100vh - #{$page-header-min-height});
  padding-bottom: 40px;

  @include tablet {
    padding-bottom: 27px;
  }

  @include mobile {
    padding-bottom: 12px;
  }
}

.overview_loading {
  min-height: calc(200vh - #{$page-header-min-height});
}

.overview__filter {
  width: 340px;
  margin: 16px auto;

  @include mobile {
    // - 12px - 12px paddings
    .date-interval-control {
      flex-flow: row nowrap;

      .date-interval-control__start-date {
        .DayPickerInput-OverlayWrapper {
          float: left;
        }
      }

      .date-interval-control__start-date,
      .date-interval-control__end-date {
        width: 49%;

        .date-control {
          margin-top: 0;
        }
      }
    }
  }

  @include small-mobile {
    width: 296px;
  }
}

.overview__header {
  @extend %table__header;
  width: 100%;
}
