.checkbox-control {
  margin-top: 1.38rem;
}

.checkbox-control__label {
  @extend %flex-container-rows-start;

  &:hover {
    cursor: pointer;
  }

  @include tablet {
    justify-content: center;
  }

  @include mobile {
    order: 2;
    margin-top: 1rem;
  }
}

.checkbox-control__label-no-centering {
  @extend %flex-container-rows-start;

  &:hover {
    cursor: pointer;
  }
  margin-bottom: 1rem;

  @include mobile {
    order: 2;
    margin-top: 1rem;
  }
}

.checkbox-control__input {
  @include hide-input;
  user-select: none;
  &:checked {
    + .checkbox-control__text {
      &:before {
        background-color: $accent-primary-color;
        box-shadow: 0 0 0 1px $accent-primary-color;
        transition: background-color $short-transition-time;
      }

      &:after {
        content: '\a0';
        display: inline-block;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdib3g9IjAgMCAxMSAxMCI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjRkZGIg0KICAgICAgICAgIGQ9Ik0zLjY3NCA3LjQ1NUwxLjExOSA0Ljg2Mi0uMDAyIDYuMTM0bDMuNzY5IDMuODY0TDExIDEuMTQ2IDkuNzg3LS4wMDEgMy42NzQgNy40NTV6Ii8+DQo8L3N2Zz4=");
        position: absolute;
        left: 2px;
        width: 11px;
        height: 10px;
        top: 50%;
        margin-top: -5px;
      }

      @include mobile {
        &:after {
          top: 50%;
          margin-top: -8px;
          left: 3.5px;
          width: 18px;
          height: 16px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNiI+DQogICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNi4wMTIgMTEuOTI4TDEuODMxIDcuNzc5LS4wMDMgOS44MTRsNi4xNjcgNi4xODNMMTguMDAxIDEuODM0IDE2LjAxNS0uMDAxIDYuMDEyIDExLjkyOHoiLz4NCjwvc3ZnPg0K");
        }
      }
    }
  }

  + .checkbox-control__text {
    padding-left: 30px;

    @extend %flex-container-rows-centred;
    position: relative;
    color: $common-black-color;
    font-size: 1.14rem;
    font-weight: 500;
    letter-spacing: .4px;

    &:before {
      content: '\a0';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -8px;
      margin-right: 1rem;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 1px $common-medium-gray-color;
      transition: background-color $short-transition-time;
    }

    @include mobile {
      padding-left: 40px;
      &:before {
        width: 26px;
        height: 26px;
        margin-top: -13px;
      }
    }
  }
}
