.file-control-upload-button {
  border: none;
}

.file-control-upload-button__button-container {
  position: relative;
}

.file-control-upload-button__upload-indicator-container {
  visibility: visible !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.file-control-upload-button__upload-indicator {
  position: relative;
  left: -50%;
}
