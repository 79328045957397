%flex-container {
  display: flex;
}

%flex-children-columns {
  flex-flow: column nowrap;
}

%flex-children-rows {
  flex-flow: row nowrap;
}

%flex-children-rows-wrapper {
  flex-flow: row wrap;
}

%flex-children-centred {
  align-items: center;
  justify-content: center;
}

%flex-children-between {
  justify-content: space-between;
}

%flex-children-around {
  justify-content: space-around;
}

%flex-children-start {
  align-items: flex-start;
}

%flex-children-start-centered {
  align-items: flex-start;
  justify-content: center;
}

%flex-children-centred-between {
  align-items: center;
  justify-content: space-between;
}

%flex-children-centred-start {
  align-items: center;
  justify-content: flex-start;
}

%flex-children-centred-end {
  align-items: center;
  justify-content: flex-end;
}

%flex-children-start-centred {
  align-items: flex-start;
  justify-content: center;
}

%flex-children-start-between {
  align-items: flex-start;
  justify-content: space-between;
}

%flex-container-columns {
  @extend %flex-container;
  @extend %flex-children-columns;
}

%flex-container-columns-centred {
  @extend %flex-container;
  @extend %flex-children-columns;
  @extend %flex-children-centred;
}

%flex-container-columns-around {
  @extend %flex-container;
  @extend %flex-children-columns;
  @extend %flex-children-around;
}

%flex-container-columns-between {
  @extend %flex-container;
  @extend %flex-children-columns;
  @extend %flex-children-between;
}

%flex-container-rows {
  @extend %flex-container;
  @extend %flex-children-rows;
}

%flex-container-rows-centred {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-centred;
}

%flex-container-rows-between {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-between;
}

%flex-container-rows-around {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-around;
}

%flex-container-rows-centred-between {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-centred-between;
}

%flex-container-rows-start-between {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-start-between;
}

%flex-container-rows-wrapper-start-between {
  @extend %flex-container;
  @extend %flex-children-rows-wrapper;
  @extend %flex-children-start-between;
}

%flex-container-rows-centred-start {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-centred-start;
}

%flex-container-rows-centred-end {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-centred-end;
}

%flex-container-rows-wrapped-centred {
  @extend %flex-container;
  @extend %flex-children-rows-wrapper;
  @extend %flex-children-start-centred;
}

%flex-container-rows-wrapped-between {
  @extend %flex-container;
  @extend %flex-children-rows-wrapper;
  @extend %flex-children-between;
}

%flex-container-rows-wrapped-start-between {
  @extend %flex-container;
  @extend %flex-children-rows-wrapper;
  @extend %flex-children-between;
  @extend %flex-children-start;
}

%flex-container-rows-start {
  @extend %flex-container;
  @extend %flex-children-rows;
  @extend %flex-children-start;
}

%flex-container-rows-wrapped-start {
  @extend %flex-container;
  @extend %flex-children-rows-wrapper;
  @extend %flex-children-start;
}

%flex-container-columns-start-centred {
  @extend %flex-container-columns;
  @extend %flex-children-start-centred;
}

%flex-container-columns-centred-start {
  @extend %flex-container-columns;
  @extend %flex-children-centred-start;
}
