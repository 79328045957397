.customers-classes {
  @extend %background-pattern;
  min-height: calc(100vh - 60px - 193px - 50px);
  padding: 2.97rem 2.9rem;

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.customer-agreement-page__container {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
}

.customer-agreement-page__background {
  background: white;
  border: 1px solid $common-gray-color_transparent;
}


.trainings-table__table {
  @include mobile {
    display: none;
  }
}

.trainings-table__grid {
  @extend %flex-container-columns-centred;
  background-color: $common-white-color;

  &:not(:empty) {
    padding-top: 20px;
  }

  @include not-mobile {
    display: none;
  }

  .attending-dogs-card-container {
    margin-left: initial;
    margin-right: initial;
  }

  .dropdown_attending-dogs-card {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.trainings-table__grid.-collapsed {
  max-height: 0;
  transition: max-height $medium-transition-time ease-in-out;

  .attending-dogs-card-container {
    display: none;
  }
}

.trainings-table {
  margin-top: 2.32rem;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }
}

.trainings-table_previous {
  .trainings-table__header {
    background-color: $common-gray-color;
  }

  .icon_chevron-table {
    fill: $common-gray-color;
  }
}

.trainings-table__header {
  @extend %table__header;

  &:hover {
    cursor: pointer;
  }
}

.trainings-table__header-content {
  @extend %table__header-content;
}

.trainings-table__th {
  @extend %table__th;
}

.trainings-table__td {
  @extend %table__td;
}

.td__pickup-dropoff {
  text-align: center;
}

.pickup__location,
.dropoff__location {
  @extend %typo-location;
}

.pickup__time,
.dropoff__location,
.dropoff__time {
  margin-top: .3rem;
}

.trainings-table__empty-content {
  @extend %table__empty-content;
}