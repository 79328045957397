.dnd-control {
  @extend %flex-container-columns;
}

.dnd-control__zone {
  @extend %flex-container-rows-centred;
  width: 100%;
  min-height: 8.12rem;
  margin-top: 1.38rem;
  border: 1px dashed $common-medium-gray-color;

  @include tablet {
    min-height: 10.15rem;
    margin-top: 3.34rem;
  }

  @include mobile {
    min-height: 14.49rem;
    margin-top: $common-margin-top-large_mobile;
  }

  .zone-preview {
    @extend %flex-container-columns-centred;

    .icon_xl {
      @include not-mobile {
        width: 2.75rem;
        height: 2.75rem;
      }

      @include mobile {
        width: 4.42rem;
        height: 4.42rem;
      }
    }
  }

  .zone-preview__text {
    @extend %typo-zone-preview-text;
    color: $common-medium-gray-color_accent;
    text-align: center;
    margin-top: 1.16rem;
  }

  .zone-preview__text-top,
  .zone-preview__text-bottom {
    @include not-mobile {
      display: inline;
    }
  }

  .zone-preview__text-bottom {
    @include not-mobile {
      margin-left: .3rem;
    }
  }

  .zone-preview__disclaimer {
    color: $common-medium-gray-color_accent;
    font-size: 1.14rem;
    line-height: 1.5;
    letter-spacing: .4px;
  }

  .zone-files {
    @extend %flex-container-columns;
    align-self: flex-start;
    width: 100%;
    //min-height: 12.1rem;
    max-height: 8.12rem;
    overflow-y: auto;

    @include tablet {
      max-height: 10.15rem;
    }

    @include mobile {
      max-height: 14.49rem;
    }
  }

  .zone-files__file {
    @extend %flex-container-rows-centred-between;
    flex: 1 0 100%;
    max-width: 100%;
    margin: 1.3rem 1.3rem 0;

    &:first-of-type {
      margin-top: 1.52rem;
    }

    &:last-of-type {
      margin-bottom: .36rem;
    }

    @include mobile {
      margin: 1rem 1rem .25rem;
      border-bottom: 1px solid $common-medium-gray-color;
      padding-bottom: .5rem;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .zone-files__file-name {
    @extend %truncate-this;
    color: $common-dark-gray-color;
    font-size: 1.14rem;
    font-family: 'Proxima Nova SemiBold';
    //font-weight: bold;
    line-height: 1.5;
    letter-spacing: .4px;

    @include mobile {
      max-width: 60%;
    }
  }

  .zone-files__file-controls {
    @extend %flex-container-rows-centred;
  }

  .file-controls__change {
    @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
    margin-right: 2.03rem;
    color: $common-dark-gray-color;
    font-size: 1.14rem;
    letter-spacing: .4px;

    &:hover {
      cursor: pointer;
    }

    @include mobile {
      margin-right: 1rem;
    }
  }

  button.file-controls__change {
    background: none;
    border: none;
    padding: 0;
  }

  button.file-controls__delete {
    background: none;
    border: none;
    padding: 0;
  }

  .file-controls__delete-icon {
    width: 22px;
    height: 22px;
    fill: $common-medium-gray-color;
    transition: fill $short-transition-time;

    &:hover {
      cursor: pointer;
      fill: $common-dark-gray-color;
      transition: fill $short-transition-time;
    }
  }

  .zone-send {
    font-size: 1.14rem;
    letter-spacing: .4px;
    line-height: 1.2;
    text-align: center;
  }

  .zone-send__email,
  .zone-send__fax {
    color: $common-dark-gray-color;
  }

  .zone-send__fax {
    margin-top: .2rem;
  }

  .zone-send__fax-number {
    @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
    color: $common-dark-gray-color;
    display: inline-block;
    margin-left: .4rem;
  }

  .zone-send__email-link {
    @include underline(false, 1px, 100%, $accent-secondary-color, .3);
    display: inline-block;
    color: $accent-secondary-color;
    margin-left: .4rem;
  }
}

.dnd-control__zone_disabled {
  pointer-events: auto;
  cursor: default;
}

.dnd-control__zone_empty:not(.dnd-control__zone_disabled) {
  &:hover {
    cursor: pointer;
  }
}

.dnd-control__zone_failed {
  @extend .input-control__input_failed;
}

.dnd-control__error {
  @extend .input-control__error;
}

.dnd-control__controls {
  @extend %flex-container-rows-centred-between;
  // #smaller margins
  //margin-top: 1.59rem;
  margin-top: 1.38rem;

  @include mobile {
    flex-flow: column nowrap;
    margin-top: 2.1rem;

    .checkbox-control__label {
      margin-top: 0;
      width: 75%;
    }
  }
}

.dnd-control__controls-checkbox {
  padding-right: 20px;
  box-sizing: border-box;
}

.dnd-control__controls-upload {
  @include underline(false, 1px, 100%, $accent-secondary-color, .3);
  color: $accent-secondary-color;
  white-space: nowrap;
  background: none;
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  transition: color $short-transition-time;

  &:hover {
    cursor: pointer;
  }

  @include mobile {
    display: none;
    //order: 1;
    //align-self: flex-end;
  }
}

.dnd-control__controls-upload_disabled {
  color: $common-dark-gray-color;

  &:after {
    background-color: $common-dark-gray-color;
  }

  &:hover {
    cursor: not-allowed;
  }

  @include mobile {
    display: none;
  }
}
