// extra large with 100% width
$chart-height-extra-large: 42.31%;
//$chart-height-extra-large_tablet: 57.5%;
$chart-height-extra-large_tablet: 380px;
//$chart-height-extra-large_mobile: 122.65%;
$chart-height-extra-large_mobile: 360px;

.overview__new-objects,
.overview__total-revenue {
  @extend %flex-container-columns-start-centred;
  position: relative;
  width: 100%;
  // fix to $chart-layer-top-margin when all the vars are moved to vars.scss
  margin-top: 20px;

  @include tablet {
    // fix to $chart-layer-top-margin_tablet when all the vars are moved to vars.scss
    margin-top: 15px;
  }
}

.overview__new-objects {
  @include mobile {
    // fix to $chart-layer-top-margin_mobile when all the vars are moved to vars.scss
    margin-top: 5px;
  }
}

.overview__new-objects_loading {
  ~ .overview__charts {
    .class-reservations__loader,
    .report-cards__loader {
      top: 234px;
    }
  }
}

.overview__charts_loading {
  ~ .overview__charts {
    .class-reservations-by-zone__loader,
    .bookings-by-trainer__loader {
      top: 570px;
    }
  }
}

.overview__charts_loading {
  ~ .overview__total-revenue {
    .total-revenue__loader {
      top: 986px;
    }
  }
}

.new-objects__content,
.total-revenue__content,
.new-objects__loader,
.total-revenue__loader {
  width: 100%;
}

.total-revenue__content {
  @include tablet {
    height: $chart-height-extra-large_tablet;
  }

  @include mobile {
    height: $chart-height-extra-large_mobile;
  }
}

.new-objects__content {
  @extend %flex-container-rows-between;

  @include mobile {
    flex-wrap: wrap;
  }
}

.new-objects__loader,
.loader__content,
.new-objects__item-content,
.total-revenue__loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.loader__content_custom-reports {
  z-index: 7;
  background: rgba(#fff, .8);
}
.new-objects__loader {
  padding-top: $new-object-item-width;

  @include mobile {
    padding-top: $new-object-loader-padding-top_mobile;
  }
}

.total-revenue__loader {
  padding-top: $chart-height-extra-large;

  @include not-desktop {
    padding-top: 0;
  }

  @include tablet {
    height: $chart-height-extra-large_tablet;
  }

  @include mobile {
    height: $chart-height-extra-large_mobile;
  }
}

.loader__content,
.new-objects__item-content {
  @extend %flex-container-columns-centred;
}

.new-objects__item {
  flex: 0 1 $new-object-item-width;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: $common-border-radius;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    height: 0;
  }

  @include small-mobile {
    flex: 0 1 $new-object-item-width_mobile;
    &:nth-last-of-type(-n+2) {
      margin-top: 5px;
    }
  }
}

.new-objects__item-text,
.new-objects__item-amount {
  margin-top: 16px;

  @include mobile {
    margin-top: 8px;
  }

  @include small-mobile {
    margin-top: 16px;
  }
}

.new-objects__item-text {
  @extend %flex-container-columns-centred;
  @extend %typo-overview-new-objects-item-text;
  min-height: 28px;
  max-width: 50%;

  @include tablet {
    max-width: 66%;
  }

  @include mobile {
    min-width: 90%;
  }
}

.new-objects__item-amount {
  @extend %typo-overview-new-objects-item-amount;
}

.fade-enter {
  opacity: .01;

  &.fade-enter-active {
    opacity: 1;
    transition: opacity $medium-transition-time $easeInCubic;
  }
}

.fade-leave {
  opacity: 1;

  &.fade-leave-active {
    opacity: .01;
    transition: opacity $small-transition-time $easeInCubic;
  }
}
