.items-list {
  &__list {
    margin-bottom: 15px;
  }
  &__item {
    padding: 15px 0;
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover .items-list__edit-icon {
      opacity: 1;
      @extend %faster;
    }
  }
  &__title {
    font-family: $bold;
    letter-spacing: .4px;
    line-height: 1.2;
  }
  &__text {
    line-height: 1.1;
    letter-spacing: 1px;
  }

  &__add {
    display: inline-block;
  }
}
