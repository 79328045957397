.class-schedule-modal-form {
  @extend .image-control;
}

.class-schedule-modal-form__image-container {
  @extend .image-control__image-container;
  width: 106px;
  height: 77px;
}

.class-schedule-modal-form__image-preview,
.class-schedule-modal-form__image-empty {
  @extend .image-control__image-preview;
}

.class-schedule-modal-form__image-empty {
  @extend .image-control__image-empty;
}

.class-schedule-modal-form__image-preview {
  @extend .image-control__image-preview;
}

.class-schedule-modal-form__profile-image {
  @extend .image-control__profile-image;
}

.class-schedule-modal-form__update-image-button {
  @extend .image-control__update-image-button;
}

.class-schedule-modal-form__image-container,
.class-schedule-modal-form__image-preview,
.class-schedule-modal-form__image-empty,
.class-schedule-modal-form__profile-image {
  border-radius: 5px;
}
