.form-label-field > div.form-label-field__inner:first-child {
  text-align: left;
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  outline: none;
  border: none;
  padding: 0;
  & input,
  & div.editable-content,
  & .input-control {
    will-change: border-color, background-color;
    transition: border-color $short-transition-time, background-color $short-transition-time !important;
  }
}

.form-label-field > div.preview {
  flex-flow: column;
  text-align: left;
  & label,
  & .image-selector__icon-text,
  & .template-block__remove,
  & .template-block__add {
    display: none;
  }
}

.form-label-field label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  text-align: left;
  opacity: .75;
  font-weight: bold;
  color: $common-medium-gray-color_accent;
  will-change: opacity, transform;
  transition:
    opacity $short-transition-time,
    transform $medium-transition-time;
}

.form-label-field > div:first-child > input[type="text"]:focus + label,
.form-label-field > div:first-child > input[type="text"]:not(:placeholder-shown) + label,
.form-label-field > div:first-child > .template-text-area:focus + label,
.form-label-field > div:first-child > .template-text-area:not(:empty) + label {
  color: $common-medium-gray-color_accent;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.form-field-error {
  @extend %typo-input-error;
  color: $warning-color_not-desktop;
  text-align: left;
  padding: 3px 5px 0;
}

.form-field_invalid input,
.form-field_invalid div.editable-content {
  border: 1px solid $warning-color_not-desktop !important;
  background-color: $warning-color_not-desktop-rgba !important;
}

.form-field_invalid label {
  color: white;
}

.link-editor.form-field_invalid .input-control__error {
  position: absolute;
  bottom: -50%;
  padding-left: 15px;
}

.form-field_invalid .link-editor__icon {
  pointer-events: all;
  opacity: 1;
}
