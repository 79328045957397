.link-switcher {
  &__item {
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 20px;
    @extend .link;
    position: relative;

    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }

    &_active {
      text-decoration: none;
      cursor: default;

      @include desktop {
        &:hover {
          color: $accent-secondary-color;
        }
      }
    }

    &:after {
      content: '|';
      position: absolute;
      right: -12px;
      color: $common-dark-gray-color;
      pointer-events: none;
    }
  }
}
