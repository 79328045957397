.mobile-toggle {
  &__checkbox {
    display: none;
  }

  &__checkbox:checked + &__toggle {
    background: $accent-secondary-color;

    &:after {
      transform: translateX(20px);
    }
  }

  &__toggle {
    width: 52px;
    height: 32px;
    border-radius: 32px;
    background: #dededd;
    display: block;
    position: relative;
    transition: background $duration/2 $easeInOutCubic;

    &:after {
      content: '';
      width: 28px;
      height: 28px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 28px;
      background: #fff;
      transition: transform $duration/2 $easeInOutCubic, width $duration/2, left $duration/2;
    }
  }

  &_loading {
    .mobile-toggle__toggle:after {
      width: 33px;
    }
    .mobile-toggle__checkbox:checked + .mobile-toggle__toggle:after {
      left: -3px;
    }
  }
}
