.dog-report-video {
  bottom: 0;
  left: 0;
  height: 100%;
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 100% !important;
}

.dog-report-video-inner-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.dog-report-video-outer-wrapper {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.dog-report-video-inner-wrapper.ended::after {
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 64px 64px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
}

.dog-report-video-inner-wrapper.paused::after {
  content:"";
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 50px;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
}
