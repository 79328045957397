$card-width: 384px;
$card-height: 447px;

.template-selector {
  @extend %flex-container-rows-wrapped-centred;
  width: 100%;
}

.template-card__content {
  height: 100%;
  overflow: hidden;
}

.template-card__top-border {
  width: $card-width;
  margin-left: -1px;
  height: 6px;
  background-color: $accent-primary-color;
  border-top-right-radius: $common-border-radius;
  border-top-left-radius: $common-border-radius;
}

.template-selector__template-wrapper {
  text-align: center;
  margin-bottom: 45px;
  &:nth-child(odd) {
    margin-right: 35px;
  }
}

.template-selector__template-header {
  @extend %typo-template-selector-template-header;
  padding: 16px 8px;
}

.template-card {
  @extend %hover-card;
  position: relative;
  display: inline-block;
  width: $card-width;
  height: $card-height;
  border-radius: $common-border-radius;
  background-color: $common-white-color;
  border: 1px solid $common-white-color;
  border-top: none;
  transition: border-color $medium-transition-time;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
  &:before {
    border-radius: $common-border-radius;
    margin-left: -1px;
    width: $card-width;
  }
}

.template-card_active {
  pointer-events: none;
  border: 1px dashed $accent-primary-color;
  border-top: none;
}
