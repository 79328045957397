$paw-width: 40px;
$paw-height: 5px;

.stepper {
  @extend %flex-container-rows-centred;
  margin-top: $common-margin-top-big;
  //@include underline(true, .36rem, 2.9rem, $call-to-action-active-color, 1);
  //padding-bottom: 2.54rem;

  @include mobile {
    margin-top: $common-margin-top-large_mobile;
  }
}

.stepper__paw {
  width: $paw-width;
  height: $paw-height;
  margin-left: .36rem;
  background-color: $common-medium-gray-color;
  transition: background-color $medium-transition-time;

  &:hover {
    cursor: pointer;
  }

  &:first-of-type {
    margin-left: 0;
  }
}

.stepper__paw_filled {
  background-color: $accent-secondary-color;
  transition: background-color $medium-transition-time;
}

.stepper__paw_disabled {
  &:hover {
    cursor: not-allowed;
  }
}
