.text-editor-container {
  height: auto;
  padding: 0;
}

.text-editor {
  margin-top: 0 !important;
  width: 100%;
  height: 326px;
  overflow-y: scroll;
  padding: 12px 25px 25px;
  transition: border-color $short-transition-time, background-color $short-transition-time;
}

.input-control__input.text-editor-container:focus,
.input-control__input.text-editor-container:active {
  padding: 0;
  outline: none;
}

.text-editor-container_invalid {
  border-color: $warning-color_not-desktop !important;
  .text-editor {
    background-color: $warning-color_not-desktop-rgba !important;
  }
  transition: border-color $short-transition-time, background-color $short-transition-time;
}

.ql-toolbar {
  @extend %flex-container-rows-centred-start;
  height: 50px;
  background: rgba(152, 153, 157, .1);
  border-bottom: 1px solid #ddd;
}

.ql-toolbar button {
  @extend %flex-container-rows-centred;
  outline: none;
  border: none;
  width: 35px;
  height: 35px;
  color: $common-gray-color;
  background: #e3e4e4;
  //margin-right: 10px;
  border-radius: $common-border-radius;
  transition: all $short-transition-time $easeOutCubic;
  &:before {
    @extend %typo-text-editor-toolbar;
    padding-top: 5px;
    text-align: center;
    width: 100%;
    height: 100%;
  }
}

.ql-toolbar button.ql-active,
.ql-toolbar button:hover {
  background: $common-dark-gray-color;
  color: $common-white-color;
  transition: all $short-transition-time $easeOutCubic;
}

.ql-toolbar button > svg {
  fill: $common-gray-color;
  transition: fill $short-transition-time $easeOutCubic;
}

.ql-toolbar button:hover > svg,
.ql-toolbar button.ql-active > svg {
  fill: $common-white-color;
  transition: inherit;
}

button.ql-bold:before,
button.ql-italic:before,
button.ql-underline:before {
  content: 'T';
  font-weight: bold;
}

button.ql-bold:before {
  font-family: 'ProximaNova-Bold';
}

button.ql-underline:before {
  text-decoration: underline;
  font-size: 21px;
}

button.ql-italic:before {
  font-style: italic;
  margin-right: 6px;
}

.ql-formats {
  @extend %flex-container-rows-centred-start;
  width: auto;
  height: 100%;
  padding: 0 20px;
  border-right: 1px solid #ddd;
  &:last-of-type {
    border-right: none;
  }
}

.ql-formats > button,
.ql-formats > span {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  p {
    margin: 0;
    padding: 0;
  }
}

.ql-editor {
  @extend %typo-ql-editor;
  color: $common-dark-gray-color;
  position: relative;
}

.ql-editor:focus {
  outline: none;
}

.ql-editor.ql-blank:before {
  @extend %typo-text-editor-placeholder-blank;
  content: attr(data-placeholder);
  font-weight: normal;
  pointer-events: none !important;
  position: absolute !important;
  color: $common-gray-color;
  top: 0;
  left: 0;
  transition: opacity $short-transition-time, color $short-transition-time, top $small-transition-time, font-size $small-transition-time, transform $small-transition-time !important;
}

.ql-editor:before {
  @extend %typo-input-top-placeholder;
  content: attr(data-placeholder);
  pointer-events: none !important;
  position: absolute !important;
  top: -16px;
  left: 0;
  color: $accent-primary-color;
  z-index: 2;
  transition: opacity $short-transition-time, color $short-transition-time, top $small-transition-time, font-size $small-transition-time, transform $small-transition-time !important;
}

.text-editor-container_invalid .ql-editor:before,
.text-editor-container_invalid .ql-editor.ql-blank:before {
  @extend %typo-input-top-placeholder;
  pointer-events: none !important;
  position: absolute !important;
  content: attr(data-placeholder);
  top: -16px;
  left: 0;
  color: $warning-color_not-desktop !important;
  transition: opacity $short-transition-time, color $short-transition-time, top $small-transition-time, font-size $small-transition-time, transform $small-transition-time !important;
}

.ql-hidden {
  display: none;
}

.ql-picker {
  position: relative;
  background: $common-white-color;
  border-radius: $common-border-radius;
  height: 35px;
}

.ql-header.ql-picker {
  width: 120px;
  margin-right: 0 !important;
}

.ql-color.ql-picker {
  width: 50px;
}

.ql-picker-label {
  border-radius: $common-border-radius;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.ql-picker-label > svg {
  display: none;
}

.ql-header .ql-picker-label {
  @extend %typo-ql-picker-label;
  color: $common-gray-color;
  padding-left: 8px;
  padding-right: 2px;
}

.ql-picker-label:after {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3IiBoZWlnaHQ9IjYiPg0KICAgIDxwYXRoIGZpbGw9IiM5ODk5OUQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTMuNDk3IDYuMDA1TC0uMDA3LS4wMDNoNy4wMDhMMy40OTcgNi4wMDV6Ii8+DQo8L3N2Zz4NCg==");
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  padding-top: 7px;
  background: #e3e4e4;
  border-top-right-radius: $common-border-radius;
  border-bottom-right-radius: $common-border-radius;
}

.ql-header .ql-picker-label:before {
  @extend %flex-container-rows-centred-start;
  height: 100%;
}

.ql-header .ql-picker-label:before,
.ql-header .ql-picker-item:before {
  content: 'Paragraph';
}

.ql-picker-options {
  display: none;
  box-shadow: $common-shadow;
  background: $common-white-color;
  border-radius: $common-border-radius;
  min-width: 100%;
  position: absolute;
  white-space: nowrap;
}

.ql-expanded .ql-picker-label {
  opacity: .5;
  transition: opacity $short-transition-time $easeOutCubic;
}

.ql-picker.ql-expanded .ql-picker-options {
  display: block;
  top: 40px;
  z-index: 2;
}

.ql-header .ql-picker-item {
  @extend %typo-ql-picker-label;
  color: $common-gray-color;
  cursor: pointer;
  display: block;
  padding: 10px 8px;
  transition: color $short-transition-time $easeOutCubic;
}

.ql-header .ql-picker-item:hover {
  color: $accent-secondary-color;
  transition: color $short-transition-time $easeOutCubic;
}

.ql-header .ql-picker-item[data-value='1']:before,
.ql-header .ql-picker-label[data-value='1']:before {
  content: 'Header' !important;
}

.ql-header .ql-picker-item[data-value='2']:before,
.ql-header .ql-picker-label[data-value='2']:before {
  content: 'Sub Header' !important;
}

.ql-color .ql-picker-item {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 25px;
  border: solid $common-white-color;
  border-width: 2px 4px;
  &:first-of-type {
    border-top-width: 4px;
    border-radius: 6px 6px 0 0;
  }
  &:last-of-type {
    border-bottom-width: 4px;
    border-radius: 0 0 6px 6px;
  }
  &:hover {
    opacity: .5;
    transition: opacity $short-transition-time $easeOutCubic;
  }
}

.ql-color .ql-picker-label,
.ql-color .ql-picker-item {
  background: $common-dark-gray-color;
}

.ql-color .ql-picker-label[data-value='#98999d'] {
  background: #98999d;
}

.ql-color .ql-picker-label[data-value='#ff5d22'] {
  background: #ff5d22;
}

.ql-color .ql-picker-label[data-value='#00a1c3'] {
  background: #00a1c3;
}

.ql-editor strong {
  font-family: ProximaNova-Bold;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor .ql-align-left {
  text-align: left;
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
