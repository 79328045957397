.link {
  color: $accent-secondary-color;
  transition: color $duration;
  cursor: pointer;

  @include desktop {
    &:hover {
      color: $accent-secondary-color-hover;
      transition-duration: $duration/2;
    }
  }
}
