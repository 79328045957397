.schedule-card {
  background: #fff;
  position: relative;

  &__inner {
    display: flex;
    position: relative;
    background: #fff;
    z-index: 1;
    box-shadow: $common-shadow;
    transition: box-shadow $duration;
  }

  &:hover {
    .schedule-card__map-wrapper,
    .schedule-card__inner {
      box-shadow: $common-shadow_lifter;
    }
  }

  &:hover &__edit {
    opacity: 1;
    @extend %faster;
  }

  &__header {
    width: 20%;
    min-width: 210px;
    border-right: 5px solid $accent-primary-color;
    padding: 22px 20px 14px;

    @include mobile {
      display: none;
    }
  }

  &__date {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__map-wrapper {
    width: 100%;
    background: #f8f8f8;
    padding: 0 90px;
    display: flex;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height $duration, box-shadow $duration;
    box-shadow: $common-shadow;

    @include tablet {
      padding: 0 15px;
    }

    @include mobile {
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &_opened {
      height: 305px;

      @include mobile {
        height: 410px;
      }
    }
  }

  &__map {
    width: 440px;
    height: 245px;
    margin-right: 40px;
    position: relative;

    @include mobile {
      height: 140px;
      width: 100%;
      margin: 0 0 20px;
    }
  }

  &__day-of-week {
    font-family: $bold;
    text-transform: uppercase;
  }

  &__day {
    letter-spacing: .8px;
  }

  &__body {
    width: 80%;
    padding: 30px 80px 30px 30px;
    display: flex;
    position: relative;

    @include mobile {
      width: 100%;
      display: block;
      padding: 0;
    }
  }

  &__photo {
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    width: 130px;
    min-width: 130px;
    height: 87px;
    position: relative;
    margin-right: 18px;

    @include mobile {
      width: 100%;
      height: 200px;
      border-bottom: 5px solid $accent-primary-color;
    }
  }

  &__access {
    display: block;
    position: absolute;
    width: 100%;
    height: 15px;
    background: #46b63b;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    font-family: $bold;
    text-align: center;

    @include mobile {
      width: 100px;
      top: 10px;
      left: 10px;
    }
  }

  &__content {
    display: block;
    letter-spacing: .9px;

    @include mobile {
      padding: 25px 16px;
    }
  }

  &__content-item {
    margin-bottom: 15px;
    display: flex;

    @include not-desktop {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_payment {
      display: none;

      @include tablet {
        display: flex;
      }

      @include mobile {
        display: block;
      }

      .schedule-card__price {
        display: none;

        @include mobile {
          display: block;
        }
      }
    }
  }

  &__name {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase;
    color: $accent-primary-color;
    line-height: 1;
    padding-left: 28px;
    position: relative;
    overflow: hidden;
    max-width: 340px;
    text-overflow: ellipsis;

    @include mobile {
      max-width: 240px;
    }

    .icon {
      fill: $accent-primary-color;
      position: absolute;
      width: 28px;
      height: 28px;
      top: -6px;
      left: -4px;
    }
    .icon_training {
      width: 24px;
      height: 24px;
      top: -3px;
      left: -2px;
    }
  }

  &__location,
  &__trainer {
    font-size: 16px;
    font-family: $bold;
    padding-left: 28px;
    position: relative;

    .icon {
      fill: $common-dark-gray-color;
      position: absolute;
      width: 19px;
      height: 19px;
      top: -3px;
      left: 0;
    }
  }

  &__location {
    margin-right: 16px;

    @include not-desktop {
      margin-bottom: 12px;
    }
  }

  &__time {
    font-size: 16px;
    padding-left: 28px;
    position: relative;

    @include not-desktop {
      flex-direction: column;
    }

    .icon {
      fill: $common-dark-gray-color;
      position: absolute;
      width: 19px;
      height: 19px;
      top: -2px;
      left: 0;
    }
  }

  &__time-item {
    &:last-child:after {
      display: none;
    }

    &:after {
      content: ' | ';
      margin: 0 5px;

      @include not-desktop {
        display: none;
      }
    }
  }

  &__edit-section {
    right: 30px;
    top: 30px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;

    @include mobile {
      height: 36px;
      top: 222px;
      right: 12px;
    }
  }

  &__edit {
    cursor: pointer;
    transition: color $duration, fill $duration;
    fill: $common-dark-gray-color;
    letter-spacing: .7px;
    font-size: 14px;
    font-family: $bold;
    text-transform: uppercase;

    @include not-desktop {
      opacity: 1;
    }
    @include mobile {
      font-size: 0;
    }

    &:hover {
      color: $accent-secondary-color-hover;
      fill: $accent-secondary-color-hover;
      @extend %faster;
    }

    .icon {
      display: block;
      width: 36px;
      height: 36px;
    }
  }

  &__dogs {
    border-top: 1px solid $common-medium-gray-color_accent;
    text-align: center;
    margin: 0 0 -14px;
    padding: 14px 0;
    position: relative;
  }

  &__dogs-body {
    position: absolute;
    padding: 14px 20px 10px;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    transition: box-shadow $duration;

    &_opened {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .08);
    }
  }

  &__dogs-list {
    position: relative;
  }

  &__dogs-last {
    transition: height $duration;
    height: 0;
    overflow: hidden;
  }

  &__dogs-hidden {
    position: absolute;
    width: 100%;
    visibility: hidden;
  }

  &__dogs-toggle {
    font-size: 12px;
    color: $accent-secondary-color;
    transition: color $duration;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $accent-secondary-color-hover;
      @extend %faster;
    }
  }

  &__dogs-list {
    text-align: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dog {
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $common-medium-gray-color_accent;
    background-size: cover;
    background-position: center;
    margin: 0 3px;
    font-size: 0;

    &_mid {
      width: 35px;
      height: 35px;
      margin: 0 3px;
    }

    &_min {
      width: 28px;
      height: 28px;
      margin: 0 2px;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__points {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__points-item {
    margin-bottom: 32px;
    line-height: 1.16;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__points-time {
    font-size: 16px;
    font-family: $bold;
    margin-bottom: 12px;

    &_pickup {
      color: $accent-primary-color;
    }

    &_dropoff {
      color: $accent-secondary-color;
    }
  }

  &__points-info {
    font-size: 16px;
  }

  &__points-place {
    font-family: $bold;
    display: block;
  }

  &__dropdown {
    cursor: pointer;
    display: flex;

    @include not-desktop {
      padding-right: 20px;
      position: relative;

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
      }
    }

    @include mobile {
      &:after {
        right: 3;
        top: 0;
        margin-top: 0;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      transform: rotate(45deg) translate(0, 0);
      margin-left: 10px;
      border: 2px solid $common-dark-gray-color;
      border-width: 0 2px 2px 0;
      transition: transform $duration, border-color $duration;
    }

    &_opened {
      &:after {
        transform: rotate(225deg) translate(-3px, -3px);
      }
    }

    &:hover {
      &:after {
        border-color: $accent-secondary-color-hover;
      }
    }
  }

  &__content-date {
    display: none;
    font-size: 18px;
    padding-left: 30px;
    color: $common-gray-color;

    @include mobile {
      display: block;
    }
  }

  &__dogs-slider {
    display: none;
    box-shadow: $common-shadow;
    position: relative;
    z-index: 2;
    background: #fff;
    white-space: nowrap;
    overflow: scroll;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid $common-medium-gray-color_accent;

    @include mobile {
      display: block;
    }

    .schedule-card__dog {
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
  }

  &_modal {
    .schedule-card {
      &__date {
        text-align: left;
        font-size: 20px;
      }
      &__day-of-week {
        display: inline-block;
        margin-right: 10px;
      }
      &__day {
        display: inline-block;
      }
    }
  }

  &__payment {
    width: 135px;
    position: absolute;
    right: 30px;
    top: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      width: 80px;
      right: 20px;
      justify-content: flex-start;
    }

    @include mobile {
      display: none;
    }

    .schedule-card__link {
      @include tablet {
        display: none;
      }
    }
  }

  &__price {
    font-size: 25px;
    font-family: $bold;
    margin-bottom: 15px;
    line-height: 1.1;

    @include not-desktop {
      font-size: 19px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_multiple {
      font-size: 19px;
    }
  }

  &__price-item {
    display: block;
    text-align: center;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mobile {
      text-align: left;
    }
  }

  &__link {
    @extend .link;
    font-size: 14px;
  }
}
