.details {
  @extend %background-pattern;
  padding: 1.96rem 2.9rem 3.62rem;
  min-height: calc(100vh - 60px - 193px - 50px);
  display: flex;
  @include mobile {
    flex-wrap: wrap;
    padding: 30px 10px 3.62rem;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  &__column {
    width: calc(50% - 9px);
    @include mobile {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
