$media-wide-desktop-min-width: 1280px;
$media-desktop-min-width: 1300px;
$media-mobile-max-width: 736px;
$media-wide-mobile-min-width: 480px;

// mixin usage - insert inside the block:
// @include desktop {styles...}

@mixin mobile {
  @media screen and (max-width: #{$media-mobile-max-width}) {
    @content;
  }
}

@mixin not-mobile {
  @media screen and (min-width: #{$media-mobile-max-width + 1px}) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (max-width: #{$media-wide-mobile-min-width}) {
    @content;
  }
}

@mixin not-small-mobile {
  @media screen and (min-width: #{$media-wide-mobile-min-width + 1px}) and (max-width: #{$media-mobile-max-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$media-mobile-max-width + 1px}) and (max-width: #{$media-desktop-min-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$media-desktop-min-width + 1px}) {
    @content;
  }
}

@mixin not-desktop {
  @media (max-width: #{$media-desktop-min-width}) {
    @content;
  }
}

@mixin wide-desktop {
  @media (min-width: #{$media-wide-desktop-min-width}) {
    @content;
  }
}

@mixin min-desktop {
  @media (min-width: #{$media-desktop-min-width + 1px}) and (max-width: #{$media-wide-desktop-min-width}) {
    @content;
  }
}

@mixin not-wide-desktop {
  @media (max-width: #{$media-wide-desktop-min-width - 1px}) {
    @content;
  }
}

@mixin orientation-portrait {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin orientation-landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}
