.class-event-card-shadow-container {
  width: 362px;
  padding: 6px;
  @include mobile {
    width: 272px;
  }
}

.class-event-card {
  @extend %hover-card;
  display: inline-block;
  position: relative;
  border-radius: $common-border-radius;
  background-color: $common-white-color;
  width: 350px;
  height: auto;
  padding: 24px;
  &:hover {
    .content-card__header-text {
      &:after {
        animation: levitate $medium-transition-time ease-in-out;
      }
    }
  }
  @include mobile {
    padding: 24px;
    width: 260px;
  }
}

.class-event-card__header {
  @extend .content-card__header;
}

.class-event-card__header-text {
  @extend .content-card__header-text;
  text-align: left;

  @include not-desktop {
    width: 100%;
    text-align: center;
    &:after {
      margin: 1.09rem auto 0;
      content: '';
      display: block;
      width: 37px;
      height: 5px;
      background-color: $accent-primary-color;
    }
  }
}

.class-event-card__item {
  @extend .content-card__item;
  text-align: left;
  @include not-desktop {
    text-align: center;
  }
}

.class-event-card__item-header {
  @extend .content-card__item-header;
}

.class-event-card__item-content {
  @extend .content-card__item-content;
  display: block;
}

.class-event-card__instructor-name {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.class-event-card__attendance {
  @extend %flex-container-rows-wrapped-start;
  padding: 16px 24px -16px;
  margin: 24px -24px;
  border-top: solid .5px $common-medium-gray-color_accent;
  border-bottom: solid .5px $common-medium-gray-color_accent;
}

.class-event-card__attendance-text {
  @extend %typo-content-card-item-header;
  width: 100%;
  text-align: center;
  padding: 18px 0 16px;
}

.class-event-card__attendance-avatar-container {
  @extend %flex-container-columns-centred;
  width: 25%;
  text-align: center;
  margin-bottom: 16px;
  @include mobile {
    width: 50%;
  }
}

.class-event-card__attendance-avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @include mobile {
    width: 56px;
    height: 56px;
  }
}

.class-event-card__attendance-name {
  @extend %typo-zone-card-name;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-top: 10px;
  padding: 0 10px;
  cursor: pointer;
  text-decoration: underline;
}

.class-event-card__footer {
  @extend %flex-container-columns-centred;
}

.class-event-card__footer-link {
  @extend %typo-link-button;
  @include underline(false, 1px, 100%, $accent-secondary-color, .3);
  display: inline-block;
  color: $accent-secondary-color;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    @include underline(false, 1px, 100%, $accent-secondary-color, 1);
  }
}
