$welcome-back-width_tablet: 65.1%;

.welcome-back {
  @extend .signin;
}

.welcome-back__logo {
  @extend .signin__logo;
}

.welcome-back__content {
  @extend .signin__content;
}

.welcome-back__container {
  @include onboarding-container($signin-width, $welcome-back-width_tablet, $signin-width_mobile);
  margin: calc(-80px - 3.19rem) auto 0;
}

.welcome-back__img {
  @extend .signin__img;
}

.welcome-back__step-description {
  width: 100%;

  .step-description__arrow,
  .step-description__image {
    display: none;
  }

  @include tablet {
    margin-top: $common-margin-top-great_mobile;
  }

  @include mobile {
    margin-top: 3.22rem;
    width: 80%;
  }
}

.welcome-back__form {
  @extend .signin__form;
  margin-top: $common-margin-top-large;

  @include tablet {
    margin-top: $common-margin-top-large_mobile;
    margin-bottom: $common-margin-large_tablet;
  }

  @include mobile {
    margin: 3.12rem 0;
  }
}

.welcome-back__button {
  @extend %flex-container-columns-centred;
  padding: 3.42rem 10px;
  text-align: center;
  border: 1px solid $common-medium-gray-color;
  transition: background-color $short-transition-time;

  &:hover {
    cursor: pointer;
    background-color: $accent-primary-color;
    border-color: $accent-primary-color;
    transition: background-color $short-transition-time, border-color $short-transition-time;

    .welcome-back__button-header,
    .welcome-back__button-description {
      color: white;
      transition: color $short-transition-time;
    }
  }

  @include mobile {
    padding: 2.61rem 1.3rem;

    &:last-of-type {
      padding: 2.61rem 0;
    }
  }
}

.welcome-back__button-header {
  @extend %typo-welcome-back-button-header;
  color: $accent-primary-color;
  transition: color $short-transition-time;
}

.welcome-back__button-description {
  @extend %typo-welcome-back-button-description;
  color: $common-black-color;
  margin-top: $common-margin-top-medium;
  //margin-top: .87rem;
  transition: color $short-transition-time;

  @include mobile {
    text-align: center;
    margin-top: .4rem;
  }
}

.welcome-back__divider {
  @extend %divider;
}

.welcome-back__divider-text {
  @extend %divider-text;
}
