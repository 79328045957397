.checkbox-list-control {
  width: 100%;
  color: $common-dark-gray-color;
  transition: border $short-transition-time, background-color $short-transition-time;
}

.checkbox-list-control__title,
.checkbox-list-control__description {
  @extend %typo-content-card-header;
  line-height: 1.2;
  transition: color $short-transition-time;
}

.checkbox-list-control__description {
  font-family: 'Proxima Nova Regular';
  text-transform: none;
}

.checkbox-list-control__checkboxes {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  margin: 10px 0;

  .swiper-wrapper {
    @include not-mobile {
      height: 100% !important;
    }
  }

  .checkbox-list-control__checkbox-label {
    margin: 5px;
    flex-grow: 1;
    min-width: 100px;
    @include mobile {
      min-width: 120px;
    }

    .checkbox-list-control__checkbox-name {
      width: 100%;
    }
  }
}

.checkbox-list-control__checkbox-label {
  @extend %flex-container;
}

.checkbox-list-control__checkbox-input {
  display: none;
  max-width: 50%;
}

.checkbox-list-control__checkbox-name {
  padding: 7px 0;
  min-width: 65px;
  text-align: center;
  border: 2px solid $common-dark-gray-color;
  background-color: $common-white-color;
  transition: background-color $short-transition-time, color $short-transition-time;
  border-radius: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  user-select: none;
}

.checkbox-list-control__checkbox-input:checked {
  & ~ .checkbox-list-control__checkbox-name {
    background-color: $common-dark-gray-color;
    color: $common-white-color;
  }
}

.checkbox-list-control_failed {
  background-color: $warning-color_not-desktop-rgba !important;
  border: 1px solid $warning-color_not-desktop;
  border-radius: 3px;
  padding: 3px;
  transition: border $short-transition-time, background-color $short-transition-time;

  .checkbox-list-control__title,
  .checkbox-list-control__description {
    color: $warning-color_not-desktop !important;
    transition: color $short-transition-time;
  }

  .checkbox-list-control__checkbox-name {
    border-color: $warning-color_not-desktop !important;
    background-color: $warning-color_not-desktop-rgba !important;
    color: $warning-color_not-desktop !important;
    font-weight: bold;
    transition: border-color $short-transition-time, background-color $short-transition-time, color $short-transition-time;
  }
}

.checkbox-list-control__error {
  @extend .input-control__error;
}
