.image-selector {
  position: relative;
  margin: 0 auto;
  width: 100%;
  & .form-field-error {
    text-align: center;
    width: 100%;
  }
}

.image-selector__placeholder {
  @extend %flex-container-columns-start-centred;
  cursor: pointer;
  position: relative;
  text-align: center;
  margin: 0 auto;
  background-color: #f8f8f8;
  border: 2px dashed #e8e8e8;
  border-radius: $common-border-radius;
  width: 100%;
  height: 100%;
}

.image-selector__icon-text {
  margin: -40px auto 0;
}

.image-selector__text {
  @extend %typo-image-selector;
  white-space: pre-line;
  pointer-events: none;
  margin-top: 10px;
  width: 100%;
}

.image-selector__image {
  @extend %flex-container-columns-start-centred;
  width: 100%;
  height: 100%;
  position: relative;
}

.image-selector__image_needs-crop {
  cursor: grab;
}

.image-selector__overlay-button {
  opacity: 0;
  border: 0;
  outline: 0;
  padding: 4px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  will-change: opacity;
  transition: opacity $easeOutCubic $short-transition-time;
  position: absolute;
  background-color: $accent-primary-color;
  color: white;
  &:hover {
    opacity: .95 !important;
  }
}

.image-selector.block4-image-selector .image-selector__overlay-button {
  padding: 4px 12px;
  font-size: 12px;
}

.overlay-button_right {
  top: 0;
  right: 0;
}

.overlay-button_left {
  top: 0;
  left: 0;
}

.image-selector__image:hover > .image-selector__overlay-button {
  opacity: .5;
}

.image-selector__loader {
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
