@keyframes levitate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  1000% {
    transform: translateY(0);
  }
}

.content-card {
  @extend %hover-card;
  display: inline-block;
  position: relative;
  //break-inside: avoid;
  // less width and additional margin because of the column layout
  //width: calc(98% - 8px);
  width: 100%;
  //margin: 1.09rem 4px 0;
  margin-top: 1.09rem;
  padding: 1.74rem;
  background-color: $common-white-color;

  &:nth-child(3),
  &:nth-child(4) {
    margin-bottom: 5px;
  }

  //&:nth-child(-n+3) {
  //  margin-right: .65rem;
  //}

  //&:nth-child(n+4) {
  //  margin-left: .65rem;
  //}

  &:hover {
    .content-card__header-text {
      &:after {
        animation: levitate $medium-transition-time ease-in-out;
      }
    }

    .content-card__header-action {
      visibility: visible;
      opacity: 1;
      transition: visibility $medium-transition-time ease-in-out, opacity $medium-transition-time ease-in-out;
    }

  button.content-card__header-action{
    background: none;
    border: none;
    padding: 0;
  }

  }

  @include mobile {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.content-card__header {
  @extend %flex-container-rows-between;
}

.content-card__header-text {
  @extend %typo-content-card-header;
  @extend %comments-word-breaker;
  color: $common-dark-gray-color;

  &:after {
    content: '';
    display: block;
    width: 37px;
    height: 4px;
    margin-top: 1.09rem;
    background-color: $accent-primary-color;
  }

  @include not-desktop {
    &:after {
      height: 5px;
    }
  }
}

.content-card__item {
  margin-top: 1.3rem;
  color: $common-dark-gray-color;

  &:first-of-type {
    margin-top: 1rem;
  }
}

.content-card__item_dog {
  @extend %flex-container-rows-centred-start;
  margin-top: 1.67rem;
}

.content-card__item-header {
  @extend %typo-content-card-item-header;
  @extend %comments-word-breaker;
}

a.content-card__item-header {
  display: inline-block;
  color: $common-dark-gray-color;
  text-decoration: underline;
  transition: color $short-transition-time;

  &:hover {
    cursor: pointer;
    color: $accent-secondary-color;
    transition: color $short-transition-time;

    &:after {
      background-color: $accent-secondary-color;
    }
  }

  //&:after {
  //  content: '';
  //  display: block;
  //  height: 1px;
  //  width: auto;
  //  max-width: 100%;
  //  background-color: $common-dark-gray-color;
  //  opacity: .3;
  //  transition: background-color $short-transition-time;
  //}
}

.content-card__header-actions {
  margin-top: -.4rem;
  height: 100%;
}

.content-card__header-action {
  margin-left: .5rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility $medium-transition-time ease-in-out, opacity $medium-transition-time ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:first-of-type {
    margin-left: 0;
  }

  @include mobile {
    visibility: visible;
    opacity: 1;
  }

  @include tablet {
    visibility: visible;
    opacity: 1;
  }
}

button.content-card__header-action{
  background: none;
  border: none;
  padding: 0;
}

.content-card__item-content {
  @extend %flex-container-rows-between;
  @extend %typo-content-card-item-content;
  @extend %comments-word-breaker;
  word-break: break-word;
  margin-top: .36rem;

  .content-card__item-content {
    margin-top: 0;
  }

  @include mobile {
    flex-flow: column nowrap;
  }
}

.content-card__item-content-data {
  @extend %comments-word-breaker;
  word-break: break-all;
}

.content-card__item-content-fake-url,
a.content-card__item-content-data,
a.content-card__item-content-info {
  //flex: none;
  @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
  display: inline-block;
  color: $common-dark-gray-color;
  transition: color $short-transition-time;

  &:hover {
    cursor: pointer;
    color: $accent-secondary-color;
    transition: color $short-transition-time;

    &:after {
      background-color: $accent-secondary-color;
      transition: background-color $short-transition-time;
    }
  }
}

.content-card__item-img {
  width: 72px;
  min-width: 72px;
  height: 72px;
  border-radius: 50%;

  &:before {
    content: 'Loading';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
  }
}

.content-card__item-info {
  @extend %truncate-this;
  margin-left: 1.45rem;
}

.content-card__item-content_requires {
  color: #c40808;
}

