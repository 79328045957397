.add-veterinarian {
  @extend .modal;
  @extend .modal__effect-right;
  left: 70%;
  width: 48.31%;
  box-shadow: $common-shadow;

  @include not-desktop {
    left: 50%;
    width: 85.94%;
  }
}

.add-veterinarian_show {
  @extend .modal_show;
}

.add-veterinarian__content {
  @extend .modal__content;
  padding: $common-margin-top-big 3.26rem 0;
  border-radius: $common-big-border-radius;
  background-color: $common-white-color;

  .step-description__arrow,
  .step-description__image {
    display: none;
  }

  @include mobile {
    padding: $common-margin-top-big .72rem 0;
  }
}

.add-veterinarian__content-description {
  @include mobile {
    width: 70%;
    margin: 0 auto;
  }
}

.add-veterinarian__form {
  margin-top: $common-margin-top-large;

  @include mobile {
    margin-top: $common-margin-universal-normal;
  }
}

.add-veterinarian__submit-button {
  @extend .signin__submit-button;
  margin-top: $common-margin-top-large;
  width: 38.66%;

  @include mobile {
    width: 84.61%;
    margin-top: 2.03rem;
  }
}

.add-veterinarian__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.add-veterinarian__skip-step {
  @extend .introduction__skip-step;

  @include mobile {
    .skip-step__close-link {
      padding: 1rem 0 2.1rem;
    }
  }
}
