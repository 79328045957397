.dogs {
  @extend .customers;
}

.dogs__filter {
  @extend .customers__filter;
}

.dogs__add-customer {
  @extend .customers__add-customer;
}

// consider .rt-tbody inheritance
.dogs-table {
  margin-top: 1.23rem;

  .rt-tbody {
    //overflow-y: scroll;
    min-height: calc(100vh - 25.6rem);
    max-height: calc(100vh - 25.6rem);

    @media screen and (max-width: 1020px) {
      min-height: calc(100vh - 25.6rem - 16px);
      max-height: calc(100vh - 25.6rem - 16px);
    }
  }

  //// workaround for FIT-478
  //// consider 9 columns + 100px + 16px for scroll bar
  //.ReactTable.-with-scroll-bar {
  //  .rt-tbody {
  //    min-width: 916px !important;
  //  }
  //}
}

.dogs-table__header {
  @extend %table__header;
}

.dogs-table__header-content {
  @extend %table__header-content;
}

.dogs-table__th {
  @extend %table__th;
}

.dogs-table__th_select {
  @extend %table__th_select;
}

.dogs-table__th_last-of-type {
  @extend .customers-table__th_last-of-type;
}

.dogs-table__td {
  @extend %table__td;
}

.dogs-table__dogs-link {
  @extend .customers-table__customer-link;
}
