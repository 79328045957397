.signup {
  @extend .signin;
}

.signup__img {
  @extend .signin__img;
}

.signup__content {
  @extend .signin__content;
}

.signup__container {
  @extend .signin__container;
  //margin: calc(-80px - 3.19rem) auto 0;

  // hack for social buttons so it doesn't broke
  @include min-desktop {
    min-width: $signin-width_min-desktop;
    max-width: $signin-width_min-desktop;
  }
}

.signup__logo {
  @extend .signin__logo;
}

.signup__form {
  @extend .signin__form;
}

.signup__social-login {
  @extend .signin__social-login;
}

.signup__divider {
  @extend %divider;
}

.signup__divider-text {
  @extend %divider-text;
}

.signup__submit-button {
  @extend .signin__submit-button;
}

.signup__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.signup__skip-step {
  @extend .signin__skip-step;
}
