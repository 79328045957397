.tabs {
  position: relative;
}

.tabs__list {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  border-top: 1px solid $common-medium-gray-color_accent;
  border-bottom: 1px solid $common-medium-gray-color_accent;
}

.tabs__item {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 66px;
  list-style: none;
  cursor: pointer;
  user-select: none;
  transition: background-color $duration, color $duration;
  font-size: 16px;
  text-transform: uppercase;
  font-family: $bold;
}

.tabs__item_selected {
  background-color: $accent-secondary-color;
  color: #fff;
  cursor: default;
  @extend %faster;
}

.tabs__item:not(.tabs__item_selected):hover {
  background-color: $accent-secondary-color-hover;
  color: #fff;
  @extend %faster;
}

.tabs__content {
  box-sizing: border-box;
  min-height: 200px;
  background-color: #fff;
}

.tabs__content p {
  margin-top: 0;
  line-height: 1.5;
}
