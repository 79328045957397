// @import url("//hello.myfonts.net/count/3406a2");

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../fonts/webfonts/3406A2_11_0.woff2') format('woff2'), url('../fonts/webfonts/3406A2_11_0.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('../fonts/webfonts/3406A2_14_0.woff2') format('woff2'), url('../fonts/webfonts/3406A2_14_0.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-Extrabld';
  src: url('../fonts/webfonts/3406A2_5_0.woff2') format('woff2'), url('../fonts/webfonts/3406A2_5_0.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/webfonts/3406A2_6_0.woff2') format('woff2'), url('../fonts/webfonts/3406A2_6_0.woff') format('woff');
}
