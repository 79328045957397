$zone-card-margin-desktop: 12px;
$zone-card-margin-tablet: 10px;

.glance-page {
  margin-top: 20px;
  margin-bottom: 20px;
}

.glance-page-filter {
  @extend %flex-container-columns-centred;
  margin: 16px auto;
  width: 100%;
}

.attendance__content {
  position: relative;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: 0 0 5px 5px;

  .rbc-custom-today {
    @include mobile {
      display: none;
    }
  }
}

.attendance__header {
  @extend %table__header;
}

.attendance__empty-content {
  @extend %flex-container-columns-centred;
  height: 288px;
  background-color: $common-white-color;
  text-align: center;
}

.zone-row-container {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }
}

.zone-row {
  @extend %flex-container-rows-centred;
  box-sizing: content-box;
  background-color: $common-white-color;
  color: $zone-card-color;
  padding: 22px 14px;
  @include mobile {
    overflow: auto;
    min-width: 594px;
    padding: 12px 14px;
  }
}

.zone-row_last {
  padding-bottom: 0;
  margin-bottom: 22px;
  @include mobile {
    margin-bottom: 12px;
  }
}

.zone-card {
  text-align: center;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: $common-border-radius;
  padding: 12px 10px 0;
  width: 173px;
  min-width: 173px;
  height: 85px;
  margin: 4px;
  margin-right: $zone-card-margin-desktop;
  cursor: pointer;
  transition: all $short-transition-time;
  @include not-desktop {
    width: 141px;
    min-width: 141px;
    margin-right: $zone-card-margin-tablet;
  }
}

.zone-card:last-child {
  margin-right: 0;
}

.zone-card:hover, .zone-card_selected {
  background-color: $common-dark-gray-color;
  color: $common-white-color;
  transition: all $short-transition-time;
}

.zone-card__zone-number {
  @extend %typo-zone-card-number;
  margin-bottom: 12px;
}

.zone-card__zone-name {
  @extend %typo-zone-card-name;
  text-align: center;
}

.linear-dashboard-search {
    width: 80%;
    margin: 30px 0 0 10%;
    height: 50px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0 .8rem;
    line-height: 1;
    font-size: 1.34rem;
    font-family: Proxima Nova Regular;
    font-weight: 600;
    letter-spacing: .5px;
    @include not-desktop {
        margin: 30px 0 30px 10%;
      }
}

.linear-dashboard-search::placeholder {
    color: #dddddc;
    font-size: 1.47rem;
    -webkit-transition: color .3s;
    transition: color .3s;
    opacity: 1; /* Firefox */
}

.linear-dashboard-search:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: #dddddc;
    font-size: 1.47rem;
    -webkit-transition: color .3s;
    transition: color .3s;
}

.linear-dashboard-search::-ms-input-placeholder { /* Microsoft Edge */
    color: #dddddc;
    font-size: 1.47rem;
    -webkit-transition: color .3s;
    transition: color .3s;
}

.glanceView {
    position: absolute;
    opacity: 1;
    z-index: 2;
    top: 5px;
    right: 20px;
}

button.glanceView{
  background: none;
  border: none;
  padding: 0;
}

.dashboard__view-icon {
    fill: $common-white-color;
    margin-left: 20px;
    width: 42px;
}

.icon_notes {
    width: 100%;
    height: 36px;
}