.step-indicator {
  text-align: center;
  width: 100%;
}

.step-indicator__steps {
  @extend %flex-container-rows-centred;
  width: 100%;
  margin-bottom: 10px;
}

.step-indicator__step {
  // Increase clicking area.
  padding: 5px 0;
  background-clip: content-box;
  box-sizing: content-box;
  width: 40px;
  height: 5px;
  margin-left: 5px;
  background-color: $common-medium-gray-color;
  transition: background-color $medium-transition-time;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $accent-secondary-color;
  }
}

.step-indicator__step_active {
  background-color: $accent-secondary-color;
  pointer-events: none;
}

.step-indicator__step_disabled {
  background-color: $common-medium-gray-color;
  &:hover {
    cursor: not-allowed;
    background-color: $common-medium-gray-color;
  }
}

.step-indicator__current-name {
  @extend %typo-notes-item-name;
  display: inline-block;
  color: $common-dark-gray-color;
}
