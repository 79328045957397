.ajax-loader {
  position: relative;
  font-size: 50px;
  color: #d31145;
  @extend %flex-container-columns-centred;
  padding-top: 50px;
  width: 100%;

  &__list {
    width: 2.3em;
    height: 4.2em;
  }

  &__paw {
    width: 1em;
    height: 1em;
    animation: 2050ms pawAnimation ease-in-out infinite;
    opacity: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    &:nth-child(odd) {
      transform: rotate(-10deg);
    }

    &:nth-child(even) {
      transform: rotate(10deg) translate(125%, 0);
    }

    @for $i from 0 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: #{ (($i * -1)+4) * .25 }s;
      }
    }

    .no-cssanimations & {
      opacity: 1;
    }
  }

  &__icon {
    fill: currentColor;
  }
}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
