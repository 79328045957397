.dropdown {
  display: block;
}

.dropdown__toggle {
  display: flex;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.dropdown__menu {
  @extend %flex-container-columns;
  border: solid 1px $common-medium-gray-color_accent;
  border-radius: 5px;
  box-shadow: $common-shadow;
  overflow: hidden;
  z-index: 10;
}

.dropdown__item {
  @extend %flex-container-rows-centred-start;
  min-height: 40px;
  padding: 5px 20px 5px 10px;
  text-align: left;
  border: none;
  border-bottom: solid 1px $common-medium-gray-color_accent;
  border-radius: 0;
  background-color: $common-white-color;
  fill: $common-dark-gray-color;
  color: $common-dark-gray-color;

  &:hover {
    background-color: $common-dark-gray-color;
    fill: $common-white-color !important;
    color: $common-white-color;
  }

  &:last-child {
    border-bottom: none;
  }

  @include not-desktop {
    padding: 0 20px 0 10px;
    min-height: 36px;
  }
}

.dropdown__item_disabled {
  color: $common-gray-color;
  fill: $common-gray-color;

  &:hover {
    cursor: not-allowed !important;
    background-color: $common-white-color !important;
    color: $common-gray-color !important;
    fill: $common-gray-color !important;
  }
}

.dropdown__text {
  @extend %typo-dropdown-menu;
  white-space: nowrap;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.dropdown__extra-text {
  @extend %typo-dropdown-menu-extra-text;
  display: block;
  color: $common-extra-text-color;
}

.dropdown__image {
  @extend %flex-container-rows-centred;
  height: 35px;
  width: 35px;
}

.dropdown-card {
  position: absolute;
  top: 27px;
  right: 40px;

  .dropdown__menu {
    position: absolute;
    top: 40px;
    right: 0;
  }
}

.dropdown_table {
  .dropdown__menu {
    position: absolute;
    margin-left: -166px;
    margin-top: -33px;
  }
}

.classes-table_previous {
  .dropdown_table {
    .dropdown__menu {
      position: absolute;
      margin-left: -250px;
      margin-top: -33px;
    }
  }
}
.dropdown_packages-card,
.dropdown_employee-card,
.dropdown_attending-dogs-card,
.dropdown_classes-schedule-card {
  .icon_chevron_down {
    fill: transparent;
  }
  .dropdown__menu {
    position: absolute;
    top: 10px;
    right: 30px;
  }
  .dropdown__toggle {
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.dropdown_classes-schedule-card,
.dropdown_packages-card {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown_table-packages-trainings {
  .dropdown__menu {
    position: absolute;
    margin-left: -232px;
    margin-top: -33px;
  }
}

.dropdown_table-packages {
  .dropdown__menu {
    position: absolute;
    margin-left: -120px;
    margin-top: -33px;
  }
}

.dropdown_table-directory {
  .dropdown__menu {
    position: absolute;
    margin-left: -210px;
    margin-top: -33px;
  }
}

.dropdown_table-classes {
  z-index: 1;
  .dropdown__menu {
    position: absolute;
    margin-left: -250px;
    margin-top: -33px;
  }
}

.dropdown.dropdown_forms-table-employee .dropdown__menu {
  position: absolute;
  right: 36px;
  top: 50%;
  margin-top: 0;
  transform: perspective(1px) translateY(-50%);
}

.forms-table-employee .ReactTable .rt-tbody .rt-tr-group:first-of-type .dropdown__menu {
  margin-top: 0;
  transform: perspective(1px) translateY(-25%);
}

.forms-table-employee .ReactTable .rt-tbody .rt-tr-group:last-of-type .dropdown__menu {
  margin-top: 0;
  transform: perspective(1px) translateY(-75%);
}
