.ReactTable {
  border-top: none;
  transition: max-height $medium-transition-time ease-in-out;
  border-radius: 0 0 $common-border-radius $common-border-radius;
}

.ReactTable.-collapsed {
  //transform: scaleY(0);
  max-height: 0;
  transition: max-height $medium-transition-time ease-in-out;
}

.ReactTable .rt-table {
  background-color: white;
  border-radius: 0 0 $common-border-radius $common-border-radius;

  @include not-desktop {
    position: relative;
  }
}

.ReactTable .rt-thead {
  //@include not-desktop {
  //  overflow: scroll;
  //}
}

.ReactTable .rt-thead.-headerGroups {
  border-bottom: none;
}

.ReactTable .rt-thead.-filters {
  background-color: white;
  border-bottom: none;
}

.ReactTable .rt-thead.-header {
  background-color: $common-gray-color_transparent;
  box-shadow: none;
}

.ReactTable .rt-tbody {
  border-top: 1px solid rgba(0, 0, 0, .05);
  background-color: $common-white-color;

  .rt-tr-group {
    &:last-of-type {
      .dropdown__menu {
        margin-top: -118px;
      }
    }
  }

  //only ios devices
  @supports (-webkit-overflow-scrolling: touch) {
    overflow: unset;
  }
}

.ReactTable .rt-th,
.ReactTable .rt-td,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 10px;
}

.ReactTable .rt-td {
  white-space: pre-wrap;
  text-align: center;
}

// dropdown inside table row
.ReactTable .rt-tr {
  transition: background-color $short-transition-time;

  .icon_chevron_down {
    fill: transparent;
  }

  &:hover {
    background-color: $common-medium-gray-color_accent;
    transition: background-color $short-transition-time;

    .icon_doc-img,
    .icon_doc-pdf {
      fill: $common-dark-gray-color;
    }

    .icon_chevron_down {
      fill: $common-gray-color;

      &:hover {
        fill: $common-dark-gray-color;
      }
    }
  }
}

.ReactTable input,
.ReactTable select {
  @extend %typo-table-search-input;
  padding: 0;
  margin-left: 6px;
  border: none;
}

.ReactTable .rt-thead.-filters .rt-th {
  background-color: white;
  padding-left: 35px;

  &:before {
    content: '';
    display: block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+DQogICAgPHBhdGggZmlsbD0iIzk4OTk5ZCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMjkuOTk4IDI4LjI5OUwyOC4zMDYgMzBsLTcuOTc2LTcuOTYyYTEyLjM2NSAxMi4zNjUgMCAwIDEtNy44NjMgMi44NjUgMTIuMzg2IDEyLjM4NiAwIDAgMS04Ljc5Ni0zLjYxMiAxMi40NyAxMi40NyAwIDAgMS0zLjY2Ny04LjgwMiAxMi40NzYgMTIuNDc2IDAgMCAxIDMuNjE1LTguODIyQTEyLjM3OCAxMi4zNzggMCAwIDEgMTIuMzk1LjAwM2ExMi4zNzcgMTIuMzc3IDAgMCAxIDguNzgzIDMuNjQ3IDEyLjQ3IDEyLjQ3IDAgMCAxIDMuNjMzIDguODE2YzAgMi44OC0uOTk0IDUuNjU4LTIuNzk0IDcuODdsNy45ODEgNy45NjN6TTE5LjUxNCA1LjM3M2ExMCAxMCAwIDAgMC0xNC4xNS0uMDUxIDEwLjA2NCAxMC4wNjQgMCAwIDAtMi45NjcgNy4wOTkgMTAuMDY4IDEwLjA2OCAwIDAgMCAyLjkxIDcuMTI0IDkuOTk0IDkuOTk0IDAgMCAwIDcuMDc5IDIuOTY1IDkuOTk3IDkuOTk3IDAgMCAwIDcuMDkyLTIuOTM0IDEwLjA3NCAxMC4wNzQgMCAwIDAgMi45NDEtNy4xMSAxMC4wNTUgMTAuMDU1IDAgMCAwLTIuOTA1LTcuMDkzeiIvPg0KPC9zdmc+DQo=");
    width: 20px;
    height: 20px;
    position: absolute;
    background-repeat: no-repeat;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ReactTable .rt-noData {
  display: none;
}

%table__header {
  @extend %typo-table-header;
  @extend %flex-container-rows-centred;
  text-align: center;
  padding: 10px;
  height: auto;
  line-height: 1.2;
  min-height: 40px;
  color: $common-white-color;
  background-color: $accent-primary-color;
  border-radius: 3px 3px 0 0;
}

%table__header-content {
  @extend %flex-container-rows-centred;
  position: relative;
  width: 100%;
  height: 100%;

  .icon_chevron-table {
    position: absolute;
    right: 13px;
    transition: transform $medium-transition-time;
  }

  .icon_chevron-table_collapsed {
    transform: rotate(-180deg);
    transition: transform $medium-transition-time;
  }
}

%table__th {
  @extend %typo-table-column;
  @extend %flex-container-rows-centred;
  //height: 50px;
  background-color: $common-gray-color_transparent;
  color: $common-dark-gray-color;

  .rt-resizable-header-content {
    padding-right: 0;
    transition: transform $short-transition-time;
  }

  &.-cursor-pointer {
    &:before {
      content: '';
      display: block;
      width: 11px;
      height: 7px;
      position: absolute;
      right: 9px;
      top: 50%;
      margin-top: -3.5px;
      opacity: 0;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7'%3E%3Cpath fill='%23373E49' fill-rule='evenodd' d='M10.265.746l-.252-.251-.497-.494-.497.494c-.356.353-.933.928-1.619 1.618L5.501 4.019 3.602 2.113C2.915 1.423 2.339.848 1.983.495L1.486.001.988.495.739.746l-.738.739 2.747 2.756c.994.998 1.803 1.808 2.255 2.259l.498.498.498-.498c.458-.457 1.279-1.279 2.255-2.259L11 1.485l-.735-.739z'/%3E%3C/svg%3E");
      transition: transform $short-transition-time, opacity $short-transition-time;
    }
  }

  &.-sort-desc,
  &.-sort-asc,
  &.-cursor-pointer:hover {
    box-shadow: none;
    position: relative;

    .rt-resizable-header-content {
      transform: translateX(-10px);
    }

    &:before {
      opacity: 1;
      transition: transform $short-transition-time, opacity $short-transition-time;
    }
  }

  &.-sort-desc {
    &:before {
      transform: rotateZ(180deg);
    }
  }
}

%table__th_select {
  overflow: initial !important;
  position: relative;
  padding-left: 10px !important;

  &:before {
    display: none !important;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    z-index: 9;
  }

  .filter__select.is-open {
    .Select-arrow {
      transform: rotate(180deg);
      transition: transform $short-transition-time;
    }
  }

  .filter__select {
    width: 100%;
    box-sizing: border-box;
    .Select-control {
      border: none;
      padding: 0;

      .Select-placeholder {
        text-align: left;
        width: 86%;
      }

      .Select-value {
        @extend %flex-container-rows-centred-start;
        padding: 0 !important;
        width: 86%;

        .Select-value-label {
          color: $common-gray-color !important;
          @extend %truncate-this;
        }
      }

      .Select-multi-value-wrapper {
        margin-right: 8px;
      }

      .Select-arrow-zone {
        width: 12px;
        padding-right: 0;
      }
    }

    .is-open,
    .is-focused {
      .Select-placeholder {
        color: $common-gray-color;
      }
    }

    .Select-placeholder {
      right: auto;
      left: 0;
      padding: 0;
      color: $common-gray-color;
    }

    .Select-arrow {
      width: 12px;
      height: 8px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA4Ij4NCiAgICA8cGF0aCBmaWxsPSIjRERERERDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4yNiAxLjQ1NmwtLjI1MS0uMjUxLS41LS40OTMtLjQ5OS40OTNjLS4zNTcuMzU0LS45MzYuOTI5LTEuNjI2IDEuNjE4TDYuNDc4IDQuNzI4IDQuNTY5IDIuODIzYTQ0NC42NTQgNDQ0LjY1NCAwIDAgMC0xLjYyNS0xLjYxOEwyLjQ0NS43MTFsLS40OTkuNDk0LS4yNTMuMjUxLS43MzkuNzM5IDIuNzU4IDIuNzU2YzEgLjk5OCAxLjgxMSAxLjgwOCAyLjI2NSAyLjI1OWwuNTAxLjQ5OC41LS40OThjLjQ2LS40NTcgMS4yODMtMS4yNzkgMi4yNjQtMi4yNTlMMTIgMi4xOTVsLS43NC0uNzM5eiIvPg0KPC9zdmc+DQo=");
      transition: transform $short-transition-time;
    }

    .Select-menu-outer {
      left: -35px;
      top: calc(100% + 10px);
      right: -10px;
      width: auto;
      border: 1px solid rgba(0, 0, 0, .02);
      border-radius: 0;

      .Select-menu {
        width: 100%;
        border-radius: 0;
      }

      .Select-option {
        width: 100%;
        color: $common-gray-color;
        text-align: left;
        background-color: white;
        transition: color $short-transition-time;

        //&.is-selected {
        //  color: $common-dark-gray-color;
        //  transition: color $short-transition-time;
        //}

        &.is-focused {
          background-color: $common-gray-color_transparent;
          transition: background-color $short-transition-time;
        }
      }
    }
  }
}

%table__td {
  @extend %typo-table-cell;
  @extend %flex-container-rows-centred;
  min-height: 90px;
  color: $common-dark-gray-color;
}

%table__empty-content {
  @extend %typo-table-cell;
  @extend %flex-container-columns-centred;
  height: 288px;
  background-color: $common-white-color;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, .1);
}

.table__td_last-of-type {
  position: relative;
  overflow: visible !important;
}

.ReactTable .rt-resizer {
  width: 16px;
  right: -8px;
}
