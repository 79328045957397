.sms-modal {
  text-align: center;
  &__form-wrapper {
    padding-top: 0;
    @include mobile {
      margin-top: 5px;
    }
  }

  &__title {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 1.3em;
    color: $common-dark-gray-color;
    margin: 0 0 10px;
  }

  &__text {
    font-size: 16px;
    letter-spacing: .8px;
    max-width: 500px;
    margin: 0 auto 39px;
    line-height: 1.3em;
    &_phone {
      max-width: 400px;
      margin-bottom: 30px;
    }
  }

  &__introduction {
    @include mobile {
      padding: 0 15px;
    }
    &__nav {
      display: inline-block;
      margin-bottom: 50px;
    }

    &__nav-item {
      cursor: pointer;
      margin-bottom: 20px;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
      &_text {
        @extend .modal-dialog__cancel-button;
      }
    }
  }

  &__verification {

    &__note {
      margin: 0 auto 30px;
      display: inline-block;
      font-size: 16px;
      line-height: 1.3em;
      font-family: $bold;
      max-width: 480px;
      span {
        display: block;
        @include mobile {
          display: inline;
        }
      }
      a {
        cursor: pointer;
        color: $accent-secondary-color;
      }

      & button {
        background: none;
        border: none;
        padding: 0;
      }
    }
  }

  &__cancel-button {
    @extend .modal-dialog__cancel-button;
  }

  &__form-footer {
    @extend .modal-dialog__form-footer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  &__form-content {
    margin: 0 auto 30px;
    max-width: 475px;
    @include mobile {
      padding: 0 15px;
    }
  }
  &__field {
    margin-bottom: 12px;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-notice {
    margin: 0;
    font-size: 14px;
    line-height: 1.3em;
    letter-spacing: .7px;
    color: #aaa;
    @include mobile {
      padding: 0 15px;
    }
    a {
      cursor: pointer;
      color: $accent-secondary-color;
    }

    & button {
      background: none;
      border: none;
      padding: 0;
    }
  }

  &__button {
    margin-top: 3px;
    max-width: 230px;
  }

  &__success-icon {
    display: inline-block;
    margin-bottom: 30px;
    &:before {
      content: '';
      display: inline-block;
      width: 66px;
      height: 66px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3My41IDczLjUiPg0KICAgIDxwYXRoIGZpbGw9IiNGRjVEMjIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkY1RDIyIiBzdHJva2Utd2lkdGg9IjUiDQogICAgICAgICAgZD0iTTM1LjUgMi41YzE4LjIyNSAwIDMzIDE0Ljc3NSAzMyAzM3MtMTQuNzc1IDMzLTMzIDMzLTMyLjk5OS0xNC43NzUtMzIuOTk5LTMzIDE0Ljc3NC0zMyAzMi45OTktMzN6Ii8+DQogICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIg0KICAgICAgICAgIGQ9Ik0zMC40NjYgNDIuMzEzbC03LjA1MS03LjE5Mi0zLjA5MiAzLjUyOCAxMC40IDEwLjcxNSAxOS45NTktMjQuNTQ3LTMuMzQ4LTMuMTgtMTYuODY4IDIwLjY3NnoiLz4NCjwvc3ZnPg0K");
    }
  }

  &__content {
    @include mobile {
      padding: 0 15px;
    }
  }
}

button.sms-modal__introduction__nav-item_text {
  background: none;
  border: none;
  padding: 0;
}
