.create-password {
  @extend .signin;
}

.create-password__img {
  @extend .signin__img;
}

.create-password__img_employee {
  @extend .signin__img_employee;
}

.create-password__content {
  @extend .signin__content;
}

.create-password__container {
  @extend .signin__container;
}

.create-password__container_success {
  @include onboarding-container($signin-width, $signin-width_tablet, $signin-width_mobile);
}

.create-password__logo {
  @extend .signin__logo;
}

.create-password__step-description {
  @extend %flex-container-columns-centred;
  margin-top: 6.67rem;
  width: 84.63%;

  .step-description__arrow,
  .step-description__image {
    display: none;
  }

  @include not-desktop {
    width: 100%;
  }

  @include tablet {
    margin-top: $common-margin-top-great_mobile;
  }

  @include mobile {
    margin-top: 3.08rem;
  }
}

.create-password__step-description_success {
  width: 100%;

  .step-description__text {
    margin-top: 2.54rem;

    @include not-desktop {
      margin-top: 1.81rem;
    }
  }

  @include mobile {
    margin-top: 2.93rem;
  }
}

.create-password__step-description_reset {
  @include mobile {
    width: 65%;
  }
}

.create-password__form {
  @extend .signin__form;
  margin-top: 3.26rem;

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: 2.68rem;
  }
}

.create-password__submit-button {
  @extend .signin__submit-button;
  width: 63.16%;

  @include mobile {
    width: 100%;
    margin-top: 1.45rem;
  }
}

.create-password__success-link {
  @extend .signin__submit-button;
  width: 69%;

  @include mobile {
    width: 100%;
    margin-top: 1.45rem;
  }
}

.create-password__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.create-password__skip-step {
  @extend .signin__skip-step;

  @include tablet {
    .skip-step__skip-link {
      padding-bottom: 6.81rem;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      padding: 2.61rem 0 $common-margin-top-big;
    }
  }
}

.create-password__skip-step_success {
  @include not-desktop {
    position: relative;

    .skip-step__skip-link {
      margin-top: $common-margin-top-large_mobile;
      padding: $common-margin-top-large_mobile 0 $common-margin-large_tablet;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      display: inline-block;
      margin-top: $common-margin-top-large_mobile;
      height: 1px;
      width: 45px;
      background-color: $common-medium-gray-color;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      margin-top: 1.96rem;
      padding: $common-margin-top-big 0;
    }

    &:before {
      margin-top: 1.96rem;
    }
  }
}

.create-password__skip-step_reset {
  @include tablet {
    .skip-step__skip-link {
      padding-bottom: 10.44rem;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      padding-bottom: 2.61rem;
    }
  }
}
