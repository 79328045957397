.sidebar {
  @extend %flex-container-rows;
  position: relative;
  min-height: calc(100vh - 60px);

  @include not-desktop {
    min-height: initial;
  }
  @include mobile {
    display: none;
  }
}

.sidebar__left {
  position: initial;
  z-index: 6;
  width: 60px;
  min-width: 60px;
  background-color: $common-white-color;
  fill: $common-dark-gray-color;
  &:hover {
    ~ .sidebar__right {
      transform: translateX(0);
      transition: transform $medium-transition-time;
    }
  }
}

.sidebar__right {
  position: absolute;
  transform: translateX(-160px);
  top: 0;
  left: 60px;
  height: 100%;
  width: 160px;
  min-width: 160px;
  z-index: 5;
  background-color: $common-white-color;
  box-shadow: .5px 0 0 0 $common-medium-gray-color;
  transition: transform $medium-transition-time;

  &:hover {
    transform: translateX(0);
  }
}

.sidebar__icon {
  @extend %flex-container-rows-centred;
  min-width: 60px;
  min-height: 60px;
}

.sidebar__text {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  white-space: nowrap;
  @extend %typo-sidebar-link;
  color: $common-dark-gray-color;
}

.sidebar-active {
  background-color: $accent-secondary-color;
  fill: $common-white-color;
  color: $common-white-color;
}
