$out-of-range-color: lighten(#333, 50%);

$calendar-border: #dddddc;
$cell-border: #dddddc;

$border-color: #CCC;

$segment-width: percentage(1 / 7);

$time-selection-color: white;
$time-selection-bg-color: rgba(0,0,0, .50);
$date-selection-bg-color: rgba(0,0,0, .10);

$event-bg: #ff5d22;
$event-border: #373e48;
$event-color: #373e48;
$event-border-radius: 0;
$event-padding: 4px 0 4px 5px;
$event-zindex: 4;

$btn-color: #373a3c;
$btn-bg: #fff;
$btn-border: #ccc;

$current-time-color: #373e48;

$today-highlight-bg: #f4f5f5;
