%skip-step {
  @extend %flex-container-rows-centred;
  @extend %typo-skip-step;
  height: 6.666666666666667rem;
  width: 100%;
  border-top: 1px solid $common-medium-gray-color;

  @include not-desktop {
    border-top: none;
  }
}

.skip-step__text {
  color: $common-dark-gray-color;
}

.skip-step__link {
  margin-left: .5rem;
  color: $accent-primary-color;
}

.skip-step__skip-link {
  @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
  color: $common-dark-gray-color;

  &:hover {
    cursor: pointer;
  }
}

button.skip-step__skip-link {
  background: none;
  border: none;
  padding: 0;
}

.skip-step__close-link {
  @extend .skip-step__skip-link;
  padding: 1.45rem 0 2.25rem;

  &:hover {
    cursor: pointer;
  }
}

button.skip-step__close-link {
  background: none;
  border: none;
  padding: 0;
}
