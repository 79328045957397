.page-header {
  @extend %flex-container-rows-centred-between;
  min-height: $page-header-min-height;
  padding-left: 25px;
  padding-right: 25px;
  background-color: $common-dark-gray-color;
  z-index: 11;
}

.dashboard__header-alert-container {
  z-index: 999;
}

.page-header_dashboard {
  position: relative;
  z-index: 4;
}

.page-header_item {
  flex: 1 0 auto;
}

.page-header__button {
  @extend %flex-container-rows-centred;
  position: relative;
  width: 42px;
  height: 42px;
  margin-left: 7px;
  margin-right: 7px;
  background-color: $common-dim-gray-color;
  border-radius: 50%;
  border: none;
  overflow: hidden;
  fill: $common-white-color;
}

.page-header__button {
  svg[class^='icon_'], div[class*='icon_'] {
    margin: 0 auto;
    display: block;
  }
}

.page-header__button-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.page-header__avatar {
  position: relative;
  .dropdown__menu {
    position: absolute;
    right: 0;
    top: 52px;
    width: 150px;
  }

  .dropdown__item {
    padding: 7px 0 7px 13px;
    height: auto;
    font-size: 14px;
    &:hover .icon_menu-profile {
      stroke: #fff;
    }
  }

  .dropdown__text {
    font-size: 14px;
    margin-left: 0;
  }
}

.page-header-logo {
  order: 1;
}

.page-header-search-bar {
  order: 2;
  max-width: 60%;
  flex: 3 0 auto;
}

.page-header-buttons {
  order: 3;
  .button-set {
    justify-content: flex-end;
    align-items: center;
  }
}

@include mobile {
  .page-header {
    padding: 0;
    min-height: 50px;
    height: 50px;
  }

  .page-header-logo,
  .page-header-search-bar,
  .page-header-buttons {
    display: flex;
    align-items: center;
  }

  .page-header-search-bar {
    height: 100%;
    left: 0;
    right: 57px;
    max-width: 100%;
    top: 0;
    position: absolute;
    display: none;
    padding: 0 !important;
    background: #373e48;
    z-index: 2;

    .search-bar {
      background: #373e48;
      padding-right: 5px;
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 4px;
      width: 100%;
      display: none;

      &__input {
        background: #373e48;
        border-radius: 0;
      }

      &__top-results {
        width: 100vw;
        top: 50px;
      }
    }

    &_opened {
      display: block;

      .search-bar {
        display: flex;
      }
    }
  }

  .page-header-buttons {
    justify-content: flex-end;
  }

  .page-header_item {
    padding: 0 10px;
  }

  .page-header__avatar {
    display: none;
  }
}

.page-header__customer-navbar {
  order: 2;
  flex: 3 0 auto;
  max-width: 700px;
  min-height: 60px;
  @include not-desktop {
    max-width: auto;
    flex: none;
    order: 4;
    min-width: calc(100% + 50px);
    background: #4b5159;
    margin: 0 -25px -5px;
    padding: 0 25px;
  }
}

.page-header__logout-btn {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-top: 5px;
  transition: color $short-transition-time;
  svg {
    transition: fill $short-transition-time;
  }
  &:hover {
    transition-duration: $short-transition-time/2;
    color: $accent-secondary-color;
    svg {
      transition-duration: $short-transition-time/2;
      fill: $accent-secondary-color !important;
    }
  }
}

.page-header_customer {
  z-index: 95;
  position: relative;

  @include not-desktop {
    flex-wrap: wrap;
    min-height: 120px;
  }
}

.page-header__hamburger {
  order: 4;
  display: none;
  padding-right: 10px;

  @include mobile {
    display: block;
  }
}

.page-header__search-icon {
  width: 26px;
  height: 26px;
  fill: #fff;
  margin-right: 10px;
  display: none;

  @include mobile {
    display: block;
  }
}

.page-header__daily-notifications {
  margin-right: 10px;
}
