.radio-control {
  @extend %flex-container-rows-between;
  margin-top: .8rem;

  &:first-of-type {
    margin-top: 0;
  }

  @include mobile {
    flex-flow: column nowrap;
    align-items: center;
  }
}

// #todo: refactor this modifier
.radio-control_vertical {
  flex-flow: column nowrap !important;
  align-items: center;

  .radio-control__description {
    text-align: center;
  }

  .radio-control__controls {
    // #smaller margins
    //margin-top: 1.88rem;
    margin-top: $common-margin-top-medium;

    @include not-desktop {
      margin-top: 1.67rem;
    }
  }
}

.radio-control__description {
  @extend %flex-container-columns;
  padding-right: 15px;
}

.radio-control__description_short {
  flex: 0 1 55%;
}

.radio-control__description-label {
  @extend %typo-radio-control-description-label;
  color: $common-dark-gray-color;

  @include mobile {
    text-align: center;
  }
}

.radio-control__description-warning {
  @extend %typo-radio-control-description-warning;
  color: $common-gray-color;

  @include mobile {
    margin-top: .5rem;
    text-align: center;
  }
}

.radio-control__description-warning_short {
  width: 70%;
}

.radio-control__controls {
  @extend %flex-container-rows-start;

  @include mobile {
    margin-top: $common-margin-universal-normal;
    padding: 1px;
  }
}

.radio-control__controls-label {
  @extend %flex-container-rows-centred;
  margin-left: 1.96rem;

  &:first-of-type {
    margin-left: 0;
  }

  @include mobile {
    margin-left: 3.56rem;
  }
}

.radio-control__controls-input {
  @include hide-input;

  &:checked {
    + .radio-control__controls-text {
      &:after {
        content: '\a0';
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -4px;
        left: 4px;
        width: 8px;
        height: 8px;
        background-color: $accent-primary-color;
        border-radius: 50%;
      }

      @include mobile {
        &:after {
          margin-top: -7px;
          left: 6px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  + .radio-control__controls-text {
    position: relative;

    &:before {
      content: '\a0';
      display: inline-block;
      margin-right: .43rem;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 1px $common-medium-gray-color;
      border-radius: 50%;
    }

    @include mobile {
      &:before {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.radio-control__controls-text {
  @extend %flex-container-rows-centred;
  @extend %typo-radio-control-text;
  position: relative;
  // height: 1.16rem;
  color: $common-dark-gray-color;

  @include mobile {
    font-size: 1.41rem;
  }
}

// todo: should it be defined as a global style via input[type...]?s
//input[type='radio'] {
//  position: absolute;
//  clip: rect(0, 0, 0, 0);
//
//  &:checked {
//    + span {
//      &:before {
//        content: '\a0';
//        display: inline-block;
//        margin-right: .43rem;
//        width: .58rem;
//        height: .58rem;
//        background-color: $radio-checked-color;
//        border: .29rem solid white;
//        border-radius: 50%;
//        box-shadow: 0 0 0 1px $input-bg-placeholder-color;
//      }
//    }
//  }
//
//  + span {
//    &:before {
//      content: '\a0';
//      display: inline-block;
//      margin-right: .43rem;
//      width: 1.16rem;
//      height: 1.16rem;
//      box-shadow: 0 0 0 1px $input-bg-placeholder-color;
//      border-radius: 50%;
//    }
//  }
//}
