.upload {
  &__item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  &__checkmark {
    margin-left: 5px;
  }
  &__cancel {
    @extend .link;
  }
  &__error {
    color: #f00;

    &-icon {
      display: inline-block;
      margin-right: 5px;
    }
    .link {
      text-align: end;
    }
  }
  &__wrapper {
    display: flex;
  }
}
