.dog-evaluation {
  @extend %background-pattern;
}

.dog-evaluation__content {
  @extend %dashboard__content;
}

.dog-evaluation__header {
  @extend %table__header;
  position: relative;
}

.dog-evaluation__items {
  height: 100%;
  margin: 0 55px 40px;
}

.dog-evaluation__item {
  padding: 40px 0;
  overflow: hidden;
}

.dog-evaluation__item:not(:last-child) {
  border-bottom: 1px solid $common-medium-gray-color_accent;
}

.dog-evaluation__question {
  @extend %typo-dog-evaluation-question;
}

.dog-evaluation__answer {
  @extend %typo-dog-evaluation-answer;
  word-wrap: break-word;
}
