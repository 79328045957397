.dog-selector {
  position: relative;
  margin-bottom: 24px;

  &__label {
    font-size: 16px;
    color: $common-dark-gray-color;
    text-align: center;
    margin-bottom: 27px;
    width: 100%;
    display: block;
  }

  &__content {
    position: relative;
  }

  &__loading {
    font-size: 17px;
    font-weight: bold;
    line-height: 145px;
    text-align: center;
  }

  &__title, &__subline {
    font-size: 16px;
    letter-spacing: .8px;
    text-align: center;
    color: $common-dark-gray-color;
    margin: 0 0 3px;
  }

  &__subline {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__error {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #f00;
  }

  &__head {
    position: relative;
    z-index: 11;
    margin-bottom: 27px;
  }
}
