.directory-table {
  margin-top: 0;

  .rt-tbody {
    min-height: calc(100vh - 32.1rem);
    max-height: calc(100vh - 32.1rem);

    @media not-desktop {
      min-height: unset;
      max-height: unset;
    }
  }

  // workaround for FIT-478
  // consider 9 columns + 100px + 16px for scroll bar
  .ReactTable.-with-scroll-bar {
    .rt-tbody {
      min-width: 666px !important;
    }
  }
}

.directory-table__header {
  @extend %table__header;
}

.directory-table__header-content {
  @extend %table__header-content;
}

.directory-table__th {
  @extend %table__th;
}

.directory-table__th_select {
  @extend %table__th_select;
}

// workaround for FIT-478
.directory-table__th_last-of-type {
  max-width: 65px !important;
  width: 65px !important;
}

.directory-table__td {
  @extend %table__td;
  position: relative;
  overflow: visible !important;
}

.directory-table__td_email {
  @extend .customers-table__td_email;
}

.directory-table__td_name {
  div {
    width: 100%;
    word-wrap: break-word;
  }
}

.directory-table__employee-link {
  @extend .customers-table__customer-link;
  word-break: normal;
}

.directory-table__empty-content {
  @extend %table__empty-content;
}
