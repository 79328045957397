.packages-table {

  .rt-tbody {
    //overflow-y: scroll;
    min-height: calc(100vh - 31.8rem);
    max-height: calc(100vh - 31.8rem);

    @media screen and (max-width: 1020px) {
      min-height: calc(100vh - 20.8rem - 16px);
      max-height: calc(100vh - 20.8rem - 16px);
    }

    @include mobile {
      min-height: calc(100vh - 24.8rem - 16px);
      max-height: calc(100vh - 24.8rem - 16px);
    }
  }

  // workaround for FIT-478
  // consider 6 columns + 100px + 50px + 16px for scroll bar
  .ReactTable.-with-scroll-bar {
    .rt-tbody {
      min-width: 666px !important;
    }
  }
}

.packages-table__th {
  @extend %table__th;
}

// workaround for FIT-478
.packages-table__th:last-child {
  max-width: 65px !important;
  width: 65px !important;
}

.packages-table__td {
  @extend %table__td;
}

.packages-table__td_last-of-type {
  position: relative;
  overflow: visible !important;
}
