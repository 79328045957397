.notifications {
  &__button {
    @extend %typo-button-submit;
    box-sizing: border-box;
    display: block;
    margin: 20px auto 0;
    text-align: center;
    line-height: 2.7;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 4px $accent-primary-color inset;
    color: $common-white-color;
    background-color: $accent-primary-color;
    transition: background-color $short-transition-time, color $short-transition-time, box-shadow $short-transition-time;
    padding: 0 10px;

    &:hover {
      cursor: pointer;
      background-color: $common-white-color;
      color: $accent-primary-color;
      transition: background-color $short-transition-time, color $short-transition-time;
    }

    @include mobile {
      width: 100%;
      margin-top: $common-margin-top-big;
    }
  }

  &__button--loading {
    background-color: $common-medium-gray-color_accent;
    box-shadow: none;
    transition: unset;

    &:hover {
      background-color: $common-medium-gray-color_accent;
      color: $common-white-color;
      transition: unset;
    }
  }

  &__title {
    font-family: $bold;
    text-transform: uppercase;
    font-size: 16px;
    color: $accent-primary-color;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
  }

  &__item {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item--disabled {
    opacity: 0.60;
  }

  &__text {
    padding-right: 20px;
    max-width: 535px;
    box-sizing: border-box;

    @include mobile {
      padding-right: 10px;
    }
  }

  &__toggle {
    &__desktop {
      display: flex;

      @include mobile {
        display: none;
      }
    }
    &__mobile {
      display: none;

      @include mobile {
        display: block;
      }
    }

    &_loading {
      pointer-events: none;

      .radio-control__controls-text {
        color: $common-gray-color;

        &:after {
          background-color: $common-gray-color !important;
        }
      }
    }
  }

  &__sent-block {
    padding: 0 0 40px;
    position: relative;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -305px;
      width: 610px;
      height: 1px;
      background: $common-medium-gray-color_accent;

      @include mobile {
        width: 100%;
        margin-left: -50%;
      }
    }

    &:first-child {
      padding-top: 40px;
    }

    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  & button {
    background: none;
    border: none;
    padding: 0;
  }
}
