.radio-control__controls-input-list-item {
  display: inline-block;
  height: 18px;
  position: relative;
  visibility: visible;
  width: 18px;

  &:before {
    border-radius: 50%;
    box-shadow: 0 0 0 1px $common-medium-gray-color;
    content: '';
    display: inline-block;
    height: 16px;
    left: 50%;
    margin-right: .43rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    width: 16px;
  }

  &:checked:after {
    background-color: $accent-primary-color;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 8px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    width: 8px;
    z-index: 2;
  }
}

.radio-control__controls-label-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}

.radio-control__controls-text-list-item {
  @extend %typo-radio-control-text;
  color: $common-dark-gray-color;
  font-size: 14px;
  line-height: 20px;
}

.radio-control__controls-text-wrapper {
  margin-left: 10px;
}
