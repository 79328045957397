@import 'select';
@import 'control';
@import 'menu';
@import 'mixins';
@import 'multi';
@import 'spinner';
@import 'option-renderers.scss';

.select-control {
  @extend .input-control;
}

.select-control__select {
  .Select-control {
    @extend .input-control__input;

    &:focus,
    &:active {
      padding: $input-value-padding;
    }
  }

  .Select-input {
    font-family: 'Proxima Nova SemiBold';
    padding: 0 !important;

    input {
      width: auto !important;
      line-height: 1;
    }
  }

  .Select-arrow-zone {
    width: 15px;
    padding-right: 0;
  }

  .Select-arrow {
    width: 15px;
    height: 9px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI0REREREQyINCiAgICAgICAgICBkPSJNMTMuOTk2Ljk1OWE0NC40MSA0NC40MSAwIDAgMC0uMzQzLS4zMjNsLS42NzctLjYzNC0uNjc4LjYzNWE1NjYuMzkgNTY2LjM5IDAgMCAwLTIuMjA4IDIuMDhsLTIuNTg5IDIuNDUtMi41OS0yLjQ1QTQ1OS4yOTYgNDU5LjI5NiAwIDAgMCAyLjcwNC42MzdMMi4wMjYuMDAxbC0uNjc4LjYzNi0uMzQyLjMyMi0xLjAwNS45NSAzLjc0NiAzLjU0M2ExNjc0LjU3IDE2NzQuNTcgMCAwIDAgMy4wNzQgMi45MDVsLjY4LjY0MS42OC0uNjQxYy42MjQtLjU4NyAxLjc0My0xLjY0NCAzLjA3NS0yLjkwNUwxNSAxLjkwOWwtMS4wMDQtLjk1eiIvPg0KPC9zdmc+");
    transition: transform $short-transition-time;
  }
  .Select-add {
    line-height: 0;
    position: absolute;
    right: 11px;
    cursor: pointer;
    top: $input-height/2;
    transform: translateY(-50%);

    &_disabled {
      cursor: not-allowed;
    }

    &:hover:not(.Select-add_disabled) > .icon_add-search {
      fill: $common-dark-gray-color;
      transition: fill $short-transition-time;
    }
  }

  .Select-placeholder {
    color: $common-medium-gray-color_accent;
    font-size: 1.47rem;
    transition: color $short-transition-time;
    @extend %typo-input-placeholder;

    &:hover {
      cursor: text;
    }

    @include mobile {
      padding-right: 2rem;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 2.4;
  }

  &.is-loading {
    pointer-events: none;

    .Select-control {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.is-disabled {
    + .select-control__placeholder {
      color: $common-gray-color;
    }
    .Select-arrow-zone {
      display: none;
    }

    > * {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .Select-option {
    //height: $input-height;
    font-size: 1.47rem;
    font-family: 'Proxima Nova SemiBold';
    //font-weight: bold;
    color: $common-dark-gray-color;
    padding: 1rem $select-padding-horizontal;
    transition: color $short-transition-time, background-color $short-transition-time;

    &.is-selected {
      background-color: $common-white-color;
      color: $accent-primary-color;

      @include mobile {
        background-color: $common-dark-gray-color;
        color: $common-white-color;
      }
    }

    &.is-focused {
      background-color: $common-dark-gray-color;
      color: $common-white-color;
      transition: color $short-transition-time, background-color $short-transition-time;

      .icon_location,
      .icon_card,
      .icon_purchased-package {
        fill: $common-white-color;
      }

      .add-icon {
        background-color: $common-white-color;
        transition: background-color $short-transition-time;

        &:before,
        &:after {
          background-color: $common-gray-color;
          transition: background-color $short-transition-time;
        }
      }

      //@include mobile {
      //  color: $common-dark-gray-color;
      //  background-color: $common-white-color;
      //}
    }

    // those icon rule should be in .select-control__upcoming-classes-option, but
    // need to fill it on the whole Select-Option hover
    .icon_location,
    .icon_card,
    .icon_purchased-package {
      fill: $common-dark-gray-color;
    }

    &:hover {
      .icon_location,
      .icon_card,
      .icon_purchased-package {
        fill: $common-white-color;
      }

      .add-icon {
        background-color: $common-white-color;
        transition: background-color $short-transition-time;

        &:before,
        &:after {
          background-color: $common-gray-color;
          transition: background-color $short-transition-time;
        }
      }
    }

    @include mobile {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // DISABLED STYLES
  .Select-option.is-selected:not(.is-focused):not(.is-disabled) {
    color: inherit;
  }

  .Select-option.is-disabled {
    color: $common-medium-gray-color_accent;

    &:hover {
      cursor: not-allowed;
    }
  }
}

// changing default control
.select-control__select.is-open > .Select-control {
  .Select-arrow {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI2ZmNWQyMiINCiAgICAgICAgICBkPSJNMTMuOTk2Ljk1OWE0NC40MSA0NC40MSAwIDAgMC0uMzQzLS4zMjNsLS42NzctLjYzNC0uNjc4LjYzNWE1NjYuMzkgNTY2LjM5IDAgMCAwLTIuMjA4IDIuMDhsLTIuNTg5IDIuNDUtMi41OS0yLjQ1QTQ1OS4yOTYgNDU5LjI5NiAwIDAgMCAyLjcwNC42MzdMMi4wMjYuMDAxbC0uNjc4LjYzNi0uMzQyLjMyMi0xLjAwNS45NSAzLjc0NiAzLjU0M2ExNjc0LjU3IDE2NzQuNTcgMCAwIDAgMy4wNzQgMi45MDVsLjY4LjY0MS42OC0uNjQxYy42MjQtLjU4NyAxLjc0My0xLjY0NCAzLjA3NS0yLjkwNUwxNSAxLjkwOWwtMS4wMDQtLjk1eiIvPg0KPC9zdmc+");
    transform: rotate(-180deg);
    transition: transform $short-transition-time;
  }
}

.select-control__select_autocomplete,
.select-control__select_creatable {
  .Select-input {
    padding-top: 1.5rem !important; // Andrey
  }
}

// fix field fot adding allergies on Health signUp page
.health {
  .select-control__select_autocomplete,
  .select-control__select_creatable {
    .Select-input {
      padding-top: 0 !important; // Andrey
    }
  }
}

.event-detail {
  .select-control__select_autocomplete,
  .select-control__select_creatable {
    .Select-input {
      padding-top: 0 !important; // Andrey
    }
  }
}

.has-value,
.is-focused,
.is-open,
.select-control__select_touched {
  .Select-control {
    @extend .input-control__input_touched;
    padding: $input-value-padding;
  }

  .Select-placeholder {
    color: transparent;
    transition: color $short-transition-time;
  }

  .Select-control {
    .Select-value {
      line-height: 3.8 !important;
      padding-left: .8rem !important;
      padding-right: .8rem !important;
    }
  }

  ~ .select-control__placeholder {
    @extend .input-control__placeholder_touched;
  }
}

.select-control__select_failed {
  .Select-control {
    @extend .input-control__input_failed;
  }
}

.select-control__placeholder {
  @extend .input-control__placeholder;
  //line-height: 2;
  right: 30px;
}

.select-control__placeholder_touched {
  @extend .input-control__placeholder_touched;
}

.select-control__placeholder_verified {
  @extend .input-control__placeholder_verified;
}

.select-control__placeholder_failed {
  @extend .input-control__placeholder_failed;
}

.select-control__error {
  @extend .input-control__error;
}

.select-control__hint {
  @extend .input-control__hint;
}

.select-control__select {
  &:not(.is-focused):not(.Select--multi) + .select-control__placeholder_empty,
  &:not(.is-focused).select-control__multibox + .select-control__placeholder_empty {
    @extend %typo-input-placeholder-empty;
    @extend %typo-input-placeholder-empty-vertical-align;
  }
  &:not(.is-focused) + .select-control__placeholder_empty {
    font-size: 1.34rem;
    position: absolute;
    vertical-align: bottom;
    left: .8rem;
    pointer-events: none;
    opacity: .5 !important;
    z-index: 1;
    font-family: 'Proxima Nova SemiBold';
    text-transform: none;
    top: $input-height/2;
    transform: translateY(-50%);
    &:not(.input-control__placeholder_failed) {
      color: $common-gray-color !important;
    }
  }
}

/* .select-control__select {
  &.Select--multi + .select-control__placeholder_empty {
    top: 0;
  }
} */

.select-control__multibox {
  width: 100%;

  .Select-control {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    padding: 0 1.8rem $input-value-padding !important;

    .Select-multibox-overflow-wrapper {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;

      .Select-multi-value-wrapper {
        height: 57px;
        position: absolute;
        width: fit-content;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        left: 0;
        padding-right: 1rem;
        .Select-value {
          padding-left: 0 !important;
          padding-right: .3rem !important;

          span.Select-value-label {
            white-space: nowrap;
            position: relative;
          }

          span.Select-value-label:after {
            content: ',';
            position: relative;
            right: -1px;
            bottom: 0;
          }

          &:nth-last-child(2) {

            span.Select-value-label:after {
              content: '';
            }
          }
        }
      }
    }

    .Select-arrow-zone {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.is-miltibox-overflow {
    .Select-multi-value-wrapper {
      left: auto !important;
      right: 0;
    }
  }

  .Select-menu-outer {
    $state-list-height: 366px;
    max-height: $state-list-height;

    .Select-menu {
      max-height: $state-list-height - 2px;
      white-space: nowrap;

      .select-control__multibox-option {
        overflow: hidden;
        text-overflow: ellipsis;
        .select-control__multibox-checkbox {
          $checkbox-size: 15.2px;
          min-width: $checkbox-size;
          width: $checkbox-size;
          height: $checkbox-size;
          border-radius: 3px;
          background-color: $common-white-color;
          border: solid 1px $common-medium-gray-color_accent;
          transition: background-color $short-transition-time, border-color $short-transition-time;
          position: relative;

          svg {
            fill: $common-white-color;
            margin: 0 auto;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
            display: block;
          }
        }

        .select-control__label {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .select-control__multibox-checkbox_checked {
          background-color: $accent-secondary-color;
          border-color: $accent-secondary-color;
        }
      }
    }
  }
}

.select-control__overflow {
  @extend %truncate-this;
}

.select-control__extra {
  font-size: 75%;
  color: $common-gray-color;
  line-height: 1.1;
}

// state select
.select-control__is-state {
  .Select-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    padding-left: .8rem !important;
    padding-right: .8rem !important;
    max-width: 100%;
    @extend %truncate-this;
    color: #aaa;
    input {
      line-height: 3.8;
      padding: 0;
      text-transform: uppercase;
      color: #333;
    }
  }
  .Select-menu-outer {
    $state-list-height: 135px;
    max-height: $state-list-height;
    .Select-menu {
      max-height: $state-list-height - 2px;
    }
  }
  .Select-menu {
    @include mobile {
      min-width: auto;
    }
  }
}

.select-control__upcoming-classes-option,
.select-control__multibox-option {
  @extend %flex-container-rows-centred-start;
}

.select-control__label {
  margin-left: 7px;

  @include not-desktop {
    width: 90%;
    white-space: normal;
  }
}

.Select-menu-outer_with-header {
  position: relative;
  max-height: $select-menu-max-height + $select-menu-header-height;
  padding-top: $select-menu-header-height;

  @include mobile {
    //width: 100% !important;
    overflow-x: scroll;
  }
}

.Select-menu {
  position: relative;
}

.Select-menu__header {
  @extend %flex-container-rows-centred-between;
  @extend %typo-input-value;
  padding: 0 10px;
  color: $common-dark-gray-color;
  z-index: 999;
  background: rgba($common-dark-gray-color, .1);
  position: absolute;
  top: 0;
  width: 100%;
  height: $select-menu-header-height;
}

.Select-loading-zone {
  position: absolute;
  right: 30px;
  top: 15px;
}
