.daily-notifications {
  position: relative;

  &__modal {
    position: absolute;
    right: 0;
    top: 100%;
    width: 360px;
    max-height: 549px;
    margin-top: 9px;
    background-color: #fff;
    box-shadow: 0 2px 7.9px .1px rgba(0, 0, 0, .16);
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity $duration;

    @include mobile {
      position: fixed;
      top: 90px;
      bottom: 0;
      width: 100%;
      margin-top: 0;
    }
  }

  &__list-head {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #4f5866;
    color: #babec4;
    padding: 15px 11px;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    pointer-events: none;
    transition: opacity $duration;
  }

  &_opened {
    .daily-notifications__modal {
      opacity: 1;
      pointer-events: all;
    }
    .daily-notifications__list-head {
      @include mobile {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__close {
    position: absolute;
    fill: #fff;
    top: 9px;
    right: 10px;
  }

  &__close-icon {
    fill: #fff;
    width: 21px;
    height: 21px;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__button {
    display: block;
    position: relative;
    width: 42px;
    height: 42px;
    margin: 0 7px;
    background-color: $common-dim-gray-color;
    border-radius: 50%;
    border: none;
    fill: $common-white-color;
    transition: background $duration;

    &:hover {
      background: $accent-secondary-color;
    }

    .icon_bell {
      display: block;
      margin: auto;
    }

    @include mobile {
      width: 32px;
      height: 32px;

      .icon_bell {
        transform: scale(.8);
      }
    }

    &_active {
      background: $accent-secondary-color;
      .daily-notifications__button__counter {
        display: block;
      }
    }

    &__counter {
      position: absolute;
      right: -3px;
      top: -3px;
      background: $accent-primary-color;
      color: #fff;
      width: 19px;
      height: 19px;
      line-height: 19px;
      text-align: center;
      font-size: 10px;
      border-radius: 50%;
      display: none;

      @include mobile {
        right: -7px;
        top: -5px;
      }
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  &__no-data {
    text-align: center;
    padding: 20px;
    font-family: $bold;
  }
}
