@mixin underline($isAbsolute, $height, $width, $color, $opacity) {
  &:after {
    content: '';
    @if $isAbsolute {
      position: absolute;
      bottom: 0;
    }
    display: block;
    height: $height;
    width: $width;
    background-color: $color;
    opacity: $opacity;
    transition: all $short-transition-time;
  }

  @include mobile {
    &:after {
      display: none;
    }
    text-decoration: underline;
  }
}

@mixin hide-input() {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

%background-pattern {
  background-image: url('/assets/imgs/dashboard-pattern.png');
  background-position: 0% 0%;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-color: transparent;
}

%hover-card {
  box-shadow: $common-shadow;

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: box-shadow $medium-transition-time cubic-bezier(.25, .8, .25, 1);
  }

  &:hover {
    // for FIT-532 moved this z-index to only employee-card
    // else it breaks content-cards on DetailsPage with column layout in safari
    //z-index: 1;

    &:before {
      box-shadow: $common-shadow_lifter;
      transition: box-shadow $medium-transition-time cubic-bezier(.25, .8, .25, 1);
    }
  }
}

%comments-word-breaker {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

%truncate-this {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%faster {
  transition-duration: $duration/2;
}

%select-value-transition {
  transition: background-color $short-transition-time, color $short-transition-time, padding $medium-transition-time;
}
