@import 'date-control-default.scss';
$datepicker-width: 231px;
$datepicker-header-height: 35px;
$datepicker-arrows-height: 15px;
$datepicker-arrows-width: 9px;
$datepicker-arrows-padding: 10px;
$datepicker-weekday-height: 20px;
$datepicker-date-width: 33px;
$datepicker-date-height: 25px;
$datepicker-date-selected-size: 21px;
.DayPickerInput-OverlayWrapper {
  width: $datepicker-width;
  top: 4px;
  z-index: 11;
  .DayPickerInput-Overlay {
    border-radius: 5px;
    overflow: hidden;
    background-color: $common-white-color;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .08);
    border: solid 1px $common-medium-gray-color_accent;
    box-sizing: border-box;
  }
}

.DayPicker-wrapper {
  padding: 0;
  *:focus {
    outline: none;
  }
  .DayPicker-NavBar {
    top: $datepicker-header-height/2 - $datepicker-arrows-height/2;
    padding: 0;
  }
  .DayPicker-NavButton--prev,
  .DayPicker-NavButton--next {
    width: $datepicker-arrows-width;
    height: $datepicker-arrows-height;
  }
  .DayPicker-NavButton--prev {
    left: $datepicker-arrows-padding;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.019 19.994'%3E%3Cdefs%3E%3Cfilter id='a'%3E%3CfeFlood flood-color='%23FFF' flood-opacity='1' result='floodOut'/%3E%3CfeComposite in='floodOut' in2='SourceGraphic' operator='atop' result='compOut'/%3E%3CfeBlend in='compOut' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath fill='%23373E48' fill-rule='evenodd' d='M8.041 1.003l.322.343.636.678-.636.678c-.453.485-1.194 1.27-2.079 2.207l-2.451 2.59 2.451 2.59c.885.936 1.626 1.722 2.079 2.206l.636.679-.636.678-.322.342-.95 1.005-3.544-3.746A1592.707 1592.707 0 0 1 .643 8.179l-.642-.68.642-.68c.587-.625 1.645-1.743 2.904-3.075L7.091-.001l.95 1.004z' filter='url(%23a)'/%3E%3C/svg%3E");
  }
  .DayPicker-NavButton--next {
    right: $datepicker-arrows-padding;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.019 19.994'%3E%3Cdefs%3E%3Cfilter id='a'%3E%3CfeFlood flood-color='%23FFF' flood-opacity='1' result='floodOut'/%3E%3CfeComposite in='floodOut' in2='SourceGraphic' operator='atop' result='compOut'/%3E%3CfeBlend in='compOut' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath fill='%23373E48' fill-rule='evenodd' d='M.959 1.003l-.323.343-.634.678.634.678c.455.485 1.193 1.27 2.081 2.207l2.449 2.59-2.449 2.59c-.888.936-1.626 1.722-2.081 2.206L0 12.974l.636.678c.097.101.205.216.323.342l.95 1.005 3.544-3.746c1.283-1.356 2.324-2.459 2.904-3.074l.64-.68-.64-.68c-.588-.625-1.644-1.743-2.904-3.075L1.909-.001l-.95 1.004z' filter='url(%23a)'/%3E%3C/svg%3E");
  }
  .DayPicker-Caption {
    @extend %typo-datepicker-font-basic;
    font-weight: bold;
    line-height: $datepicker-header-height;
    height: auto;
    background-color: $common-dark-gray-color;
    color: $common-white-color;
    text-transform: uppercase;
  }
  .DayPicker-Weekday {
    @extend %typo-datepicker-font-basic;
    font-weight: bold;
    color: $common-dark-gray-color;
    background-color: transparentize($common-medium-gray-color_accent, .3);
    height: $datepicker-weekday-height;
    vertical-align: middle;
    padding: 0;
    abbr {
      width: 9px;
      height: 11px;
      text-decoration: none;
      display: block;
      margin: 0 auto;
      overflow: hidden;
      text-transform: uppercase;
    }
    abbr {
      text-decoration: none;
      display: block;
      margin: 0 auto;
      overflow: hidden;
      text-transform: uppercase;
      height: 11px;
      &[title='Monday'] {
        width: 11px;
      }
      &[title='Wednesday'] {
        width: 13px;
      }
      &[title='Sunday'],
      &[title='Saturday'],
      &[title='Tuesday'],
      &[title='Thursday'],
      &[title='Friday'] {
        width: 9px;
      }
    }
  }
  .DayPicker-Month {
    margin: 0;
  }
  .DayPicker-Day {
    @extend %typo-datepicker-font-basic;
    border: none;
    padding: 0;
    box-sizing: border-box;
    width: $datepicker-date-width;
    height: $datepicker-date-height;
    position: relative;
    z-index: 0;
    transition: color $short-transition-time;
    &.DayPicker-Day--today:not(.DayPicker-Day--selected) {
      color: $accent-secondary-color;
      font-weight: bold;
      &:before {
        z-index: -1;
      }
    }
    &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: transparent;
      &:before {
        content: '';
        background-color: $accent-secondary-color;
        width: $datepicker-date-selected-size;
        height: $datepicker-date-selected-size;
        display: block;
        top: $datepicker-date-height/2 - $datepicker-date-selected-size/2;
        left: $datepicker-date-width/2 - $datepicker-date-selected-size/2;
        position: absolute;
        border-radius: 50%;
        z-index: -1;
      }
    }
    &.DayPicker-Day--disabled,
    &.DayPicker-Day--outside {
      font-size: 13.9px;
      letter-spacing: .7px;
      color: $common-gray-color;
      background-color: $common-white-color;
    }

    &:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      color: $accent-secondary-color;
      font-weight: bold;
      transition: color $short-transition-time;
    }
  }
} // date-control
.date-control {
  @extend .input-control;
  input {
    cursor: pointer;
  }
  &:not(.date-control_disabled):hover {
    .date-control__icon {
      fill: $common-dark-gray-color !important;
    }
  }
}

.date-control_disabled {
  .date-control__placeholder {
    color: $common-gray-color;
    opacity: 1;
  }

  .DayPickerInput,
  .date-control__placeholder,
  .date-control__icon-container,
  .date-control__input {
    &:hover {
      cursor: not-allowed !important;
    }
  }
}

.date-control__input {
  @extend .input-control__input;
  padding: $input-value-padding;

  &:placeholder-shown {
    font-weight: 400;
  }

  &:not(:placeholder-shown) {
    padding: $touched-input-padding;
  }

  &:focus,
  &:active {
    padding: $touched-input-padding;
  }

  &.has-value,
  &.is-focused,
  &.is-open,
  &.date-control__input_touched {
    @extend .input-control__input_touched;
  }

  &.date-control__input_failed {
    @extend .input-control__input_failed;
  }
}

.date-control__placeholder {
  @extend .input-control__placeholder;
}

.date-control__placeholder_touched {
  @extend .input-control__placeholder_touched;
}

.date-control__placeholder_verified {
  @extend .input-control__placeholder_verified;
}

.date-control__placeholder_failed {
  @extend .input-control__placeholder_failed;
}

.date-control__error {
  @extend .input-control__error;
}
.date-control__hint {
  @extend .input-control__hint;
}

.date-control__icon-container {
  position: absolute;
  right: 0;
  top: 0;
  width: $input-height;
  height: $input-height;

  &:hover {
    cursor: pointer;
  }
}

.date-control__icon {
  //position: absolute;
  //right: 11px;
  //pointer-events: none;
  //top: $input-height/2;
  //transform: translateY(-50%);
  margin: 11px;
  transition: fill $short-transition-time;
}

.rbc-toolbar {
  .DayPicker-wrapper {
    .DayPicker-Day {
      &.DayPicker-Day--today {
        color: white !important;

        &:before {
          content: '';
          display: block;
          position: absolute;
          background-color: $accent-secondary-color !important;
          width: $datepicker-date-selected-size;
          height: $datepicker-date-selected-size;
          top: $datepicker-date-height/2 - $datepicker-date-selected-size/2;
          left: $datepicker-date-width/2 - $datepicker-date-selected-size/2;
          border-radius: 50%;
        }
      }
    }
  }
}
