.summary {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &__container {
    overflow: hidden;
    transition: height $duration;
    &_expanded {
      @extend %faster;
    }
  }

  &__wrapper {
    padding: 25px 20px 20px;
    border-bottom: 1px solid #dddddc;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: .8px;
    color: $common-dark-gray-color;
    text-transform: uppercase;
    margin: 0 0 15px;
    @include mobile {
      text-align: center;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: $accent-primary-color;
      margin-top: 11px;
      @include mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__total {
    margin: 0;
    background: #f8f8f8;
  }

  &__total-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 29px 0;
    letter-spacing: .9px;
  }

  &__total-button {
    border-top: 1px solid $common-medium-gray-color_accent;
    display: none;
    text-align: center;
    cursor: pointer;
    color: $accent-secondary-color;
    font-size: 15px;
    font-weight: bold;
    padding: 18px 0;
    @include mobile {
      display: block;
    }
  }
}
