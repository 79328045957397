.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  color: $common-white-color;
  background-color: $common-dark-gray-color;
  transition: background-color $short-transition-time;

  &:hover {
    box-shadow: 0 0 0 4px $common-dark-gray-color inset;
    color: $common-dark-gray-color;
    background-color: $common-white-color;
    transition: background-color $short-transition-time;

    .add-button__icon {
      color: $common-white-color;
      background-color: $common-dark-gray-color;
      transition: background-color $short-transition-time;

      &:before,
      &:after {
        background-color: $common-white-color;
        transition: background-color $short-transition-time;
      }
    }
  }

  .add-button__icon {
    position: relative;
    color: $common-dark-gray-color;
    background-color: $common-white-color;
    margin-right: .8rem;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    transition: background-color $short-transition-time;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $common-dark-gray-color;
      transition: background-color $short-transition-time;
    }

    &:before {
      top: 50%;
      margin-top: -6px;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 12px;
    }

    &:after {
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -6px;
      width: 12px;
      height: 2px;
    }

    @include mobile {
      display: none;
    }
  }

  .add-button__text {
    padding-top: 1px;
    margin: 0 auto;
  }
}

// only icon, used in EditUpcomingClassModalDialog
.add-icon {
  position: relative;
  color: $common-white-color;
  background-color: $common-gray-color;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  transition: background-color $short-transition-time;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $common-white-color;
    transition: background-color $short-transition-time;
  }

  &:before {
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 10px;
  }

  &:after {
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -5px;
    width: 10px;
    height: 2px;
  }
}

.add-icon_card {
  margin: 0 3px;
}

