.contact-card {
  position: relative;
  @extend %flex-container-rows-centred-start;
  padding: 0 41px;
  min-height: 193px;

  @include mobile {
    flex-flow: column nowrap;
    padding: 30px 0;
  }
}

.contact-card__info {
  padding: 0 35px 0 25px;

  @include mobile {
    padding: 20px 0 0;
    text-align: center;
  }
}

.contact-card__title {
  display: inline-block;
  margin: 0;
  padding: 0;
  min-height: 39px;
  word-break: break-all;
  @extend %typo-contact-card-title;
  color: $accent-primary-color;
}

.contact-card__status {
  display: inline-block;
  margin-left: .5rem;
  padding: 0;
  @extend %typo-contact-card-status;
}

.contact-card__text {
  margin: 0;
  padding: 0;
  @extend %typo-contact-card-text;
  color: $common-dark-gray-color;
}
