.customers-transactions {
  @extend .customers-classes;
}

.transactions-table {
  @extend .classes-table;
  height: 100%;
  position: relative;
}

.transactions-table__header {
  @extend %table__header;
}

.transactions-table__header-content {
  @extend %table__header-content;
}

.transactions-table__th {
  @extend %table__th;
}

.transactions-table__td {
  @extend %table__td;
}

.transactions-table__td_transaction-id {
  @extend %comments-word-breaker;
  width: 100%;
}

.transactions-table__empty-content {
  @extend %table__empty-content;
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  background-color: white;
}

