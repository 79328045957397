.link-editor {
  position: absolute;
  left: 0;
  display: inline-block;
  transform: translateY(calc(-100% - 4px));
  & .input-control {
    border-radius: 3px;
  }
}

.link-editor__icon {
  @extend %flex-container-columns-centred;
  pointer-events: none;
  opacity: .01;
  width: 30px;
  height: 30px;
  border-radius: $common-border-radius;
  background-color: $common-dark-gray-color;
  transition: opacity $short-transition-time;
  &:after {
    opacity: .01;
    content: '';
    width: $common-border-radius;
    height: $common-border-radius;
    position: absolute;
    bottom: 27px;
    left: 30px;
    pointer-events: none;
    clip: rect(0, $common-border-radius, $common-border-radius, 0);
    border-top-left-radius: $common-border-radius;
    box-shadow: 0 0 0 5px $common-dark-gray-color;
  }
}

.link-editor__input-wrapper {
  @extend %flex-container-columns-centred;
  text-align: left;
  width: 336px;
  height: 68px;
  padding: 0 9px;
  background-color: $common-dark-gray-color;
  border-radius: $common-border-radius $common-border-radius $common-border-radius 0;
  & > .input-control {
    background: white;
    width: 100%;
  }
}

.link-editor__input-wrapper-enter {
  transform: translateY(7.5%);
  opacity: .01;
}

.link-editor__input-wrapper-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $short-transition-time, transform $short-transition-time;
}

.link-editor__input-wrapper-leave {
  opacity: 1;
}

.link-editor__input-wrapper-leave-active {
  opacity: .01;
  transition: opacity $short-transition-time;
}

.link-editor_active .link-editor__input-wrapper,
.link-editor.form-field_invalid .link-editor__input-wrapper,
.link-editor_active .link-editor__icon,
.link-editor.form-field_invalid .link-editor__icon,
.link-editor_active .link-editor__icon:after,
.link-editor.form-field_invalid .link-editor__icon:after {
  pointer-events: all;
  opacity: 1 !important;
}

.link-editor_active .link-editor__icon,
.form-field_invalid .link-editor__icon {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
