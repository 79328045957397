.mobile-header {
  display: none;
  background-color: $common-dark-gray-color;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  @include mobile {
    display: flex;
  }

  &__search {
    position: absolute;
    right: 60px;
    top: 10px;
  }

  &__search-icon {
    width: 26px;
    height: 26px;
    fill: #fff;
  }

  &__search-bar {
    position: absolute;
    right: 0;
    top: -7px;
    width: calc(100vw - 60px);
    display: none;

    &_opened {
      display: block;
    }

    .search-bar {
      background: #373e48;
      padding-right: 5px;

      &__input {
        background: #373e48;
      }
      &__top-results {
        width: 100vw;
        top: 50px;
      }
    }
  }
}
