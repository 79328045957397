.payment-history {
  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon-card {
    margin-right: 10px;
    width: 34px;
    height: 22px;
    display: block;

    @include mobile {
      width: 28px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .rt-tr-group {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
