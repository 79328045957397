.view-link {
  font-family: $bold;
  text-transform: uppercase;
  font-size: 16px;
  color: $common-dark-gray-color;
  transition: color $duration;

  &:hover {
    color: $accent-secondary-color;
    fill: $accent-secondary-color;
  }

  &:hover &__arrow {
    transform: translateX(5px);
  }

  &__arrow {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 8px;
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    transition: transform $duration, fill $duration;
  }
}
