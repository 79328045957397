.packages {
  @extend .customers;
  padding: 17px 40px 55px;

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.packages__top {
  @extend %flex-container-rows-centred;
  margin-bottom: 20px;

  @include tablet {
    justify-content: flex-start;
  }

  @include mobile {
    flex-flow: column nowrap;
  }
}

.packages__top-content {
  @extend %flex-container-rows-centred;
}

.packages__filter {
  width: 220px;
  margin-right: 20px;

  @include mobile {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.packages__view-icon-wrapper {
  @extend %flex-container-rows-centred;
  width: 42px;
  height: 32px;

  @include mobile {
    display: none;
  }
}

button.packages__view-icon-wrapper {
  background: none;
  border: none;
  padding: 0;
}

.packages__view-icon {
  fill: $common-gray-color;
  display: block;
}

.packages__add {
  @extend %flex-container-rows-centred;
  position: absolute;
  right: 40px;
  top: 32px;

  @include mobile {
    position: initial;
  }
}

.packages__header {
  @extend %table__header;
}

.packages__filter-tags {
  @extend %flex-container-columns-centred;
  padding: 17px 0;
  margin-bottom: 25px;
  border-top: 1px solid $common-medium-gray-color_accent;
  border-bottom: 1px solid $common-medium-gray-color_accent;
  text-align: center;
  min-height: 123px;
}

.packages__grid {
  @extend .directory__grid;
  @extend .directory__content;
}

.packages__empty-content {
  @extend %table__empty-content;
}
