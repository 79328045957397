.dogs-slider {
  width: 100%;
  overflow: hidden;
  padding: 0 52px;

  @include mobile {
    padding: 0;
    width: auto;
  }
  .swiper-button-next, .swiper-button-prev {
    width: 52px;
    background: #fff;
    @include mobile {
      display: none;
    }
    &:before, &:after {
      top: 55px;
    }
    &:not(.swiper-button-disabled):before {
      border-color: $common-dark-gray-color;
      transition: opacity $duration;
      opacity: .4;
    }
    &:not(.swiper-button-disabled):hover:before {
      transition-duration: $duration/2;
      opacity: 1;
    }
    &.swiper-button-disabled:before {
      border-color: $common-dark-gray-color;
      opacity: .2;
    }
  }

  .swiper-button-prev:before {
    left: 0;
  }

  .swiper-button-next:before {
    right: -1px;
  }

  &_no-swiper {
    text-align: center;
  }

  &__content {
    display: inline-flex;
    width: auto;
  }

  &__item {
    width: 110px !important;
    cursor: pointer;
    transform: translateZ(0); // add some magic
    transition: opacity $duration;
    opacity: .4;

    &:hover:not(&_active) {
      @include desktop {
        transition-duration: $duration/2;
        opacity: .8;
      }
    }

    &_active {
      transition-duration: $duration/2;
      opacity: 1;
    }

    &_disabled {
      opacity: .1;
      @include not-desktop {
        opacity: .5;
      }
      &:hover {
        opacity: .5 !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__photo {
    display: block;
    width: 110px;
    height: 110px;
    box-sizing: border-box;
    border: 4px solid $common-dark-gray-color;
    background: $common-dark-gray-color;
    border-radius: 50%;
    margin: 0 0 20px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 2px solid #fff;
      box-sizing: border-box;
    }
  }

  &_no-swiper &__item {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__name {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    transition: opacity $duration;
    background-color: rgba(0, 0, 0, .3);
    transform: translateZ(0);
    @include not-desktop {
      opacity: 1;
    }
    svg {
      width: 55px;
      height: 70px;
      fill: #fff;
    }
  }

  &__item_disabled:hover &__icon {
    @extend %faster;
    opacity: 1;
  }

  &__error {
    display: block;
    text-align: center;
    margin: 0 -10px;
    opacity: 0;
    transition: opacity $duration, transform $duration/2;
    transform: translateY(5px);
    @include not-desktop {
      transform: none;
      opacity: 1;
    }
  }

  &__item_disabled:hover &__error {
    @extend %faster;
    @include desktop {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__error-title {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .6px;
    line-height: 1.2em;
  }

  &__error-content {
    font-size: 12px;
    letter-spacing: .6px;
    line-height: 1.2em;
    display: block;
  }

  &__upload-file {
    cursor: pointer;
    color: $accent-secondary-color;
    transition: color $duration;
    background: none;
    border: none;
    padding: 0;

    &:hover {
      @extend %faster;
      color: darken($accent-secondary-color, 20%);
    }
  }
}
