.input-control {
  @extend %flex-container-columns;
  position: relative;
  margin-top: 1rem;
  // overflow: hidden;

  textarea {
    max-width: 100%;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.input-control__input {
  @extend %typo-input-value;
  box-sizing: border-box;
  height: $input-height;
  width: 100%;
  padding: $input-value-padding;
  border-radius: $common-border-radius;
  border: 1px solid $common-medium-gray-color;
  color: $common-dark-gray-color;
  line-height: 1;
  //transition: border-color $short-transition-time background-color $short-transition-time;
  // transition: padding $short-transition-time;

  &::placeholder {
    @extend %typo-input-placeholder;
    color: $common-medium-gray-color_accent;
    transition: color $short-transition-time;
  }

  &:focus,
  &:active {
    padding: $touched-input-padding;

    ~ .input-control__placeholder {
      color: $accent-primary-color;
      opacity: 1;
      transition: opacity $short-transition-time, color $short-transition-time;
    }

    &::placeholder {
      color: transparent;
      transition: color $short-transition-time;
    }
  }

  &:disabled {
    background-color: $common-input-disabled-color;
  }

  @include mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.input-control__input_touched {
  padding: $touched-input-padding;
  transition: border-color $short-transition-time, background-color $short-transition-time;

  &::placeholder {
    color: transparent;
    transition: color $short-transition-time;
  }
}

.input-control__input_failed {
  border-color: $warning-color_not-desktop !important;
  background-color: $warning-color_not-desktop-rgba !important;
  transition: border-color $short-transition-time, background-color $short-transition-time;
}

.input-control__input_empty:not(:-webkit-autofill):not(textarea) {
  padding: $input-value-padding !important;
}

.input-control__placeholder {
  @extend %typo-input-top-placeholder;
  //line-height: 1;
  position: absolute;
  top: .95rem;
  left: .8rem;
  right: .8rem;
  opacity: 0;
  z-index: 0;
  transform: translateY(0%);
  max-width: 90%;
  @extend %truncate-this;
  transition: opacity $short-transition-time, color $short-transition-time, top $small-transition-time, left $small-transition-time, font-size $small-transition-time, transform $small-transition-time !important;
  // transition: all $small-transition-time !important;
}

.input-control__placeholder_empty {
  top: 1.88rem;
}

.input-control__placeholder_touched {
  opacity: 1;
  color: $accent-primary-color;
  z-index: 2;
  transition: opacity $short-transition-time;
}

.input-control__placeholder_verified {
  opacity: 1;
  color: $accent-primary-color;
}

.input-control__placeholder_failed {
  opacity: 1;
  color: $warning-color_not-desktop !important;
  border-color: $warning-color_not-desktop;
}

.input-control__autocomplete:not(:-webkit-autofill),
.input-control__input:not(:-webkit-autofill) {
  + .input-control__placeholder_empty {
    @extend %typo-input-placeholder-empty;
  }
  &:not(textarea) + .input-control__placeholder_empty {
    @extend %typo-input-placeholder-empty-vertical-align;
  }
}

.input-control__textarea {
  .input-control__placeholder_empty {
    @extend %typo-input-placeholder-empty;
  }
}

.input-control__placeholder_empty {
  font-size: 1.34rem;
  position: absolute;
  vertical-align: bottom;
  left: .8rem;
  pointer-events: none;
  opacity: .5 !important;
  z-index: 1;
  font-family: 'Proxima Nova SemiBold';
  text-transform: none;
  top: $input-height/2;
  transform: translateY(-50%);
  &:not(.input-control__placeholder_failed) {
    color: $common-gray-color !important;
  }
}

.input-control__info {
  @extend %flex-container-rows;
  justify-content: flex-end;
  margin-top: $common-margin-top-medium;

  &:empty {
    margin-top: 0;
  }

  .input-control__error {
    margin-top: 0;
  }

  //@include not-desktop {
  //  margin-top: 1.44rem;
  //}
}

.input-control__info_center {
  justify-content: center;
}

.input-control__info_multi {
  justify-content: space-between;

  .input-control__helper ~ .input-control__error {
    flex-basis: 65%;
  }
}

.input-control__error {
  @extend %typo-input-error;
  margin: $common-margin-top-medium .8rem 0;
  color: $warning-color_not-desktop;

  .error__helper {
    display: inline-block;
    text-decoration: underline;
    //@include underline(false, 1px, 100%, $common-dark-gray-color, .3);
    margin-top: .65rem;
    color: $common-dark-gray-color;
    font-size: .9rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.input-control__helper {
  @extend %typo-input-hint;
  @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
  align-self: flex-end;
  color: $common-dark-gray-color;

  &:hover {
    cursor: pointer;
  }
}

.input-control__hint {
  @extend %typo-input-hint;
  color: $common-gray-color;
  margin-top: $common-margin-top-medium;

  @include mobile {
    text-align: center;
    margin-top: 1.45rem;
  }
}

.input-control__toggler {
  position: absolute;
  width: $input-password-toggler-width;
  height: $input-password-toggler-height-hidden;
  top: calc(#{$input-height} / 2 - #{$input-password-toggler-height-hidden} / 2);
  right: 1rem;

  &:hover {
    cursor: pointer;

    //.input-control__toggler-icon {
    //  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdib3g9IjAgMCAxMSAxMCI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjRkZGIg0KICAgICAgICAgIGQ9Ik0zLjY3NCA3LjQ1NUwxLjExOSA0Ljg2Mi0uMDAyIDYuMTM0bDMuNzY5IDMuODY0TDExIDEuMTQ2IDkuNzg3LS4wMDEgMy42NzQgNy40NTV6Ii8+DQo8L3N2Zz4=");
    //}
  }
}

button.input-control__toggler{
  background: none;
  border: none;
  padding: 0;
}

.input-control__toggler-icon {
  width: $input-password-toggler-width;
  height: $input-password-toggler-height-hidden;
  fill: $common-medium-gray-color;
  transition: fill $short-transition-time;
}

.input-control__toggler-icon_shown {
  height: $input-password-toggler-height-shown;
  margin-top: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMiI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjZGRkIg0KICAgICAgICAgIGQ9Ik0xMC45OTYgNC42OTZjLjY5MiAwIDEuMjQ4LjU3NCAxLjI0OCAxLjMwMyAwIC43MjEtLjU1OCAxLjMwMy0xLjI0OCAxLjMwMy0uNjk5IDAtMS4yNDktLjU4My0xLjI0OS0xLjMwMyAwLS43MjkuNTUtMS4zMDMgMS4yNDktMS4zMDN6bTAtMS4wNDVjLTEuMjM5IDAtMi4yNSAxLjA1NC0yLjI1IDIuMzQ4IDAgMS4yOTMgMS4wMTEgMi4zNDggMi4yNSAyLjM0OCAxLjIzMSAwIDIuMjQ5LTEuMDU1IDIuMjQ5LTIuMzQ4IDAtMS4yOTQtMS4wMTgtMi4zNDgtMi4yNDktMi4zNDh6bTMuNDM3LTIuMTE3YzIuNjU4Ljc1OCA0LjkxNyAyLjM1NyA2LjQyMiA0LjQ2NS0xLjUxNCAyLjEwNy0zLjc2NCAzLjcwNi02LjQyMiA0LjQ2NWE1Ljg0NSA1Ljg0NSAwIDAgMCAyLjA2NC00LjQ2NSA1Ljg0MiA1Ljg0MiAwIDAgMC0yLjA2NC00LjQ2NXptLTYuODczIDBhNS44MSA1LjgxIDAgMCAwLTIuMDY0IDQuNDY1YzAgMS44MDIuODA2IDMuNDEgMi4wNjQgNC40NjUtMi42NTctLjc1OS00LjkxNy0yLjM1OC02LjQyMy00LjQ2NUMyLjY0MyAzLjg5MSA0LjkwMyAyLjI5MiA3LjU2IDEuNTM0em0zLjQzNy0uMjMxYzIuNDkgMCA0LjUgMi4wOTggNC41IDQuNjk2cy0yLjAxIDQuNjk1LTQuNSA0LjY5NWMtMi40OTcgMC00LjUtMi4wOTctNC41LTQuNjk1IDAtMi41OTggMi4wMDMtNC42OTYgNC41LTQuNjk2em0wLTEuMzAzQzYuNDA4IDAgMi4zNyAyLjI1Ni4wODUgNS42OTRhLjU2Ny41NjcgMCAwIDAgMCAuNjAxYzIuMjg1IDMuNDI5IDYuMzIzIDUuNzA0IDEwLjkxMiA1LjcwNCA0LjU4IDAgOC42MjgtMi4yNzUgMTAuOTEzLTUuNzA0YS41ODYuNTg2IDAgMCAwIDAtLjYwMUMxOS42MjUgMi4yNTYgMTUuNTc1IDAgMTAuOTk2IDBoLjAwMXoiLz4NCjwvc3ZnPg0K");

  &:hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMiI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjMzczZTQ4Ig0KICAgICAgICAgIGQ9Ik0xMC45OTYgNC42OTZjLjY5MiAwIDEuMjQ4LjU3NCAxLjI0OCAxLjMwMyAwIC43MjEtLjU1OCAxLjMwMy0xLjI0OCAxLjMwMy0uNjk5IDAtMS4yNDktLjU4My0xLjI0OS0xLjMwMyAwLS43MjkuNTUtMS4zMDMgMS4yNDktMS4zMDN6bTAtMS4wNDVjLTEuMjM5IDAtMi4yNSAxLjA1NC0yLjI1IDIuMzQ4IDAgMS4yOTMgMS4wMTEgMi4zNDggMi4yNSAyLjM0OCAxLjIzMSAwIDIuMjQ5LTEuMDU1IDIuMjQ5LTIuMzQ4IDAtMS4yOTQtMS4wMTgtMi4zNDgtMi4yNDktMi4zNDh6bTMuNDM3LTIuMTE3YzIuNjU4Ljc1OCA0LjkxNyAyLjM1NyA2LjQyMiA0LjQ2NS0xLjUxNCAyLjEwNy0zLjc2NCAzLjcwNi02LjQyMiA0LjQ2NWE1Ljg0NSA1Ljg0NSAwIDAgMCAyLjA2NC00LjQ2NSA1Ljg0MiA1Ljg0MiAwIDAgMC0yLjA2NC00LjQ2NXptLTYuODczIDBhNS44MSA1LjgxIDAgMCAwLTIuMDY0IDQuNDY1YzAgMS44MDIuODA2IDMuNDEgMi4wNjQgNC40NjUtMi42NTctLjc1OS00LjkxNy0yLjM1OC02LjQyMy00LjQ2NUMyLjY0MyAzLjg5MSA0LjkwMyAyLjI5MiA3LjU2IDEuNTM0em0zLjQzNy0uMjMxYzIuNDkgMCA0LjUgMi4wOTggNC41IDQuNjk2cy0yLjAxIDQuNjk1LTQuNSA0LjY5NWMtMi40OTcgMC00LjUtMi4wOTctNC41LTQuNjk1IDAtMi41OTggMi4wMDMtNC42OTYgNC41LTQuNjk2em0wLTEuMzAzQzYuNDA4IDAgMi4zNyAyLjI1Ni4wODUgNS42OTRhLjU2Ny41NjcgMCAwIDAgMCAuNjAxYzIuMjg1IDMuNDI5IDYuMzIzIDUuNzA0IDEwLjkxMiA1LjcwNCA0LjU4IDAgOC42MjgtMi4yNzUgMTAuOTEzLTUuNzA0YS41ODYuNTg2IDAgMCAwIDAtLjYwMUMxOS42MjUgMi4yNTYgMTUuNTc1IDAgMTAuOTk2IDBoLjAwMXoiLz4NCjwvc3ZnPg0K");
  }
}

.input-control__toggler-icon_hidden {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjZGRkIg0KICAgICAgICAgIGQ9Ik0yMS45MDkgMTAuMjk1Yy0xLjIwNSAxLjgwOS0yLjkwOSAzLjI4Mi00LjkxNSA0LjI4NGwzLjkwNiAzLjkwNi0xLjQxNSAxLjQxNC00LjUxNy00LjUxN2ExMy40OCAxMy40OCAwIDAgMS0zLjk3MS42MTdjLTQuNTg4IDAtOC42MjctMi4yNzUtMTAuOTEzLTUuNzA0YS41Ny41NyAwIDAgMSAwLS42MDFDMS4yOSA3Ljg4MSAyLjk5MyA2LjQxIDQuOTk3IDUuNDEyTDEuMSAxLjUxNSAyLjUxNS4xbDQuNTEyIDQuNTEyQTEzLjU0OSAxMy41NDkgMCAwIDEgMTAuOTk3IDRjNC41NzkgMCA4LjYyOCAyLjI1NiAxMC45MTIgNS42OTRhLjU4Mi41ODIgMCAwIDEgMCAuNjAxem0tMTAuOTEyIDQuNGE0LjM1IDQuMzUgMCAwIDAgMi40OTUtLjc4OGwtMS43MTUtMS43MTZhMi4xMzIgMi4xMzIgMCAwIDEtLjc4MS4xNTVjLTEuMjM5IDAtMi4yNS0xLjA1NC0yLjI1LTIuMzQ3IDAtLjI1Mi4wNDktLjQ5MS4xMi0uNzE5TDcuMTU5IDcuNTczYTQuODE0IDQuODE0IDAgMCAwLS42NjEgMi40MjZjMCAyLjU5NyAyLjAwMiA0LjY5NiA0LjQ5OSA0LjY5NnpNOS43ODYgMTAuMmMuMDg3LjU3Mi41MTEgMS4wMDggMS4wNzMgMS4wNzRMOS43ODYgMTAuMnptMi40MTktLjQxYy0uMDktLjU3MS0uNTEzLS45OTYtMS4wNjQtMS4wNjRsMS4wNjQgMS4wNjR6bS0xMS4wNjguMjA5YzEuNTA2IDIuMTA3IDMuNzY2IDMuNzA2IDYuNDIyIDQuNDY0YTUuODA4IDUuODA4IDAgMCAxLTIuMDYzLTQuNDY0YzAtMS4xNjkuMzY1LTIuMjM2Ljk0Ni0zLjE0M2wtLjY1OS0uNjU5Yy0xLjg4NS44NjktMy40ODkgMi4xODMtNC42NDYgMy44MDJ6bTkuODYtNC42OTZjLS45MjUgMC0xLjc4MS4yOTEtMi40OTQuNzg2bDEuNzE2IDEuNzE1Yy4yNDMtLjA5NC41MDMtLjE1My43NzctLjE1MyAxLjIzMiAwIDIuMjUgMS4wNTQgMi4yNSAyLjM0OCAwIC4yNS0uMDQ4LjQ4Ny0uMTE5LjcxNGwxLjcwOSAxLjcwOGE0LjgwNiA0LjgwNiAwIDAgMCAuNjYxLTIuNDIyYzAtMi41OTgtMi4wMS00LjY5Ni00LjUtNC42OTZ6bTMuNDM2LjIzMWE1LjgzOSA1LjgzOSAwIDAgMSAyLjA2NCA0LjQ2NWMwIDEuMTY2LS4zNjcgMi4yMzItLjk0NiAzLjEzN2wuNjYyLjY2MmMxLjg4MS0uODY4IDMuNDgtMi4xODEgNC42NDItMy43OTktMS41MDUtMi4xMDgtMy43NjQtMy43MDctNi40MjItNC40NjV6TTEwLjk5NyA0aC0uMDAxLjAwMXoiLz4NCjwvc3ZnPg0K");

  &:hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjMzczZTQ4Ig0KICAgICAgICAgIGQ9Ik0yMS45MDkgMTAuMjk1Yy0xLjIwNSAxLjgwOS0yLjkwOSAzLjI4Mi00LjkxNSA0LjI4NGwzLjkwNiAzLjkwNi0xLjQxNSAxLjQxNC00LjUxNy00LjUxN2ExMy40OCAxMy40OCAwIDAgMS0zLjk3MS42MTdjLTQuNTg4IDAtOC42MjctMi4yNzUtMTAuOTEzLTUuNzA0YS41Ny41NyAwIDAgMSAwLS42MDFDMS4yOSA3Ljg4MSAyLjk5MyA2LjQxIDQuOTk3IDUuNDEyTDEuMSAxLjUxNSAyLjUxNS4xbDQuNTEyIDQuNTEyQTEzLjU0OSAxMy41NDkgMCAwIDEgMTAuOTk3IDRjNC41NzkgMCA4LjYyOCAyLjI1NiAxMC45MTIgNS42OTRhLjU4Mi41ODIgMCAwIDEgMCAuNjAxem0tMTAuOTEyIDQuNGE0LjM1IDQuMzUgMCAwIDAgMi40OTUtLjc4OGwtMS43MTUtMS43MTZhMi4xMzIgMi4xMzIgMCAwIDEtLjc4MS4xNTVjLTEuMjM5IDAtMi4yNS0xLjA1NC0yLjI1LTIuMzQ3IDAtLjI1Mi4wNDktLjQ5MS4xMi0uNzE5TDcuMTU5IDcuNTczYTQuODE0IDQuODE0IDAgMCAwLS42NjEgMi40MjZjMCAyLjU5NyAyLjAwMiA0LjY5NiA0LjQ5OSA0LjY5NnpNOS43ODYgMTAuMmMuMDg3LjU3Mi41MTEgMS4wMDggMS4wNzMgMS4wNzRMOS43ODYgMTAuMnptMi40MTktLjQxYy0uMDktLjU3MS0uNTEzLS45OTYtMS4wNjQtMS4wNjRsMS4wNjQgMS4wNjR6bS0xMS4wNjguMjA5YzEuNTA2IDIuMTA3IDMuNzY2IDMuNzA2IDYuNDIyIDQuNDY0YTUuODA4IDUuODA4IDAgMCAxLTIuMDYzLTQuNDY0YzAtMS4xNjkuMzY1LTIuMjM2Ljk0Ni0zLjE0M2wtLjY1OS0uNjU5Yy0xLjg4NS44NjktMy40ODkgMi4xODMtNC42NDYgMy44MDJ6bTkuODYtNC42OTZjLS45MjUgMC0xLjc4MS4yOTEtMi40OTQuNzg2bDEuNzE2IDEuNzE1Yy4yNDMtLjA5NC41MDMtLjE1My43NzctLjE1MyAxLjIzMiAwIDIuMjUgMS4wNTQgMi4yNSAyLjM0OCAwIC4yNS0uMDQ4LjQ4Ny0uMTE5LjcxNGwxLjcwOSAxLjcwOGE0LjgwNiA0LjgwNiAwIDAgMCAuNjYxLTIuNDIyYzAtMi41OTgtMi4wMS00LjY5Ni00LjUtNC42OTZ6bTMuNDM2LjIzMWE1LjgzOSA1LjgzOSAwIDAgMSAyLjA2NCA0LjQ2NWMwIDEuMTY2LS4zNjcgMi4yMzItLjk0NiAzLjEzN2wuNjYyLjY2MmMxLjg4MS0uODY4IDMuNDgtMi4xODEgNC42NDItMy43OTktMS41MDUtMi4xMDgtMy43NjQtMy43MDctNi40MjItNC40NjV6TTEwLjk5NyA0aC0uMDAxLjAwMXoiLz4NCjwvc3ZnPg0K");
  }
}

.input-control__textarea {
  border: 1px solid $common-medium-gray-color;
  border-radius: $common-border-radius;

  textarea {
    min-height: 8.81rem;
    min-width: 100%;
    margin-top: 1.81rem;
    border: none;
    padding-top: 0 !important;
    overflow-y: auto;

    &:focus,
    &:active {
      padding-top: 0 !important;
    }

    @include mobile {
      margin-top: 2.1rem;
      min-height: 13.87rem;
      white-space: pre-wrap;

      ~ .input-control__placeholder {
        opacity: 1;
        color: $accent-primary-color;
        z-index: 2;
      }

      &::placeholder {
        color: transparent;
      }
    }
  }

  @include mobile {
    margin-top: $common-margin-top-big;
  }
}

.input-control__textarea_failed {
  border: none !important;

  .input-control__input_failed {
    border: 1px solid $warning-color_not-desktop !important;
  }
}

.input-control__autocomplete-container {
  position: absolute !important;
  height: auto !important;
  //min-height: 255px !important;
}

.input-control__autocomplete-item {
  font-size: 1.47rem;
  font-family: 'Proxima Nova SemiBold';
  color: $common-dark-gray-color !important;
  padding: 1rem 10px;
  transition: color $short-transition-time, background-color $short-transition-time;
}

.input-control__autocomplete-item_active {
  background-color: $common-dark-gray-color !important;
  color: $common-white-color !important;
  transition: color $short-transition-time, background-color $short-transition-time;
}

.autocomplete-item__overflow {
  @extend %truncate-this;
}

.autocomplete-item__extra {
  @extend .select-control__extra;
}

// hide autofill icon on safaris
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input::-webkit-credentials-auto-fill-button {
  position: absolute;
  top: 21px;
  right: 40px;
}

.input-control__number-spinner {
  @extend %typo-input-placeholder-empty-vertical-align;
  position: absolute;
  right: 0;
  top: $input-height/2;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  padding: 0 13px;

  .input-control__arrow {
    padding: 3px 0;
    fill: $common-medium-gray-color_accent;
    transition: fill $short-transition-time;
    &.input-control__arrow_down {
      transform: rotateZ(180deg);
    }
  }

  &:not(.input-control__number-spinner_max) {
    .input-control__arrow_up {
      &:hover {
        fill: $common-dark-gray-color;
      }
    }
  }

  &:not(.input-control__number-spinner_min) {
    .input-control__arrow_down {
      &:hover {
        fill: $common-dark-gray-color;
      }
    }
  }

  &.input-control__number-spinner_max {
    .input-control__arrow_up {
      cursor: default;
    }
  }

  &.input-control__number-spinner_min {
    .input-control__arrow_down {
      cursor: default;
    }
  }
}
