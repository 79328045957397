.dogs-list-slider {
  &__item {
    display: block;
    width: 144px !important;
    cursor: pointer;
    transform: scale(.8);
    transition: transform $duration;
    position: relative;

    &.swiper-slide-prev,
    &.swiper-slide-next {
      transform: scale(.85);
    }

    &_active {
      transform: scale(1);

      .dogs-list-slider__name,
      .dogs-list-slider__info,
      .dogs-list-slider__photo {
        opacity: 1;
      }
    }

    &:hover {
      .dogs-list-slider__name,
      .dogs-list-slider__photo {
        opacity: 1;
      }
    }

    &:not(&_active) {
      .avatar__overlay {
        opacity: 0;
      }
      .avatar__dropzone {
        pointer-events: none;
      }
    }
  }

  &__photo {
    margin-bottom: 20px;
    opacity: .3;
    transition: opacity $duration;
  }

  &__name {
    @extend .dogs-slider__name;
    opacity: 0;
    transition: opacity $duration;
    transform: translateZ(0);
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    padding: 0;
    margin: 0;
    width: 500px;
    justify-content: center;
    left: 50%;
    margin-left: -250px;
    position: relative;
    opacity: 0;
    transition: opacity $duration;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__info-item {
    list-style-type: none;
    font-size: 16px;
    margin-right: 10px;

    @include mobile {
      &:first-child {
        width: 100%;
        text-align: center;
        margin-right: 0;
        line-height: 1.2;

        &:after {
          display: none;
        }
      }
    }

    &:after {
      content: '|';
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }
}
