.search-page {
  @extend .classes;
}

.search-page__content {
  @extend .directory__content;
}

.search-page__grid {
  @extend .directory__grid;
}

.search-page__top {
  @extend .classes__top;
}

.search-page__header {
  @extend .classes__header;
}

.search-page-results__empty-content {
  @extend .classes-schedule-table__empty-content;
}

.search-page__grid {
  @extend .class-schedule__grid;

  @include tablet {
    justify-content: space-around;
  }

  &:after {
    content: '';
    width: 260px;
  }
}

.search-page__grid-loader {
  width: 100%;
  text-align: center;
  padding: 10px;
}
