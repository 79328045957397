.search-result-card-container {
  @extend .employee-card-container;
}

.search-result-card-container_empty {
  @extend .employee-card-container_empty;
}

.search-result-card {
  //@extend .classes-schedule-card;
  //@extend %flex-container-columns;

  @extend .employee-card;
}

.search-result-card__header {
  @extend %flex-container-columns;
  @extend %flex-children-centred-start;
  min-height: 190px;
  width: 100%;
  background-color: $common-gray-color_transparent;
  box-sizing: border-box;
  padding: 20px;

  .search-result-card__header-photo-container {
    @extend .employee-card__img-container;

    .search-result-card__header-photo {
      @extend .employee-card__img;
    }
  }

  .search-result-card__header-info {
    max-width: 100%;
    text-align: center;

    // word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .search-result-card__class-name,
    .search-result-card__phone-number,
    .search-result-card__email {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }

    .search-result-card__class-name {
      @extend .employee-card__header-title;
      cursor: pointer;
      text-decoration: underline;
    }

    .search-result-card__phone-number,
    .search-result-card__email {
      @extend .employee-card__header-text;
      display: block;
      color: $common-dark-gray-color;
    }

    .search-result-card__phone-number_link,
    .search-result-card__email_link {
      transition: color $short-transition-time;

      &:hover {
        color: $accent-secondary-color;
        text-decoration: underline;
      }
    }
  }
}

.search-result-card__content {
  @extend .classes-schedule-card__content;
  min-height: 160px;
}

.search-result-card__item {
  @extend .classes-schedule-card__item;
}

.search-result-card__item-header {
  @extend .content-card__item-header;
}

.search-result-card__item-content {
  @extend .content-card__item-content;
  justify-content: left;
}

.search-result-card__item-content-link {
  @extend .search-result-card__item-content;
  display: inline;
  cursor: pointer;
  text-decoration: underline;
}

.search-result-card__footer {
  padding: 0 20px 20px;
  margin-top: auto;
  color: $common-gray-color;
  background-color: $common-white-color;

  .search-result-card__next-class {
    @extend .search-result-card__item-header;
  }
}
