.form-edit-create-page {
  @extend .dashboard-page;
  min-width: 803px;
  min-height: calc(100vh - #{$page-header-min-height});
}

.form-edit-create-page__controls {
  @extend %flex-container-rows-centred-end;
  height: 72px;
}

.form-edit-create-page__cancel-button {
  margin-right: 12px;
}

.form-edit-create-page__control-button {
  @extend .event-detail__control-button;
  margin: 0 0 0 12px;
}

.form-edit-create-page__header {
  @extend %table__header;
}

.form-edit-create-page__search-avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 12px;
}

.form-edit-create-page__content {
  @extend .event-detail__content;
}

.form-edit-create-page__empty-content {
  @extend .attendance__empty-content;
}

.form-edit-create-page__recipient-tags {
  justify-content: flex-start;
  margin: 13px 0 2px;
}

.form-edit-create-page__form {
  color: $common-dark-gray-color;
}
