.package-summary {
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &__header {
    margin-bottom: 15px;
  }

  &__title {
    margin: 0 0 2px;
    text-transform: uppercase;
    color: $accent-primary-color;
    font-size: 16px;
    letter-spacing: .8px;
    font-weight: bold;
  }

  &__subline {
    font-weight: bold;
    color: $common-gray-color;
    font-size: 14px;
    letter-spacing: .8px;
    margin: 0;
  }

  &__content {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .6px;
    color: $common-dark-gray-color;
    max-height: 50px;
    overflow: hidden;
  }

  &__footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__price {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .9px;
  }

  &__button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: $accent-secondary-color;
    letter-spacing: .6px;
    transition: color $duration;
    &:hover {
      @extend %faster;
      color: darken($accent-secondary-color, 20%);
    }
  }
}
