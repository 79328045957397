.error-handler {
  @extend .modal;
  @extend .modal__effect-sticky-top;
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  transform: translateX(0) translateY(0);

  @include mobile {
    top: 0;
    height: auto;
  }
}

.error-handler_show {
  @extend .modal_show;
}

.error-handler_content {
  @extend .modal__content;
  @extend %flex-container-rows-centred;
  width: 100%;
  height: 100%;
}

.error-handler__text {
  @extend %flex-container-rows-centred;
  @extend %typo-error-handler;
  color: $common-dark-gray-color;

  @include mobile {
    flex-flow: column nowrap;
    align-items: center;
    padding: 1rem 4rem;
    text-align: center;
  }
}

.error-handler__icon-overlay {
  @extend %flex-container-columns-centred;
  width: 36px;
  height: 35px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: $accent-primary-color;

  &_success .icon {
    fill: #fff;
  }
}

.error-handler__close {
  position: absolute;
  right: 3rem;

  @include tablet {
    right: 2rem;
  }

  @include mobile {
    top: 1rem;
    right: 1rem;
  }
}