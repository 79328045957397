.progress-bar {
  width: 100%;
  height: 15px;
  border: 1px solid $common-medium-gray-color;
  padding: 1px;

  &__progress {
    background: #00a1c3;
    height: 100%;
    width: 0%;
    transition: width .1s;
  }
}
