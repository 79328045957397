.template-editor {
  width: 610px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  &_preview {
    pointer-events: none !important;
  }
}

.template-editor__logo {
  display: inline-block;
  margin: 10px;
  & > svg.logo__img {
    width: 211px;
    height: 70px;
  }
}

.image-selector.template1-image-selector,
.image-selector.block2-image-selector {
  height: 320px;
  margin-bottom: 25px;
}

.image-selector.template3-image-selector {
  & > .image-selector__placeholder,
  & > .image-selector__image {
    height: 215px;
  }
  & > .image-selector__placeholder > .image-selector__icon-text {
    margin-top: -10px;
  }
}

.image-selector.block4-image-selector {
  width: 145px;
  height: 145px;
  &:before {
    content: '';
    margin-top: 100%;
    display: block;
  }
  &:last-child {
    margin-right: 0;
  }
  & > .image-selector__placeholder,
  & > .image-selector__image {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    & > .image-selector__icon-text {
      margin: 0 auto;
      & > .icon_file.icon {
        display: none;
      }
    }
    & > .image-selector__image-change-text {
      font-size: 1.5rem;
    }
  }
}

.template-title.template1-title {
  line-height: 1.5;
  margin-bottom: 15px;
}

.template-title.template3-title {
  line-height: 1;
  margin-bottom: 5px;
}

.template-title.template3-title2 {
  line-height: 1.5;
  margin-bottom: 10px;
  & > input[type="text"],
  & > label {
    font-size: 16px;
  }
}

.template-title.template3-subtitle {
  color: $common-gray-color;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
}

.template-body.template1-body {
  line-height: 1.3;
  margin-bottom: 20px;
}

.template-body.template3-body {
  line-height: 1.3;
}

.template1-button-link-editor {
  z-index: 3;
  min-width: 165px;
  display: inline-block;
  position: relative;
}

.block2-button-link-editor {
  z-index: 3;
  min-width: 165px;
  display: inline-block;
  position: relative;
  margin-bottom: 25px;
}

.template-block {
  position: relative;
  overflow: hidden;
  will-change: height;
  transition: height 500ms;
}

.template-block__add {
  height: 30px;
  display: inline-flex;
  opacity: .01;
  transition: opacity $short-transition-time;
  z-index: 1;
}

.template-block__remove {
  opacity: .01;
  display: inline-flex;
  position: absolute;
  right: 0;
  transition: opacity $short-transition-time;
  &:hover {
    opacity: 1 !important;
  }
  &:hover > div.remove-item__text {
    color: $warning-color !important;
  }
  &:hover > svg.icon_add-new-item {
    fill: $warning-color !important;
  }
}

.template-block:hover .template-block__remove,
.template-editor:hover .template-block__add {
  opacity: .25;
}

.template-block__add:hover {
  opacity: 1 !important;
}

.template-editor:hover .template-block__footer-add {
  opacity: 1;
}

.template-block__header {
  border: none;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;

  & button {
    background: none;
    border: none;
    padding: 0;
  }
}

.template-block:not(:first-child) > .template-block__header {
  border-top: 1px solid #ddd;
}

.template-block__footer {
  display: flex;
  position: relative;
  height: 30px;
  width: 100%;
}

.template-block__footer-add {
  display: flex;
  opacity: .01;
  height: 100%;
  padding-right: 1rem;
  background: $common-white-color;
  transition: opacity $short-transition-time;
}

.template-block-flex-container {
  display: flex;
  justify-content: space-between;
}

.template3-group50 {
  width: 300px;
  height: auto;
  &:last-child {
    margin-right: 0;
    padding-top: 10px;
  }
}

.campaign-weekly-photos {
  @extend %typo-template-title;
  text-align: center;
  margin-top: 30px;
}

.campaign-weekly-photos__fb-button {
  @extend %typo-template-button;
  display: inline-flex;
  align-items: center;
  padding: 0 30px;
  margin: 30px auto 0;
  color: $common-white-color;
  height: 44px;
  background: $facebook-bg-color;
}

.campaign-weekly-photos__fb-icon {
  width: 26px;
  height: 26px;
  fill: $common-white-color;
  margin-right: 16px;
}
