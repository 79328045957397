.modal {
  position: fixed;
  top: 50%;
  // positioning should be defined with this 2 rules in inherited class
  //left: 50%;
  //width: 50%;
  height: auto;
  z-index: 2000;
  visibility: hidden;
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
}

.modal_show {
  visibility: visible;
}

.modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, .7);
  transition: all .3s;
  pointer-events: none;
}

.modal_show ~ .modal__overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

/* Effect 2: Slide from the right */
.modal__effect-right {
  .modal__content {
    transform: translateX(20%);
    opacity: 0;
    transition: all $short-transition-time cubic-bezier(.25, .5, .5, .9);
  }
}

.modal_show.modal__effect-right .modal__content {
  transform: translateX(0);
  opacity: 1;
}

/* Effect 7:  slide and stick to top */
.modal__effect-sticky-top {
  .modal__content {
    opacity: 0;
    transform: translateY(-200%);
    transition: all $short-transition-time cubic-bezier(.25, .5, .5, .9);
  }
}

.modal_show.modal__effect-sticky-top .modal__content {
  transform: translateY(0%);
  opacity: 1;
}

