.simple-select {

  .Select-control {
    border: none;
    padding: 0 20px;
    height: 54px;
  }

  .Select-value {
    padding: 0 20px !important;
  }

  .Select-clear-zone {
    display: none;
  }

  .Select-value-label {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 7px;
    height: 54px;
    line-height: 54px;
  }

  .Select-arrow {
    width: 17px;
    height: 17px;
    border: 4px solid $common-dark-gray-color;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    margin-top: -6px;
    transition: transform $duration;
  }

  .Select-menu-outer {
    border: none;
  }

  &.is-open {
    .Select-arrow {
      transform: rotate(-135deg) translateY(-4px) translateX(-5px);
    }
  }

  .Select-option {
    font-size: 16px !important;
    padding: 15px 20px !important;
    width: 100% !important;

    &.is-selected,
    &.is-focused {
      background-color: $common-dark-gray-color;
      color: $common-white-color;
    }
  }
}
