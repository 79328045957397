.form-update {
  letter-spacing: .8px;

  &__container {
    @extend .inner-page;
    padding-top: 70px;

    @include mobile {
      padding: 50px 10px 40px;
    }
  }

  &__form {
    max-width: 700px;
    background: #fff;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: $common-shadow;
  }

  &__title {
    color: #373e48;
    font-size: 16px;
    font-family: $bold;
    margin-right: 0;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    display: block;
    padding-bottom: 17px;
    margin-bottom: 30px;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: #ff5d22;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
    }
  }
  &__content {
    padding: 30px 70px 20px;

    @include mobile {
      padding: 30px 10px 20px;
    }
  }
  &__headline {
    margin-bottom: 26px;
    font-size: 16px;
    text-align: center;
    line-height: 1.1;
  }
  &__name {
    color: $accent-primary-color;
  }
  &__headline-title {
    font-family: $bold;
  }
  &__textarea {
    margin-bottom: 20px;
  }
  &__checkbox {
    justify-content: space-between;
  }
  &__footer {
    padding: 20px 70px;
    border-top: 1px solid $common-medium-gray-color_accent;
    display: flex;
    justify-content: center;
  }
  &__submit {
    @extend .button;
    width: 100%;
    max-width: 300px;
    margin-top: 0;
  }
}
