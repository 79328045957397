.data-slider-wrapper {
  position: relative;
  padding: 0 100px;
  background: #fff;
  box-shadow: $common-shadow;
  overflow: hidden;
  margin-bottom: 20px;

  @include tablet {
    padding: 0 50px;
  }
  @include mobile {
    padding: 0 40px;
  }
}

.date-slider {
  height: 60px;

  &__item {
    padding: 14px 5px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: color $duration, background $duration;

    @include mobile {
      width: 90px;
    }

    &_active,
    &:hover {
      color: #fff;
      background: $common-dark-gray-color;
      @extend %faster;
    }
  }

  &__day {
    font-family: $bold;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  &__date {
    letter-spacing: 1.1px;
    font-size: 16px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 100px;
    background: #fff;

    @include tablet {
      width: 50px;
    }
    @include mobile {
      width: 40px;
    }

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border: 4px solid $common-silver-color;
      border-width: 4px 4px 0 0;
      margin-top: -8px;
    }

    &.swiper-button-disabled {
      pointer-events: all;
      &:before {
        opacity: .3;
      }
    }
  }

  .swiper-button-next {
    right: 0;

    &:before {
      right: 60px;

      @include tablet {
        right: 20px;
      }

      @include mobile {
        right: 15px;
      }
    }
  }

  .swiper-button-prev {
    left: 0;

    &:before {
      left: 60px;

      @include tablet {
        left: 20px;
      }

      @include mobile {
        left: 15px;
      }
    }
  }
}
