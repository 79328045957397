.form-step {
  position: relative;
  &__title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: .8px;
    color: $common-dark-gray-color;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 15px;
    line-height: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: $accent-primary-color;
      margin: 11px auto 0;
    }
  }

  &__form-content {
    padding: 0 55px;
    @include not-desktop {
      padding: 0 20px;
    }
  }

  &__error {
    text-align: center;
    color: red;
    margin-bottom: 10px;
    line-height: 1.3em;
    &:last-child {
      margin-bottom: 0;
    }
    .tooltip {
      color: $accent-secondary-color;
      cursor: pointer;
    }
  }

  &__errors-list {
    margin-bottom: 30px;
  }
}
