$waiver-width: 62.1%;

.forgot-password {
  @extend .signin;
}

.forgot-password__img {
  @extend .signin__img;
}

.forgot-password__img_employee {
  @extend .signin__img_employee;
}

.forgot-password__content {
  @extend .signin__content;
}

.forgot-password__container {
  @extend .signin__container;
}

.forgot-password__container_forgotten {
  @include onboarding-container($waiver-width, $signin-width_tablet, $signin-width_mobile);
}

.forgot-password__logo {
  @extend .signin__logo;
}

.forgot-password__step-description {
  @extend %flex-container-columns-centred;
  margin-top: 6.67rem;
  width: 84.63%;

  .step-description__arrow,
  .step-description__image {
    display: none;
  }

  @include not-desktop {
    width: 100%;
  }

  @include tablet {
    margin-top: $common-margin-top-great_mobile;
  }

  @include mobile {
    margin-top: 3.08rem;
  }
}

.forgot-password__step-description_forgotten {
  width: 100%;

  &:before {
    content: '';
    display: inline-block;
    width: 66px;
    height: 66px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3My41IDczLjUiPg0KICAgIDxwYXRoIGZpbGw9IiNGRjVEMjIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkY1RDIyIiBzdHJva2Utd2lkdGg9IjUiDQogICAgICAgICAgZD0iTTM1LjUgMi41YzE4LjIyNSAwIDMzIDE0Ljc3NSAzMyAzM3MtMTQuNzc1IDMzLTMzIDMzLTMyLjk5OS0xNC43NzUtMzIuOTk5LTMzIDE0Ljc3NC0zMyAzMi45OTktMzN6Ii8+DQogICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIg0KICAgICAgICAgIGQ9Ik0zMC40NjYgNDIuMzEzbC03LjA1MS03LjE5Mi0zLjA5MiAzLjUyOCAxMC40IDEwLjcxNSAxOS45NTktMjQuNTQ3LTMuMzQ4LTMuMTgtMTYuODY4IDIwLjY3NnoiLz4NCjwvc3ZnPg0K");

    @include tablet {
      width: 60px;
      height: 60px;
    }

    @include mobile {
      width: 40px;
      height: 40px;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    margin-top: 1.81rem;
    height: 1px;
    width: 45px;
    background-color: $common-medium-gray-color;

    @include tablet {
      margin-top: 2.54rem;
    }
  }

  .step-description__text {
    margin-top: 2.54rem;

    @include not-desktop {
      margin-top: 1.81rem;
    }
  }

  @include mobile {
    margin-top: 2.93rem;
  }
}

.forgot-password__step-description_reset {
  @include mobile {
    width: 65%;
  }
}

.forgot-password__form {
  @extend .signin__form;
  margin-top: 3.26rem;

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: 2.68rem;
  }
}

.forgot-password__submit-button {
  @extend .signin__submit-button;
  width: 63.16%;

  @include mobile {
    width: 100%;
    margin-top: 1.45rem;
  }
}

.forgot-password__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.forgot-password__retry {
  @extend %typo-forgot-password-retry;
  margin-top: 1.74rem;
  color: $common-dark-gray-color;
  text-align: center;

  @include tablet {
    margin-top: $common-margin-top-large_mobile;
  }

  @include mobile {
    margin-top: 2.1rem;
  }
}

.forgot-password__retry-text {
  font-family: 'Proxima Nova SemiBold';
  //font-weight: bold;
}

.forgot-password__retry-action {
  @include underline(false, 1px, 100%, $common-dark-gray-color, .3);
  margin-top: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.forgot-password__skip-step {
  @extend .signin__skip-step;

  @include tablet {
    .skip-step__skip-link {
      padding-bottom: 6.81rem;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      padding: 2.61rem 0 $common-margin-top-big;
    }
  }
}

.forgot-password__skip-step_forgotten {
  @include not-desktop {
    position: relative;

    .skip-step__skip-link {
      margin-top: $common-margin-top-large_mobile;
      padding: $common-margin-top-large_mobile 0 $common-margin-large_tablet;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      display: inline-block;
      margin-top: $common-margin-top-large_mobile;
      height: 1px;
      width: 45px;
      background-color: $common-medium-gray-color;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      margin-top: 1.96rem;
      padding: $common-margin-top-big 0;
    }

    &:before {
      margin-top: 1.96rem;
    }
  }
}

.forgot-password__skip-step_reset {
  @include tablet {
    .skip-step__skip-link {
      padding-bottom: 10.44rem;
    }
  }

  @include mobile {
    .skip-step__skip-link {
      padding-bottom: 2.61rem;
    }
  }
}
