.button-editable {
  display: inline-block;
  height: 44px;
  width: 100%;
  & .form-field-error {
    width: 100%;
    text-align: center;
  }
}

.button-editable__input,
.button-editable__text {
  @extend %typo-template-button;
  text-align: center;
  border: none;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  color: $common-white-color;
  background-color: $accent-primary-color;
  transition: background-color $short-transition-time;
}

.button-editable__input {
  cursor: text !important;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  &::placeholder {
    @extend %typo-template-button;
    color: $common-white-color;
    text-align: center;
    height: 44px;
    opacity: .75;
    padding: 0;
    width: 100%;
    will-change: opacity, transform;
    transition:
      opacity $short-transition-time,
      transform $medium-transition-time;
  }
  &:focus::placeholder {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.button-editable__text {
  display: inline-block;
  white-space: pre;
  line-height: 44px;
}

span.button-editable__text {
  visibility: hidden;
}

.button-editable:focus-within .button-editable__input {
  pointer-events: auto;
}

.button-editable:focus-within ~ .link-editor .link-editor__icon {
  pointer-events: all;
  cursor: pointer;
  opacity: .5;
  &:hover {
    opacity: 1;
  }
}
