.package-sell-modal {
  .radio-control {
    display: block;
    @extend .form__aligner;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .radio-control__controls-text {
    font-size: 18px;
    font-weight: 600;
  }
}

.package-create-modal__head {
  margin-top: -37px;

  @include mobile {
    margin-top: 20px;
  }
}

.package-create-modal__name {
  margin-top: 60px !important;

  @include mobile {
    margin-top: 20px !important;
  }
}
