.search-bar {
  @extend %flex-container-rows-centred-between;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $common-dim-gray-color;

  @include mobile {
    max-width: 100%;
  }
}

.search-bar__input {
  width: 100%;
  padding-top: 3px;
  @extend %typo-search-bar-input;
  color: $common-silver-color;
  background-color: $common-dim-gray-color;
  border: none;
  border-radius: 0;

  @include mobile {
    font-size: 1.3rem;
    padding-left: 30px;
    margin-right: 10px;
    border-bottom: 1px solid $common-white-color;
    height: 35px;
  }
}

.search-bar__search-icon {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  border: none;
  object-fit: contain;
  background-color: $common-dim-gray-color;
  fill: $common-white-color;

  @include mobile {
    display: none;
  }
}

.search-bar__icon-loader {
  @include mobile {
    position: absolute;
    right: 70px;
  }
}

.search-bar__top-results {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: $common-white-color;
  z-index: 9;
  box-shadow: 0 2px 7.9px .1px rgba(0, 0, 0, .16);

  &.search-bar__top-results_empty {
    min-height: 150px;
  }

  .search-bar__top-results-table {
    .rt-tr,
    .rt-td {
      @extend %flex-container-rows-centred-start;
      text-align: left !important;
    }

    .search-top-results-table__th {
      border-right: none !important;
      font-family: 'ProximaNova-Bold';
      padding: 10px 25px;
    }

    .rt-tbody {
      .rt-tr-group {
        border-bottom: none !important;

        .search-top-results-table__td {
          border-right: none !important;
          padding: 10px 25px;
          white-space: nowrap;

          .search-top-results-table__cell-container {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .search-top-results-table__result-link {
            @include underline(false, 1px, 100%, $common-dark-gray-color, 0);
            color: $common-dark-gray-color;

            // word-break: break-all;
            cursor: pointer;

            &:hover {
              @include underline(false, 1px, 100%, $common-dark-gray-color, 1);
            }
          }

          .search-top-results-table__email-link,
          .search-top-results-table__phone-link {
            @include underline(false, 1px, 100%, $accent-secondary-color, 0);
            color: $common-dark-gray-color;
            transition: color $short-transition-time;

            &:hover {
              color: $accent-secondary-color;
              @include underline(false, 1px, 100%, $accent-secondary-color, .3);
            }
          }

          .search-top-results-table__result-link,
          .search-top-results-table__email-link,
          .search-top-results-table__phone-link {
            &:after {
              transition: opacity $short-transition-time !important;
            }
          }
        }
      }
    }

    .rt-thead,
    .rt-tbody {
      min-width: 0 !important;
    }

    @include mobile {
      .search-top-results-table__th_phone,
      .search-top-results-table__td_phone,
      .search-top-results-table__th_email,
      .search-top-results-table__td_email {
        display: none;
      }
    }
  }

  .search-bar__top-results-no-matches {
    @extend %type-vertical-horizontal-align;
    position: absolute;
    color: $common-gray-color;
  }

  @include mobile {
    top: 100px;
  }
}

.search-bar__search-icon_mobile,
.search-bar__remove-icon_mobile {
  display: none;
  fill: $common-white-color;
  @include mobile {
    display: block;
  }
}

.search-bar__remove-icon_mobile {
  cursor: pointer;
  line-height: 0;
  border-width: 0;
  background: transparent;
}

.search-bar__search-icon_mobile {
  position: absolute;
  top: 11px;
}

.search-bar__icon-loading {
  @include mobile {
    position: absolute;
    right: 35px;
    top: 11px;

    .icon_loader {
      width: 25px;
      height: 25px;
    }
  }
}
