%container {
  display: flex;
  height: auto;
  width: 100%;
}

%container_centred {
  @extend %flex-container-columns-centred;
}

@mixin onboarding-container($width, $tablet-width, $mobile-width) {
  @extend %container_centred;
  flex: auto;
  min-width: $width;
  max-width: $width;
  // negative margin needed because we moved logo out of the container
  //margin: calc(-80px - 3.19rem) auto 0;

  @include not-desktop {
    height: auto;
    margin: auto !important;
  }

  @include tablet {
    min-width: $tablet-width;
    max-width: $tablet-width;
  }

  @include mobile {
    min-width: $mobile-width;
    max-width: $mobile-width;
  }
}

