$health-width: 69.44%;
$health-width_tablet: 86.98%;
$health-logo-width: 29.4%;
$add-dog-button-width: 41.83%;

.health {
  @extend .signin;
}

.health__img {
  @extend .signin__img;
  background-image: url('/assets/imgs/slide-2.jpg');
  @include not-desktop {
    display: none;
  }
}

.health__content {
  @extend .signin__content;
}

.health__container {
  @include onboarding-container($health-width, $health-width_tablet, $signin-width_mobile);
  min-height: calc(100vh - 28.1rem);
}

// todo: should it be done like container above?
.health__logo {
  @extend .signin__logo;
  width: $health-logo-width;

  @include mobile {
    width: $signin-logo-width_mobile;
  }
}

.health__step-name {
  @extend .introduction__step-name;
}

.health__step-description {
  @extend .introduction__step-description;

  @include mobile {
    .step-description__disclaimer {
      padding: 0 4.45rem;
    }
  }
}

.health__step-description-image {
  @extend .step-description__image;
  background-image: url('/assets/imgs/slide-2_tablet.jpg');

  @include mobile {
    padding-bottom: 62.5%;
    background-image: url('/assets/imgs/slide-2_mobile.jpg');
  }
}

.health__form {
  @extend .signin__form;
  @extend %flex-container-columns-around;
  flex: 1;
  // #smaller margins
  //margin-top: $common-margin-top-big;
  margin-top: 1.67rem;

  .select-control {
    border: none !important;

    @include mobile {
      margin-top: $common-margin-top-big;
    }
  }

  .form__select-aligner {
    @include mobile {
      margin-top: $common-margin-top-big;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .Select {
    z-index: 1;
  }

  .Select-control {
    display: flex;
    min-height: 5.5rem;
    height: auto;
    padding-top: 1.5rem;

    .Select-value {
      line-height: 2 !important;
    }

    .Select-multi-value-wrapper {
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;
      margin-bottom: .5rem;

      .Select-input ~ .Select-value {
        background-color: red;
      }

      @include mobile {
        width: 100%;
      }
    }

    .Select-placeholder {
      line-height: 3.5;

      @include mobile {
        line-height: 4.5;
        color: transparent;

        &:before {
          content: 'Start typing...';
          color: #ddd !important;
          font-size: 1.47rem;
        }
      }
    }

    .Select-input input {
      padding: 0 !important;
    }
  }

  .Select-arrow-zone,
  .Select-menu-outer {
    display: none;
  }

  .Select--multi {
    .Select-input {
      display: flex !important;
      align-items: center;
      margin-left: 0;
      height: $common-margin-top-large_mobile;
      overflow: hidden;

      input {
        //padding: 1.81rem 0 .65rem;
        padding-left: 0;
        //width: auto !important;
        line-height: 1;
        overflow: hidden;
      }

      @include mobile {
        width: 100%;
        order: 1;

        input {
          width: 100% !important;
          border-bottom: 1px solid $common-medium-gray-color;
          padding-bottom: 8px;
          font-size: 1.34rem;
          font-family: 'Proxima Nova SemiBold';
          //font-weight: bold;
          color: $common-dark-gray-color;
        }
      }
    }

    .Select-value {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      background-color: transparent;
      border-radius: 40px / 100%;
      border: none;
      color: $common-dark-gray-color;
      font-size: 1.34rem;
      letter-spacing: .5px;
      padding: 0 .1rem 0 0 !important;
      margin-left: 0;
      margin-right: .5rem;
      vertical-align: middle;
      @extend %select-value-transition;

      @include mobile {
        justify-content: space-between;
        order: 2;
        width: 100%;
        margin-right: 0;

        &:first-of-type {
          margin-top: 0;
        }

        &:after {
          display: none !important;
        }

        .Select-value-icon {
          display: block !important;
          opacity: .3 !important;
        }

        &:hover {
          margin-left: 0;
          padding: 0 !important;
          background-color: transparent;
          color: $common-dark-gray-color;

          &:after {
            color: $common-dark-gray-color;
            right: -.7rem;
          }

          ~ .Select-value {
            margin-left: 0;
          }

          + .Select-input {
            margin-left: 0;
          }

          .Select-value-label {
            padding: 2px 5px;
            transition: padding $medium-transition-time;
          }
        }
      }

      &:after {
        content: ',';
        display: inline-block;
        left: -12px;
        bottom: 0;
      }

      &:first-of-type {
        margin-left: 0 !important;
      }

      // #todo: HOW TO REMOVE THIS LAST ','...
      &:last-child {
        background-color: yellow;

        &:after {
          display: none;
        }
      }

      &:hover {
        margin-left: 5px;
        padding: $input-value-padding !important;
        background-color: $common-dark-gray-color;
        color: $common-white-color;
        @extend %select-value-transition;

        &:after {
          color: $common-dark-gray-color;
          right: -.7rem;
        }

        ~ .Select-value {
          margin-left: 1rem;
        }

        + .Select-input {
          margin-left: .95rem;
        }

        .Select-value-icon {
          display: block;
          opacity: .5;
          transition: opacity $short-transition-time;
        }

        .Select-value-label {
          padding: 2px 5px;
          transition: padding $medium-transition-time;
        }
      }

      .Select-value-label {
        padding-left: 0;
        transition: padding $medium-transition-time;
      }

      + .Select-input {
        margin-left: 0;

        @include mobile {
          margin-left: 0;
        }

        input {
          padding: 0;
          line-height: 1.9;
        }
      }

      .Select-value-icon {
        display: none;
        order: 2;
        opacity: 0;
        border: none;
        transition: opacity $short-transition-time;

        &:hover {
          opacity: 1;
          background-color: transparent;
          color: $common-white-color;
          transition: opacity $short-transition-time;
        }

        @include mobile {
          padding: 4px 5px 0;
        }
      }
    }
  }

  .react-select-2--backspace-remove-message {
    display: none;
  }

  .radio-control__description-warning {
    @include mobile {
      margin-top: 0;
    }
  }

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: 2.97rem;
    // make it act as an untouched one for mobile devices
    .select-control__placeholder {
      opacity: 1;
      color: $accent-primary-color;
      z-index: 2;
    }
  }
}

.health__form-buttons {
  @extend %flex-container-rows-between;
  width: 100%;
  // #smaller margins
  //margin: $common-margin-top-big auto 0;
  margin: 1.67rem auto 0;

  @include tablet {
    margin-top: 3.26rem;
    width: 85.18%;
  }

  @include mobile {
    flex-flow: column nowrap;
    width: 100%;
    margin-top: $common-margin-top-large_mobile;
  }
}

.health__submit-button {
  @extend .signin__submit-button;
  width: 56.34%;
  margin: 0;

  @include mobile {
    width: 100%;
    margin-top: .72rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.health__submit-button_add-dog {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: $add-dog-button-width;
  box-shadow: none;
  color: $common-white-color;
  background-color: $common-dark-gray-color;

  &:hover {
    box-shadow: 0 0 0 4px $common-dark-gray-color inset;
    color: $common-dark-gray-color;
    background-color: $common-white-color;

    .add-dog__icon {
      color: $common-white-color;
      background-color: $common-dark-gray-color;
      transition: background-color $short-transition-time;

      &:before,
      &:after {
        background-color: $common-white-color;
        transition: background-color $short-transition-time;
      }
    }
  }

  @include mobile {
    width: 100%;
  }

  .add-dog__icon {
    position: relative;
    color: $common-dark-gray-color;
    background-color: $common-white-color;
    margin-right: .8rem;
    margin-top: -3px;
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    transition: background-color $short-transition-time;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $common-dark-gray-color;
      transition: background-color $short-transition-time;
    }

    &:before {
      top: 50%;
      margin-top: -6px;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 12px;
    }

    &:after {
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -6px;
      width: 12px;
      height: 2px;
    }
  }

  .add-dog__text {
    padding-top: 1px;
  }
}

.health__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.health__skip-step {
  @extend .introduction__skip-step;

  .skip-step__skip-link {
    @include mobile {
      padding: $common-margin-top-big 0;
    }
  }
}
