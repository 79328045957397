.event-detail {
  @extend %background-pattern;
  padding: 0 40px 40px;

  @include mobile {
    padding: 10px;
  }

  @include tablet {
    padding: 30px;
  }
}

.event-detail__header {
  @extend %table__header;
}

.event-detail__content {
  position: relative;
  padding: 30px;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
  border-radius: 0 0 $common-border-radius $common-border-radius;
}

.event-detail__empty-content {
  @extend %table__empty-content;
}

.event-detail__controls {
  @extend %flex-container-rows-centred-between;
  height: 72px;

  @include mobile {
    margin: 20px 0 33px;
    height: auto;
    flex-flow: column-reverse nowrap;
  }
}

.event-detail__form {
  margin-bottom: 1rem;
}

.event-detail__form-buttons {
  @extend %flex-container-rows-centred;
  height: 100%;

  @include mobile {
    width: 100%;
    margin-bottom: 23px;
  }
}

.event-detail__form-buttons > button {
  margin-left: 12px;
  margin-top: 0;
}

.event-form__notes textarea[name='notes'] {
  height: 36.81rem;
  padding-top: 1.81rem !important;
  margin-top: 0;

  @include mobile {
    height: 20rem;
  }
}

.event-detail__form-training .event-form__notes textarea[name='notes'] {
  height: 30.2rem;
  @include mobile {
    height: 20rem;
  }
}

.event-detail__control-button {
  @extend .modal-dialog__button;
  padding: 0 38px;
  min-width: 136px;

  @include mobile {
    margin-top: 0 !important;
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;

    &:first-of-type {
      margin-left: 0;
      margin-right: 5px;
    }

    &:last-of-type {
      margin-left: 5px;
      margin-right: 0;
    }
  }
}

.event-detail__cancel {
  @include mobile {
    text-indent: -9999px;
    line-height: 0;
  }
}

.event-detail__cancel:after {
  @include mobile {
    line-height: initial;
    display: block;
    text-indent: 0;
    content: 'Cancel';
  }
}

.event-detail__spots {
  @extend %typo-event-spots;
  color: $common-gray-color;
}

.event-dog-search {
  margin-top: 1rem;
}

.event-detail__training-conflict-list {
  list-style: none;
  text-align: center;
  padding-left: 0;
}

.event-detail__save-changes-warning {
  @extend %typo-event-warning;
  color: $warning-requires-color;
  width: 100%;
  text-align: center;
  margin-top: 23px;
  padding-bottom: 10px;
}

.event-detail__table-cell-disabled {
  @extend %table__th;
  background-color: #f5f5f4;
  pointer-events: none;
  cursor: not-allowed;
}

.event-detail-table__td {
  @extend %table__td;
}

.attending-dogs-tabe {
  @extend .classes-table;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}
