.profile-edit {
  @extend %background-pattern;
  width: 100%;
}

.profile-edit__container {
  margin: 30px 40px 40px;

  @include mobile {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.profile-edit__section {
  @extend %dashboard__content;
  overflow: hidden;
  margin: 0 auto 30px;
  max-width: 1400px;
  &:last-child {
    margin-bottom: 0;
  }
}

.profile-edit__section-content {
  will-change: height;
  transition: height $short-transition-time;
}

.profile-edit__section-content__wrapper {
  padding: 35px 30px 25px;
  @include mobile {
    padding: 30px 20px 18px;
  }
}

.profile-edit__section-content--closed {
  transition-duration: $small-transition-time;
}

.profile-edit__section-toggle {
  fill: #fff;
  position: absolute;
  right: 10px;
  top: 50%;
  width: 26px;
  height: 26px;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
  border: none;
  background: transparent;
  overflow: hidden;
  transition: transform $short-transition-time;
}

.profile-edit__section-toggle--closed {
  transform: translateY(-50%) rotate(180deg);
  transition-duration: $small-transition-time;
}

.profile-edit__section-toggle__icon {
  width: 100%;
  height: 100%;
}
.profile-edit__section-head {
  @extend %table__header;
  position: relative;
  @include mobile {
    height: auto;
    min-height: 40px;
  }
}

.profile-edit__section-head--dark {
  background: $common-dark-gray-color;
}

.profile-edit__section-head__text {
  @include mobile {
    max-width: 70%;
    font-size: 18px;
    line-height: 1.3em;
    text-align: center;
    margin: 13px auto;
  }
}

.profile-edit-form {
  position: relative;
  box-sizing: border-box;
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.profile-edit-form__avatar {
  position: absolute;
  top: 0;
  left: 0;
  @include mobile {
    position: relative;
    margin: 0 auto 30px;
    display: inline-block;
  }
  .avatar__container {
    @include mobile {
      margin: 4px auto;
    }
  }

  .avatar__container, .avatar__img {
    @include mobile {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
  }
  .avatar__overlay {
    @include mobile {
      width: 100%;
      height: 100%;
    }
  }

  .avatar__text {
    @include mobile {
      font-size: 13px;
      line-height: 24px;
      margin-top: 0;
    }
  }
}

.profile-edit-form__content {
  margin-left: 170px;
  margin-bottom: 25px;
  @include mobile {
    margin-left: 0;
    margin-bottom: 15px;
    &:after {
      display: block;
      content: '';
      margin: 40px -20px 0;
      background: $common-medium-gray-color;
      height: 1px;
    }
  }
}

.profile-edit-form__input {
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
}

.profile-edit-form__submit {
  @extend .signin__submit-button;
  width: 185px;
  @include mobile {
    margin: 0 auto;
  }
}

.profile-edit-form__submit--disabled {
  @extend .signin__submit-button_disabled;
}

.profile-edit__change-pass {
  position: relative;
}

.profile-edit__change-pass__content {
  @extend %flex-container-rows-between;
  @include mobile {
    margin-left: 0;
    margin-bottom: 15px;
    flex-direction: column;
    &:after {
      display: block;
      content: '';
      margin: 40px -20px 0;
      background: $common-medium-gray-color;
      height: 1px;
    }
  }
}

.profile-edit__change-pass__input {
  width: 49%;
  @include mobile {
    width: 100%;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
